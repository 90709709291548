import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import React from "react";
import CardMenu from "../../../../components/card/CardMenu";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import PrintButton from "../../../../components/filterButton/printBtn";
import Switch from "../../../../components/switch";
import CustomTable from "../../../../components/customTable/CustomTable";
import Checkbox from "../../../../components/checkbox";
import CustomSelect from "../../../../components/select/select";

interface CardData {
    title?: string;
}
const CurrentCards: React.FC<CardData> = () => {
    const { t } = useTranslation();
    type Option = typeof sampleOptions[0];
    const handleChange = (selectedValues: Option[]) => {
        console.log(selectedValues);
    };
    const sampleOptions = [
        {label: "option 1", value: "option-1"},
        {label: "option 2", value: "option-2"},
        {label: "option 3", value: "option-3"},
    ];
    const data = [
        { label: "", content:  <div className={'flex items-center h-full pb-3'}><Checkbox id={'1'} color={'yellow'}/></div>  , colSpan: 1 },
        { label: "ADDRESS_COUPONS", content: "Takaful1", colSpan: 1 },
        { label: "START_DATE_COUPONS", content: " 1-10-2023", colSpan: 1 },
        { label: "END_DATE_COUPONS", content: " 1-10-2023", colSpan: 1 },
        { label: "STATISTIC", content: <button className={'action-btn border-primary bg-white'} >
                <span className={'icon-list-2 fs-16 text-primary-500'}> <span className="path1"/> <span className="path2"/></span></button>, colSpan: 1 },
        { label: "CARD_VALUE", content: " 1-10-2023", colSpan: 1 },
        { label: "FINISH_ORDER", content: <Switch id="switch1" color="primary" />, colSpan: 1 },
    ];
    return (
        <div>
            <div>
                <ScreanTitle title={t('SCREEN_GIFT_CARDS')}></ScreanTitle>
                <div className={'flex justify-between pb-3'}>
                    <div>
                        <button className="btn btn-primary mx-3 flex items-center">
                            <span className={'pe-3'}>{t('CURRENT_CARDS')}</span>
                            <em className={'icon-plus'}></em>
                        </button>
                    </div>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                               <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    {t('ACTIVE')}
                                </p>
                                <p className="text-black cursor-pointer">
                                    {t('EXPIRED')}
                                </p>
                                <p className="text-black cursor-pointer">
                                    {t('CLOSED')}
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="white-card h-full w-full mt-1">
                    <div className="flex justify-between pe-4">
                        <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('CURRENT_SUBSCRIPTIONS')}</p>
                        <CustomSelect  onChange={handleChange}
                            minWidth={'150px'}
                            placeholder={t('ACCORDING_TO_TYPE')}
                            options={sampleOptions}
                        />
                    </div>
                <div className="steppedTable">
                    <CustomTable data={data} numColumns="7" />
                    <CustomTable data={data} numColumns="7" />
                    <CustomTable data={data} numColumns="7" />
                    <CustomTable data={data} numColumns="7" />
                </div>
            </div>

        </div>
    );
};


export default CurrentCards;
