import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import React from "react";
import ProductTable from "../../productManagement/subscriptionProduct/components/ProductTable";
import CardMenu from "../../../../components/card/CardMenu";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import PrintButton from "../../../../components/filterButton/printBtn";

const SubscriptionManagement = () => {
    const { t } = useTranslation();

    return (
        <div>
            <div>
                <ScreanTitle title={t('CORPORATE_SUBSCRIPTION_MANAGEMENT_SCREEN')}></ScreanTitle>
                <div className={'flex justify-between pb-3'}>
                    <div>
                        <button className="btn btn-primary mx-3 flex items-center">
                            <span className={'pe-3'}>{t('ADD_A_NEW_PRODUCT')}</span>
                            <em className={'icon-plus'}></em>
                        </button>
                    </div>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                        <CardMenu
                        classList="icon-filter fs-25"
                        menu={<div className="z-50">
                            <p className="text-black cursor-pointer">
                                فلتر 1
                            </p>
                            <p className="text-black cursor-pointer">
                                فلتر 2
                            </p>
                            <p className="text-black cursor-pointer">
                                فلتر 3
                            </p>
                            <p className="text-black cursor-pointer">
                                فلتر 4
                            </p>
                        </div>
                        }
                        />
                    </div>
                </div>
            </div>
            <div className="mt-1 grid h-full grid-cols-1 md:grid-cols-1">
                <ProductTable/>
            </div>
        </div>
    );
};


export default SubscriptionManagement;
