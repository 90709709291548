import React from "react";
import Dropdown from "components/dropdown";
import isRtl from "../../utils/langUtils";

interface CardMenuProps {
    classList?: string;
    top?: string;
    menu?: React.ReactNode;
    btn?: React.ReactNode | null;
}

const CardMenu: React.FC<CardMenuProps> = ({ classList, menu, btn, top }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <Dropdown
            button={
                !btn ? (
                    <button
                        onClick={() => setOpen(!open)}
                        className={`flex items-center justify-center transition duration-200 action-btn-lg primary me-3`}
                    >
                        <span className={classList}>
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </span>
                    </button>
                ) : (
                    btn
                )
            }
            animation={`${isRtl ? 'origin-top-left' : 'origin-top-right'} top-[60px] transition-all duration-300 ease-in-out`}
            classNames={`${isRtl ? 'left-0' : 'right-0'} w-max`}
            children={
                <div className="dropDownFilter z-50 w-[130px] rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500">
                    {menu}
                </div>
            }
        />
    );
}

export default CardMenu;
