import Links from "./components/Links";
import { HiX } from "react-icons/hi";
import sidebarImg from './../../assets/img/layout/sidebar.png'
import routes from "routes";
import React from "react";
import isMobile from "../../utils/mobileUtils";
import {useTranslation} from "react-i18next";
import isRtl from "../../utils/langUtils";

const Sidebar = (props: {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement>;
}) => {
  const { open, onClose } = props;
  const isMobileView = isMobile();
    const { t } = useTranslation();
  return (
      <div className={'relative lg:pt-[100px]'}>
          {/*<div className={'progress'}>*/}
          {/*    <Progress value={80} color="pink" />*/}
          {/*</div>*/}
        <div className={`sidebar sm:none duration-175 w-[250px] fixed  overflow-auto transition-all bg-gray-200 md:bg-none z-50
      ${open ? "translate-x-0" : "-translate-x-96"}
      ${isRtl && open ? "translate-x-0" : "translate-x-96 closed-sidebar"}`}
        >
        <span
            className={`absolute top-4  block cursor-pointer xl:hidden ${isRtl ? 'left-4' : 'right-4'}`}
            onClick={onClose}
        >
          <HiX />
        </span>



          <div className={'sidebar-content pe-[15px]'}>
            <ul className="mb-auto pt-1 max-h[80vh]">
              <Links routes={routes} onClose={onClose} />
            </ul>
            <div className={'ps-3 mb-4'}>
                <div className={`px-4 pt-2 pb-5 border-radius-10 ${ !open ? 'hidden' : '' }`}
                     style={{ backgroundImage: `url(${sidebarImg})` , backgroundSize:'cover' , backgroundRepeat:'repeat'}}>
                    <p className={'pb-2 font-semiBold text-center fs-17'}>{t('SIDEBAR.REWARD_STORE')} </p>
                    <div className={'bg-white flex justify-between py-2 px-2 border-radius-10 items-center'}>
                        <p >مشاركة متجر انفينيت </p>
                        <em className={'icon icon-manager text-primary-500'}></em>
                    </div>
                    <div className={' flex justify-center pt-1'}>
                        <button  className="btn btn-outline mt-3
                                 font-medium transition duration-200">
                            {t('SIDEBAR.COPY_LINK')}
                        </button>
                    </div>
                </div>
            </div>
          </div>
        </div>
        {open && isMobileView &&  <span  onClick={onClose} className={'overlay md:hidden duration-175  min-h-full'}/>}
      </div>
  );
};

export default Sidebar;
