import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import React, {useState} from "react";
import avatar2 from "../../../../assets/img/avatars/avatar2.png";
import QR from "../../../../assets/img/qr.png";
import {useNavigate} from "react-router-dom";
import CardMenu from "../../../../components/card/CardMenu";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import PrintButton from "../../../../components/filterButton/printBtn";

interface CardData {
    title?: string;
}
const CurrentCoupons: React.FC<CardData> = () => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const { t } = useTranslation();
    const data = [
        { title: t('ALL_COUPONS') },
        { title: t('PAYED_COUPONS') },
        { title: t('NOT_PAYED_COUPONS') },
        { title: t('EXPIRED_COUPONS') },
    ];
    const handleCardClick = (index: number) => {
        setActiveIndex(index);
    };
    const navigate = useNavigate();
    const redirectToViewCoupon  = () => {
        navigate('/private/voucher-products/current-coupons/view-coupon');
    };
    return (
        <div>
            <div>
                <ScreanTitle title={t('PAYED_COUPONS')}></ScreanTitle>
                <div className={'flex justify-end pb-3'}>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                               <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    فلتر 1
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 2
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 3
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 4
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>

            <div className="mt-3 checkListCard grid grid-cols-2 gap-2 md:gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-0 pb-3">
                {data.map((item, index) => (
                    <div
                        key={index}
                        className={`simple-card py-2 md:min-w-[220px] ${index === activeIndex && 'active'}`}
                        onClick={() => handleCardClick(index)}
                    >
                        <p className= {` text-center  fs-19 ${index === activeIndex ? 'font-bold text-primary-500 border-b-primary-500' : 'font-medium text-gray-600'}`}>{item.title}</p>
                    </div>
                ))}
            </div>


            <div className="bg-white steppedTable custom-shadow mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1 bg-white p-3 border-radius-20 overflow-hidden">
                    <div className="stepped-element grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-13 px-4 pt-2 pb-1">
                        <div>
                            <div className={'flex items-center'}>
                                <span className={'font-regular fs-15 text-gray-600'}> 1</span>
                                <div className={'ps-2'}>
                                    <p className={'font-regular fs-15 pb-1'}>{t('CLIENT')}</p>
                                    <img src={avatar2} alt="avatar1" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>
                                </div>
                            </div>

                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('QR_COUPON')}</p>
                            <img src={QR} alt="avatar1" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('COUPON_CODE')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>545465</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('NAME_COUPON_HOLDER')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>بشار بعاج</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('DISPLAY_NAME')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>وجبة مقابل وجبة</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('THE_NUMBER')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>3</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('OFFER_PRICE')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>50 ريال</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('CITY')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>الرياض</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('BYE_DATE')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>1-1-2023</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('END_DATE')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>10-1-2023</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('DEALER')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>كناكي</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('STATE')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>غير مفعلة</p>
                        </div>

                        <div >
                            <p className={'font-regular fs-15 pb-1'}>{t('REVIEW')}</p>
                            <button className="action-btn border-primary bg-white " onClick={redirectToViewCoupon}>
                                <em className={'icon-search-normal fs-16 text-primary-500'} />
                            </button>
                        </div>
                    </div>
                    <div className="stepped-element grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-13 px-4 pt-2 pb-1">
                        <div>
                            <div className={'flex items-center'}>
                                <span className={'font-regular fs-15 text-gray-600'}> 2</span>
                                <div className={'ps-2'}>
                                    <p className={'font-regular fs-15 pb-1'}>{t('CLIENT')}</p>
                                    <img src={avatar2} alt="avatar1" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>
                                </div>
                            </div>

                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('QR_COUPON')}</p>
                            <img src={QR} alt="avatar1" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('COUPON_CODE')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>545465</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('NAME_COUPON_HOLDER')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>بشار بعاج</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('DISPLAY_NAME')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>وجبة مقابل وجبة</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('THE_NUMBER')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>3</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('OFFER_PRICE')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>50 ريال</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('CITY')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>الرياض</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('BYE_DATE')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>1-1-2023</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('END_DATE')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>10-1-2023</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('DEALER')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>كناكي</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('STATE')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>غير مفعلة</p>
                        </div>

                        <div >
                            <p className={'font-regular fs-15 pb-1'}>{t('REVIEW')}</p>
                            <button className="action-btn border-primary bg-white " onClick={redirectToViewCoupon}>
                                <em className={'icon-search-normal fs-16 text-primary-500'} />
                            </button>
                        </div>
                    </div>
                    <div className="stepped-element grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-13 px-4 pt-2 pb-1">
                        <div>
                            <div className={'flex items-center'}>
                                <span className={'font-regular fs-15 text-gray-600'}>3</span>
                                <div className={'ps-2'}>
                                    <p className={'font-regular fs-15 pb-1'}>{t('CLIENT')}</p>
                                    <img src={avatar2} alt="avatar1" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>
                                </div>
                            </div>

                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('QR_COUPON')}</p>
                            <img src={QR} alt="avatar1" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('COUPON_CODE')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>545465</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('NAME_COUPON_HOLDER')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>بشار بعاج</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('DISPLAY_NAME')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>وجبة مقابل وجبة</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('THE_NUMBER')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>3</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('OFFER_PRICE')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>50 ريال</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('CITY')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>الرياض</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('BYE_DATE')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>1-1-2023</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('END_DATE')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>10-1-2023</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('DEALER')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>كناكي</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('STATE')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>غير مفعلة</p>
                        </div>

                        <div >
                            <p className={'font-regular fs-15 pb-1'}>{t('REVIEW')}</p>
                            <button className="action-btn border-primary bg-white" onClick={redirectToViewCoupon}>
                                <em className={'icon-search-normal fs-16 text-primary-500'} />
                            </button>
                        </div>
                    </div>
            </div>

        </div>
    );
};


export default CurrentCoupons;
