import InputField from "../../../../components/fields/InputField";
import React from "react";
import Filter from "../../../../components/filter/Filter";
import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import Popover from "../../../../components/popover";
import CheckCard from "../../../../components/card/CheckCard";
import Checkbox from "../../../../components/checkbox";
import {useNavigate} from "react-router-dom";
import CardMenu from "components/card/CardMenu";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import PrintButton from "../../../../components/filterButton/printBtn";

const WarrantManagement = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const redirectToPermission2 = () => {
        navigate('/private/user-management/permission/permission-2');
    };
    return (
        <div>
            <div>
                <ScreanTitle title={t('ADD_EMPLOYEE_SCREEN_WITH_ADDED_POWERS')}></ScreanTitle>
                <div className={'flex justify-between pb-3'}>
                    <div>
                        <button onClick={redirectToPermission2} className="btn btn-primary mx-3 flex items-center">
                            <span className={'pe-3'}>{t('ADD_PERMISSION')}</span>
                            <em className={'icon-plus'}></em>
                        </button>
                    </div>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                               <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    فلتر 1
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 2
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 3
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 4
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>

            <div className="white-card  h-full w-full mt-1 grid h-full grid-cols-1 gap-5 md:grid-cols-1  border-radius-40">
                <div className={'flex justify-between'}>
                    <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('ADD_PERMISSION')}</p>
                    <div className="flex items-center">
                        <div className={'px-2'}>
                            <Checkbox id={'CHECK_ALL'} color={'yellow'} label={t('CHECK_ALL')}/>
                        </div>
                        <div className={'px-2'}>
                            <Checkbox id={'UNCHECK_ALL'} color={'yellow'} label={t('UNCHECK_ALL')}/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-4 lg:grid-cols-4 2xl:grid-cols-10 pb-3">
                        <div className={'col-span-1 md:col-span-3'}>
                            <CheckCard
                                headerContent={
                                    <div className="flex items-center justify-between">
                                        <div className={'px-2'}>
                                            <p className={'font-bold fs-16'}>{t('MANAGEMENT')}</p>
                                        </div>
                                        <div className={'px-2'}>
                                            <Checkbox id={'CHECK_ALL_1'} color={'yellow'} label={t('CHECK_ALL')}/>
                                        </div>
                                    </div>
                                }
                                bodyContent={
                                    <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-2">
                                        <div>
                                            <Checkbox id={'CREATE_A_NEW_EMPLOYEE'} color="yellow" label={t('CREATE_A_NEW_EMPLOYEE')}  />
                                        </div>
                                        <div>
                                            <Checkbox id={'WEBSITE_MANAGEMENT'} color="yellow" label={t('WEBSITE_MANAGEMENT')}  />
                                        </div>
                                        <div>
                                            <Checkbox id={'MODIFY_EMPLOYEE_SETTINGS'} color="yellow" label={t('MODIFY_EMPLOYEE_SETTINGS')}  />
                                        </div>
                                        <div>
                                            <Checkbox id={'APPLICATION_MANAGEMENT'} color="yellow" label={t('APPLICATION_MANAGEMENT')}  />
                                        </div>
                                        <div>
                                            <Checkbox id={'SYSTEM_SETTINGS'} color="yellow" label={t('SYSTEM_SETTINGS')}  />
                                        </div>
                                        <div>
                                            <Checkbox id={'STOP_EMPLOYEE_ACCOUNT'} color="yellow" label={t('STOP_EMPLOYEE_ACCOUNT')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'CREATE_A_NEW_SUBSCRIPTION_OFFER'} color="yellow" label={t('CREATE_A_NEW_SUBSCRIPTION_OFFER')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'CREATE_A_NEW_SUBSCRIPTION_OFFER'} color="yellow" label={t('CREATE_A_NEW_SUBSCRIPTION_OFFER')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'ADD_A_NEW_COUPON_OFFER'} color="yellow" label={t('ADD_A_NEW_COUPON_OFFER')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'MODIFY_A_CUSTOMER_SUBSCRIPTION'} color="yellow" label={t('MODIFY_A_CUSTOMER_SUBSCRIPTION')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'CREATE_CARDS'} color="yellow" label={t('CREATE_CARDS')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'EXTEND_SUBSCRIPTION_DATE'} color="yellow" label={t('EXTEND_SUBSCRIPTION_DATE')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'CREATE_MARKETING_CAMPAIGNS'} color="yellow" label={t('CREATE_MARKETING_CAMPAIGNS')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'CUSTOMER_POINTS'} color="yellow" label={t('CUSTOMER_POINTS')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'MAILBOX'} color="yellow" label={t('MAILBOX')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'EMPLOYMENT_MANAGEMENT'} color="yellow" label={t('EMPLOYMENT_MANAGEMENT')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'ALLOW_CHAT'} color="yellow" label={t('ALLOW_CHAT')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'COMPANY_EMPLOYEES'} color="yellow" label={t('COMPANY_EMPLOYEES')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'CREATE_CARDS'} color="yellow" label={t('CREATE_CARDS')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'EXTEND_SUBSCRIPTION_DATE'} color="yellow" label={t('EXTEND_SUBSCRIPTION_DATE')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'CREATE_MARKETING_CAMPAIGNS'} color="yellow" label={t('CREATE_MARKETING_CAMPAIGNS')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'CUSTOMER_POINTS'} color="yellow" label={t('CUSTOMER_POINTS')} />
                                        </div>
                                    </div>
                                }
                                theme="navy-100 indigo"
                            />
                        </div>
                        <div className={'col-span-1 md:col-span-3'}>
                            <CheckCard
                                headerContent={
                                    <div className="flex items-center justify-between">
                                        <div className={'px-2'}>
                                            <p className={'font-bold fs-16'}>{t('MANAGEMENT')}</p>
                                        </div>
                                        <div className={'px-2'}>
                                            <Checkbox id={'CHECK_ALL_1'} color={'yellow'} label={t('CHECK_ALL')}/>
                                        </div>
                                    </div>
                                }
                                bodyContent={
                                    <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-2">
                                        <div>
                                            <Checkbox id={'CREATE_VOUCHER'} color="yellow" label={t('CREATE_VOUCHER')}  />
                                        </div>
                                        <div>
                                            <Checkbox id={'CUSTOMER_POINTS'} color="yellow" label={t('CUSTOMER_POINTS')}  />
                                        </div>
                                        <div>
                                            <Checkbox id={'CURRENT_COUPONS'} color="yellow" label={t('CURRENT_COUPONS')}  />
                                        </div>
                                        <div>
                                            <Checkbox id={'ABANDONED_BASKETS'} color="yellow" label={t('ABANDONED_BASKETS')}  />
                                        </div>
                                        <div>
                                            <Checkbox id={'VIEW_CUSTOMER_VISITS'} color="yellow" label={t('VIEW_CUSTOMER_VISITS')}  />
                                        </div>
                                        <div>
                                            <Checkbox id={'MANAGE_SECTIONS'} color="yellow" label={t('MANAGE_SECTIONS')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'ACTIVATE_DEALER_OFFER'} color="yellow" label={t('ACTIVATE_DEALER_OFFER')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'MANAGE_CLASSIFICATIONS'} color="yellow" label={t('MANAGE_CLASSIFICATIONS')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'SUBSCRIPTION_PRODUCTS'} color="yellow" label={t('SUBSCRIPTION_PRODUCTS')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'MANAGE_FILTERING'} color="yellow" label={t('MANAGE_FILTERING')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'SUBSCRIPTION_PRODUCTS'} color="yellow" label={t('SUBSCRIPTION_PRODUCTS')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'BROWSE_CONTRACTS'} color="yellow" label={t('BROWSE_CONTRACTS')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'CURRENT_COUPONS'} color="yellow" label={t('CURRENT_COUPONS')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'MODIFICATION_OF_SERVICES'} color="yellow" label={t('MODIFICATION_OF_SERVICES')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'ACTIVATE_DEALER_OFFER'} color="yellow" label={t('ACTIVATE_DEALER_OFFER')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'ADD_A_COMPLAINT'} color="yellow" label={t('ADD_A_COMPLAINT')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'VIEW_CUSTOMER_VISITS'} color="yellow" label={t('VIEW_CUSTOMER_VISITS')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'CLOSING_A_COMPLAINT'} color="yellow" label={t('CLOSING_A_COMPLAINT')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'SUBSCRIPTION_PRODUCTS'} color="yellow" label={t('SUBSCRIPTION_PRODUCTS')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'BROWSE_CONTRACTS'} color="yellow" label={t('BROWSE_CONTRACTS')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'CURRENT_COUPONS'} color="yellow" label={t('CURRENT_COUPONS')} />
                                        </div>
                                        <div>
                                            <Checkbox id={'MODIFICATION_OF_SERVICES'} color="yellow" label={t('MODIFICATION_OF_SERVICES')} />
                                        </div>
                                    </div>
                                }
                                theme="navy-100 indigo"
                            />
                        </div>
                        <div className={'col-span-1 md:col-span-4'}>
                            <div>
                                <div className="pb-4">
                                    <CheckCard
                                        headerContent={
                                            <div className="flex items-center justify-between">
                                                <div className={'px-2'}>
                                                    <p className={'font-bold fs-16'}>{t('ACCOUNTS')}</p>
                                                </div>
                                                <div className={'px-2'}>
                                                    <Checkbox id={'CHECK_ALL_1'} color={'yellow'} label={t('CHECK_ALL')}/>
                                                </div>
                                            </div>
                                        }
                                        bodyContent={
                                            <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-2">
                                                <div>
                                                    <Checkbox id={'VIEW_ELECTRONIC_PAYMENTS'} color="yellow" label={t('VIEW_ELECTRONIC_PAYMENTS')}  />
                                                </div>
                                                <div>
                                                    <Checkbox id={'البحث في المدفوعات الالكترونية'} color="yellow" label={t('البحث في المدفوعات الالكترونية')}  />
                                                </div>
                                                <div>
                                                    <Checkbox id={'ARCHIVE_OPERATIONS'} color="yellow" label={t('ARCHIVE_OPERATIONS')}  />
                                                </div>
                                                <div>
                                                    <Checkbox id={'VIEW_TAX'} color="yellow" label={t('VIEW_TAX')}  />
                                                </div>
                                                <div>
                                                    <Checkbox id={'PRINT_INVOICES'} color="yellow" label={t('PRINT_INVOICES')}  />
                                                </div>
                                                <div>
                                                    <Checkbox id={'VIEW_INVOICES'} color="yellow" label={t('VIEW_INVOICES')} />
                                                </div>
                                            </div>
                                        }
                                        theme="navy-200 indigo"
                                    />
                                </div>
                                <div>
                                    <CheckCard
                                        headerContent={
                                            <div className="flex items-center justify-between">
                                                <div className={'px-2'}>
                                                    <p className={'font-bold fs-16'}>{t('CONTRACTS_AND_FOLLOWUP')}</p>
                                                </div>
                                                <div className={'px-2'}>
                                                    <Checkbox id={'CHECK_ALL_1'} color={'yellow'} label={t('CHECK_ALL')}/>
                                                </div>
                                            </div>
                                        }
                                        bodyContent={
                                            <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-2">
                                                <div>
                                                    <Checkbox id={'ADD_NODE'} color="yellow" label={t('ADD_NODE')}  />
                                                </div>
                                                <div>
                                                    <Checkbox id={'ADD_COUPON'} color="yellow" label={t('ADD_COUPON')}  />
                                                </div>
                                                <div>
                                                    <Checkbox id={'CREATE_DEALER'} color="yellow" label={t('CREATE_DEALER')}  />
                                                </div>
                                                <div>
                                                    <Checkbox id={'ADD_COUPONS'} color="yellow" label={t('ADD_COUPONS')}  />
                                                </div>
                                                <div>
                                                    <Checkbox id={'CLOSING_A_COMPLAINT'} color="yellow" label={t('CLOSING_A_COMPLAINT')}  />
                                                </div>
                                                <div>
                                                    <Checkbox id={'HIDE_NODES'} color="yellow" label={t('HIDE_NODES')} />
                                                </div>
                                                <div>
                                                    <Checkbox id={'ADD_TASK'} color="yellow" label={t('ADD_TASK')} />
                                                </div>
                                                <div>
                                                    <Checkbox id={'VIEW_COMPLAINTS_AND_SUGGESTIONS'} color="yellow" label={t('VIEW_COMPLAINTS_AND_SUGGESTIONS')} />
                                                </div>
                                            </div>
                                        }
                                        theme="gray-200 indigo"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={'flex justify-center py-5 flex-wrap'}>
                        <button className="btn btn-primary mx-3">
                            <em className={'icon-pen'}></em>
                            <span className={'ps-3'}>{t('AMENDMENT')}</span>
                        </button>
                        <button className="btn btn-secondary mx-3">
                            <em className={'icon-check'}></em>
                            <span className={'ps-3'}>{t('SAVE')}</span>
                        </button>
                        <button className="btn btn-outline-secondary mx-3">
                            <em className={'icon-close'}></em>
                            <span className={'ps-3'}>{t('CLOSE')}</span>
                        </button>
                    </div>
                </div>
            </div>

        </div>
  );
};


export default WarrantManagement;
