import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import {Link} from "react-router-dom";
import logo from "../../assets/img/layout/logo.png";
import React from "react";

export default function SignIn() {
  return (
      <div className="flex w-full items-center justify-center px-2 lg:items-center">
          {/* Sign in section */}
          <div className="w-full max-w-full flex-col items-center md:pl-4 lg:px-32 xl:max-w-[715px] auth-card">
            <div className="flex justify-center">
              <img src={logo} alt="logo" className="h-[49px]" />
            </div>
            <div className={'pt-5'}>
              <h4 className="mb-2.5 font-semiBold  dark:text-white text-center fs-34">
                مرحباً بك !
              </h4>
              <p className="mb-9 ms-1 text-base text-center font-semiBold fs-34 pt-[8px]">
                قم بالتسجيل
              </p>
            </div>

            {/* Email */}
            <InputField
                variant="auth"
                extra="mb-3"
                label="البريد الإلكتروني"
                placeholder="البريد الإلكتروني"
                id="email"
                type="text"
            />
            <InputField
                variant="auth"
                extra="mb-3"
                label="إسم المستخدم"
                placeholder="إسم المستخدم "
                id="name"
                type="text"
            />
            {/* Password */}
            <InputField
                variant="auth"
                extra="mb-3"
                label="كلمة السر"
                placeholder="كلمة السر"
                id="password"
                type="password"
            />
            <InputField
                variant="auth"
                extra="mb-3"
                label="تأكيد كلمة السر"
                placeholder="تأكيد كلمة السر "
                id="password-confirm"
                type="password"
            />

              <div className={'flex justify-around pt-[10px]'}>
                <div className={'flex justify-center'}>
                  <Link to="/auth/sign-in">
                    <button className="btn btn-secondary linear  py-[12px]  font-regular text-white transition duration-200  dark:bg-navy-400 dark:text-white dark:hover:bg-navy-300 dark:active:bg-navy-200">
                      إنشاء</button>
                  </Link>
                </div>

              </div>
            </div>
          </div>
  );
}
