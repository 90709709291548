import React, {useEffect} from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PrivateLayout from "layouts/private";
import Home from "layouts/public/home/home";
import AuthLayout from "layouts/auth";
import PublicLayout from "./layouts/public/home/home";

const App = () => {
    const { i18n } = useTranslation();
    document.documentElement.dir = "rtl";
    useEffect(() => {
        if (i18n.language === "ar") {
            document.documentElement.dir = "rtl";
        }
        else {
            document.documentElement.dir = "ltr";
        }
    }, [i18n.language]);

    return (
        <Routes>
            <Route path="auth/*" element={<AuthLayout />} />
            <Route path="/*" element={<Home />} />
            <Route path="public/*" element={<PublicLayout />} />
            <Route path="private/*" element={<PrivateLayout />} />
            <Route path="/" element={<Navigate to="/home" replace />} />
        </Routes>
    );
};

export default App;
