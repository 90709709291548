import React from "react";
import {useTranslation} from "react-i18next";
import avatar3 from "../../../../../assets/img/avatars/avatar3.png";
import avatar5 from "../../../../../assets/img/avatars/avatar6.png";
import avatar2 from "../../../../../assets/img/avatars/avatar2.png";
import avatar4 from "../../../../../assets/img/avatars/avatar4.png";
import CardMenu from "../../../../../components/card/CardMenu";

const ProductTable = () => {
    const {t} = useTranslation();
    return (
        <div className="white-card  h-full w-full mt-5">
            <div className="flex justify-between pe-4 pb-5">
                <div className="flex ">
                    <p className={'title ps-6 pe-3 py-3 font-bold text-primary-500 fs-19'}>{t('PROPERTIES_REGISTRATION')}</p>
                </div>
            </div>
            <div className={'steppedTable pt-3'}>
                <div className="stepped-element grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-11 px-4 pt-2 pb-1">
                    <div className={'col-span-1'}>
                        <div className={'flex items-center'}>
                            <span className={'font-regular fs-15 text-gray-600'}> 1</span>
                            <div className={'ps-2'}>
                                <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIPS')}</p>
                                <img src={avatar4} alt="avatar4" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>
                            </div>
                        </div>

                    </div>
                    <div className={'col-span-2'}>
                        <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIP_HOLDER_NAME')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>بشار بعاج</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('CITY')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>الرياض</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIP_NUMBER')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>0563231328</p>
                    </div>
                    <div className={'col-span-1'}>
                        <p className={'font-regular fs-15 pb-1'}>{t('ID_NUMBER')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>145478</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('CREATE_DATE')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>1-1-2023</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('END_DATE')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>1-10-2023</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIP_TYPE')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>1-10-2023</p>
                    </div>

                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('REVIEW')}</p>
                        <CardMenu
                            btn={<button className="action-btn border-primary bg-white " >
                                <em className={'icon-search-normal fs-16 text-primary-500'} />
                            </button>}
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    ارسال الى مجموعة
                                </p>
                                <p className="text-black cursor-pointer">
                                    عملاء  الأكثر شراء
                                </p>
                                <p className="text-black cursor-pointer">
                                    أصحاب ولاء مرتفع
                                </p>
                                <p className="text-black cursor-pointer">
                                    الفئة الماسية
                                </p>
                                <p className="text-black cursor-pointer">
                                    ارشيف
                                </p>
                            </div>
                            }
                        />
                    </div>
                    <div className={'flex items-center cursor-pointer'}>
                        <p className={'font-regular fs-15 pb-1'}>{t('RELEASE')}</p>
                    </div>
                </div>
                <div className="stepped-element grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-11 px-4 pt-2 pb-1">
                    <div className={'col-span-1'}>
                        <div className={'flex items-center'}>
                            <span className={'font-regular fs-15 text-gray-600'}>2</span>
                            <div className={'ps-2'}>
                                <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIPS')}</p>
                                <img src={avatar2} alt="avatar1" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>
                            </div>
                        </div>

                    </div>
                    <div className={'col-span-2'}>
                        <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIP_HOLDER_NAME')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>بشار بعاج</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('CITY')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>الرياض</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIP_NUMBER')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>0563231328</p>
                    </div>
                    <div className={'col-span-1'}>
                        <p className={'font-regular fs-15 pb-1'}>{t('ID_NUMBER')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>145478</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('CREATE_DATE')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>1-1-2023</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('END_DATE')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>1-10-2023</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIP_TYPE')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>1-10-2023</p>
                    </div>

                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('REVIEW')}</p>
                        <CardMenu
                            btn={<button className="action-btn border-primary bg-white " >
                                <em className={'icon-search-normal fs-16 text-primary-500'} />
                            </button>}
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    ارسال الى مجموعة
                                </p>
                                <p className="text-black cursor-pointer">
                                    عملاء  الأكثر شراء
                                </p>
                                <p className="text-black cursor-pointer">
                                    أصحاب ولاء مرتفع
                                </p>
                                <p className="text-black cursor-pointer">
                                    الفئة الماسية
                                </p>
                                <p className="text-black cursor-pointer">
                                    ارشيف
                                </p>
                            </div>
                            }
                        />
                    </div>
                    <div className={'flex items-center cursor-pointer'}>
                        <p className={'font-regular fs-15 pb-1'}>{t('RELEASE')}</p>
                    </div>
                </div>
                <div className="stepped-element grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-11 px-4 pt-2 pb-1">
                    <div className={'col-span-1'}>
                        <div className={'flex items-center'}>
                            <span className={'font-regular fs-15 text-gray-600'}>3</span>
                            <div className={'ps-2'}>
                                <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIPS')}</p>
                                <img src={avatar5} alt="avatar4" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>
                            </div>
                        </div>

                    </div>
                    <div className={'col-span-2'}>
                        <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIP_HOLDER_NAME')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>بشار بعاج</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('CITY')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>الرياض</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIP_NUMBER')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>0563231328</p>
                    </div>
                    <div className={'col-span-1'}>
                        <p className={'font-regular fs-15 pb-1'}>{t('ID_NUMBER')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>145478</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('CREATE_DATE')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>1-1-2023</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('END_DATE')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>1-10-2023</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIP_TYPE')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>1-10-2023</p>
                    </div>

                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('REVIEW')}</p>
                        <CardMenu
                            btn={<button className="action-btn border-primary bg-white " >
                                <em className={'icon-search-normal fs-16 text-primary-500'} />
                            </button>}
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    ارسال الى مجموعة
                                </p>
                                <p className="text-black cursor-pointer">
                                    عملاء  الأكثر شراء
                                </p>
                                <p className="text-black cursor-pointer">
                                    أصحاب ولاء مرتفع
                                </p>
                                <p className="text-black cursor-pointer">
                                    الفئة الماسية
                                </p>
                                <p className="text-black cursor-pointer">
                                    ارشيف
                                </p>
                            </div>
                            }
                        />
                    </div>
                    <div className={'flex items-center cursor-pointer'}>
                        <p className={'font-regular fs-15 pb-1'}>{t('RELEASE')}</p>
                    </div>
                </div>
                <div className="stepped-element grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-11 px-4 pt-2 pb-1">
                    <div className={'col-span-1'}>
                        <div className={'flex items-center'}>
                            <span className={'font-regular fs-15 text-gray-600'}>4</span>
                            <div className={'ps-2'}>
                                <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIPS')}</p>
                                <img src={avatar3} alt="avatar1" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>
                            </div>
                        </div>

                    </div>
                    <div className={'col-span-2'}>
                        <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIP_HOLDER_NAME')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>بشار بعاج</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('CITY')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>الرياض</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIP_NUMBER')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>0563231328</p>
                    </div>
                    <div className={'col-span-1'}>
                        <p className={'font-regular fs-15 pb-1'}>{t('ID_NUMBER')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>145478</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('CREATE_DATE')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>1-1-2023</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('END_DATE')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>1-10-2023</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIP_TYPE')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>1-10-2023</p>
                    </div>

                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('REVIEW')}</p>
                        <CardMenu
                            btn={<button className="action-btn border-primary bg-white " >
                                <em className={'icon-search-normal fs-16 text-primary-500'} />
                            </button>}
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    ارسال الى مجموعة
                                </p>
                                <p className="text-black cursor-pointer">
                                    عملاء  الأكثر شراء
                                </p>
                                <p className="text-black cursor-pointer">
                                    أصحاب ولاء مرتفع
                                </p>
                                <p className="text-black cursor-pointer">
                                    الفئة الماسية
                                </p>
                                <p className="text-black cursor-pointer">
                                    ارشيف
                                </p>
                            </div>
                            }
                        />
                    </div>
                    <div className={'flex items-center cursor-pointer'}>
                        <p className={'font-regular fs-15 pb-1'}>{t('RELEASE')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductTable;