import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import CustomTable from "../../../../components/customTable/CustomTable";
import CardMenu from "../../../../components/card/CardMenu";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import PrintButton from "../../../../components/filterButton/printBtn";
import CustomSelect from "../../../../components/select/select";
import CustomDatePicker from "../../../../components/fields/DatePicker";
import QrCode from "../../../../assets/img/prod/qr.png";
import avatar4 from "../../../../assets/img/avatars/avatar4.png";
import Switch from "../../../../components/switch";
const EmployeeWallet = () => {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState<number>(0);
    type Option = typeof sampleOptions[0];
    const handleChange = (selectedValues: Option[]) => {
        console.log(selectedValues);
    };
    const handleCardClick = (index: number) => {
        setActiveIndex(index);
    };
    const sampleOptions = [
        {label: "option 1", value: "option-1"},
        {label: "option 2", value: "option-2"},
        {label: "option 3", value: "option-3"},
    ];
    const data = [
        { title: t('GOVERNOR_OF_THE_CLEANERS') },
        { title: t('REPORTS') }
    ];
    const dataTable = [
        {content:<div className={'flex items-center h-full'}>
                <span className={'font-regular fs-15 text-gray-600'}> 1</span>
            </div>, colSpan: 1 },
        { label: "EMPLOYEE", content: <img src={avatar4} alt="avatar4" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>, colSpan: 2 },

        { label: "DEALER_QR", content: <img src={QrCode} alt="QrCode" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>, colSpan: 2 },
        { label: "EMPLOYEE_NAME", content: "بشار بعاج", colSpan: 2 },
        { label: "WALLET_NUMBER", content: "4545", colSpan: 2 },
        { label: "JOB", content: "مندوب", colSpan: 1 },
        { label: "CITY", content:'الرياض', colSpan: 1 },
        { label: "PHONE", content:'2000563231328', colSpan: 2 },
        { label: "SOLD", content:'200 ريال', colSpan: 3 },
        { label: "CREATION_DATE", content:'1-1-2023', colSpan: 2 },
        { label: "FINISH_ORDER", content:<Switch /> , colSpan: 2 },
        { label: "REVIEW", content: <button className={'action-btn border-primary bg-white'}>
                <span className={'icon-search-normal fs-16 text-primary-500'}> <span className="path1"/> <span className="path2"/></span></button>, colSpan: 2 },
    ];
    return (
        <div>
            <div>
                <ScreanTitle title={t('TRADER_INFORMATION_SCREEN')}></ScreanTitle>
                <div className={'flex justify-end pb-3'}>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                        <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    فلتر 1
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 2
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 3
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 4
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="mt-3 checkListCard flex justify-center flex-wrap pb-3 gap-8">
                {data.map((item, index) => (
                    <div
                        key={index}
                        className={`simple-card py-2 md:min-w-[220px] ${index === activeIndex && 'active'}`}
                        onClick={() => handleCardClick(index)}
                    >
                        <p className= {` text-center  fs-19 ${index === activeIndex ? 'font-bold text-primary-500 border-b-primary-500' : 'font-medium text-gray-600'}`}>{item.title}</p>
                    </div>
                ))}
            </div>
            <div className="bg-white mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pb-6 dashed-border py-5">

                    <div>
                        <CustomSelect  onChange={handleChange}
                                       label={t('GOVERNOR_CLEANERS_NUMBER')}
                                       placeholder={t('GOVERNOR_CLEANERS_NUMBER')}
                                       options={sampleOptions}
                        />
                    </div>
                    <div>
                        <CustomSelect  onChange={handleChange}
                                       label={t('ACCORDING_EMPLOYEE')}
                                       placeholder={t('ACCORDING_EMPLOYEE')}
                                       options={sampleOptions}
                        />
                    </div>
                    <div>
                        <CustomDatePicker
                            label={t('START_DATE')}
                            placeholder={t('START_DATE')}
                        ></CustomDatePicker>
                    </div>
                    <div>
                        <CustomDatePicker
                            label={t('EXPIRY_DATE')}
                            placeholder={t('EXPIRY_DATE')}
                        ></CustomDatePicker>
                    </div>
                    <div className={'md:col-span-2 lg:col-span-3 2xl:col-span-4 flex justify-center items-end'}>
                        <button className="btn btn-secondary mx-3 flex items-center h-[45px] justify-center">
                            <span className={'pe-3'}>{t('FILTERING')}</span>
                        </button>
                    </div>
                </div>
            <div className="white-card h-full w-full mt-6">
                <div className="steppedTable py-0">
                    <CustomTable data={dataTable} numColumns={'22'} />
                    <CustomTable data={dataTable} numColumns={'22'} />
                    <CustomTable data={dataTable} numColumns={'22'} />
                    <CustomTable data={dataTable} numColumns={'22'} />
                </div>
            </div>
            <div className={'flex justify-center pb-4 pt-7'}>
                <button className="btn btn-primary mx-3">
                    <em className={'icon-pen'}></em>
                    <span className={'ps-3'}>{t('AMENDMENT')}</span>
                </button>
                <button className="btn btn-secondary mx-3">
                    <em className={'icon-check'}></em>
                    <span className={'ps-3'}>{t('SAVE')}</span>
                </button>
                <button className="btn btn-outline-secondary mx-3">
                    <em className={'icon-close'}></em>
                    <span className={'ps-3'}>{t('CLOSE')}</span>
                </button>
            </div>

        </div>
  );
};


export default EmployeeWallet;
