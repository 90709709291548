import React, {useEffect, useState} from "react";
import {Routes, Route, Navigate, useLocation, Link} from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import routes from "routes";
import isMobile from "../../utils/mobileUtils";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import CustomBreadcrumb from "../../components/CustomBreadcrumb/CustomBreadcrumb";

export default function Private(props: { [x: string]: any }) {
  const isMobileDevice = isMobile();
  const { brandText, ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(!isMobileDevice);
  const [currentRoute, setCurrentRoute] = React.useState("Dashboard");

  React.useEffect(() => {
    window.addEventListener("resize", () =>
        window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);


  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const getActiveRoute = (routes: RoutesType[]): string | boolean => {
    let activeRoute = "Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
          window.location.href.indexOf(
              routes[i].layout + "/" + routes[i].path
          ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: RoutesType[]): string | boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;

      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes: RoutesType[]): JSX.Element[] => {
    return routes.flatMap((route, key) => {
      if (route.layout === "/private") {
        if (route.children) {
          const parentRoute = (
              <Route
                  key={key}
                  path={`/${route.path}`}
                  element={route.component}
              >
                {route.children.map((childRoute: RoutesType, childKey: number) => (
                    <Route
                        key={childKey}
                        path={`/${route.path}/${childRoute.path}`}
                        element={childRoute.component}
                    />
                ))}
              </Route>
          );
          return [parentRoute, ...getRoutes(route.children)];
        } else {
          return (
              <Route
                  key={key}
                  path={`/${route.path}`}
                  element={route.component}
              />
          );
        }
      } else {
        return [];
      }
    });
  };

  return (
      <div className="flex h-full w-full">
        <Sidebar open={open} onClose={() => setOpen(false)} />
        <div className="h-full w-full bg-[#F3F0F0] dark:!bg-navy-900">
          <Navbar
              onOpenSidenav={() => setOpen(!open)}
              open={open}
              brandText={currentRoute}
              secondary={getActiveNavbar(routes)}
              {...rest}
          />
          {/* Main Content */}
          <main  className={`mx-[12px] h-full flex-none transition-all md:pr-2 pt-3  md:pr-2 ${open ? 'xl:ms-[250px]' : 'xl:ms-[110px]'}`}>

            <div className="h-full pb-5">

              <div className="relative pt-2 mx-auto mb-auto h-full min-h-[84vh] py-2 px-3 md:pr-3">
                 <div className={'md:absolute top-[0]'}>
                      <Breadcrumb routes={routes}></Breadcrumb>
                    </div>
                <Routes>
                  {getRoutes(routes)}
                  <Route path="/"
                      element={<Navigate to="/private/dashboard" replace />} />
                </Routes>
              </div>
            </div>
          </main>
        </div>
      </div>
  );
}
