import React from "react";
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Breadcrumb = ({ routes }: { routes: any }): JSX.Element => {
    const { t } = useTranslation();
    const location = useLocation();
    const isActive = (route: RoutesType): boolean => {
        if (location.pathname.includes(route.path)) {
            return true;
        }
        if (route.children) {
            return route.children.some((child: { path: string }) => location.pathname.includes(child.path));
        }
        return false;
    };

    const renderChildren = (parentPath: string, children: RoutesType[] | undefined): JSX.Element | null => {
        return (
            <div>
                {children?.map((child, index) => (
                    isActive(child) && (
                        <div key={index} className={'flex'}>
                            <div className="">
                                <Link
                                    className={`ps-2 font-regular fs-14`}
                                    to={child.path}
                                >
                                    <span> {'>'} </span> {t(child.name)}
                                </Link>
                            </div>
                            {child.children && (
                                    renderChildren(parentPath + "/" + child.path, child.children)
                            )}
                        </div>
                    )
                ))}
            </div>
        );
    };


    const createBreadcrumb = (routes: RoutesType[]) => {
        const activeRouteIndex = routes.findIndex((route: RoutesType) => isActive(route));
        if (activeRouteIndex !== -1) {
            const activeRoute = routes[activeRouteIndex];
            const parentPath = activeRoute.layout + "/" + activeRoute.path;
            return (
                <div className="flex items-center">
                    <span className="font-regular fs-14">{t("SIDEBAR." + activeRoute.name)}</span>
                    {activeRoute.children && renderChildren(parentPath, activeRoute.children)}
                </div>
            );
        }
        return null;
    };

    return <>{createBreadcrumb(routes)}</>;
};

export default Breadcrumb;
