import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";

import React from "react";
import CustomSelect from "../../../../components/select/select";
import CardMenu from "../../../../components/card/CardMenu";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import PrintButton from "../../../../components/filterButton/printBtn";
import {useNavigate} from "react-router-dom";
import client from "../../../../assets/img/prod/Group1.png";
import QrCode from "../../../../assets/img/prod/qr.png";
import Switch from "../../../../components/switch";
import CustomTable from "../../../../components/customTable/CustomTable";
import CustomDatePicker from "../../../../components/fields/DatePicker";

const ServiceProviderPortfolios = () => {
    const { t } = useTranslation();
    type Option = typeof sampleOptions[0];
    const handleChange = (selectedValues: Option[]) => {
        console.log(selectedValues);
    };
    const sampleOptions = [
        {label: "option 1", value: "option-1"},
        {label: "option 2", value: "option-2"},
        {label: "option 3", value: "option-3"},
    ];
    const navigate = useNavigate();
    const viewPortfolios = () => {
        navigate('/private/portfolio-management/service-provider-portfolios/details');
    };
    const dataTable = [
        {content:<div className={'flex items-center h-full'}>
                <span className={'font-regular fs-15 text-gray-600'}> 1</span>
            </div>, colSpan: 1 },
        { label: "CLIENT", content:<img src={client} alt="avatar4" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>, colSpan: 1 },
        { label: "DEALER_QR", content: <img src={QrCode} alt="avatar4" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>, colSpan: 1 },
        { label: "DEALER_CODE", content: "25487", colSpan: 1 },
        { label: "DEALER_NAME", content: "شركة البيك", colSpan: 1 },
        { label: "BRANCHES_NUMBER", content: "1", colSpan: 1 },
        { label: "OFFERS_NUMBER", content:'3', colSpan: 1 },
        { label: "ACTIVE_OFFERS_NUMBER", content:'2', colSpan: 1 },
        { label: "AMOUNT_VALUE", content:'190', colSpan: 1 },
        { label: "DATE_OF_CONTRACT", content:'1-1-2023', colSpan: 1 },
        { label: "DATE_END_CONTRACT", content:'1-10-2023', colSpan: 1 },
        { label: "FINISH_ORDER", content: <Switch />, colSpan: 1 },
        { label: "REVIEW", content: <button className={'action-btn border-primary bg-white'} onClick={viewPortfolios}>
                <span className={'icon-search-normal fs-16 text-primary-500'}> <span className="path1"/> <span className="path2"/></span></button>, colSpan: 1 },


    ];

    return (
        <div>
            <div>
                <ScreanTitle title={t('ADMIN_MERCHANTS_PORTFOLIOS_SCREEN')}></ScreanTitle>
                <div className={'flex justify-end pb-3'}>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                               <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    الكل
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب الاسم
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب العرض
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب المدينة
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <div className={'white-card pb-3 pt-1'}>
                <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pb-6 dashed-border py-5">

                    <div>
                        <CustomSelect  onChange={handleChange}
                                       label={t('COUNTRY')}
                                       placeholder={t('COUNTRY')}
                                       options={sampleOptions}
                        />
                    </div>
                    <div>
                        <CustomSelect  onChange={handleChange}
                                       label={t('COMPANY_NAME_DEALER')}
                                       placeholder={t('COMPANY_NAME_DEALER')}
                                       options={sampleOptions}
                        />
                    </div>
                    <div>
                        <CustomDatePicker label={t('START_DATE')} placeholder={t('START_DATE')}></CustomDatePicker>
                    </div>
                    <div>
                        <CustomDatePicker label={t('EXPIRY_DATE')}  placeholder={t('EXPIRY_DATE')}></CustomDatePicker>
                    </div>
                    <div className={'md:col-span-2 lg:col-span-3 2xl:col-span-4 flex justify-center items-end'}>
                        <button className="btn btn-secondary mx-3 flex items-center h-[45px] justify-center">
                            <span className={'pe-3'}>{t('FILTERING')}</span>
                        </button>
                    </div>
                </div>
                <div className="flex justify-center gap-8 pt-5">
                    <div className={'sm-card md:min-w-[200px]'}>
                        <p className={'font-bold fs-17 text-center'}>{t('TOTAL_NUMBER_OF_COUPON_OFFERS')}</p>
                        <p className={'font-bold fs-32 text-center value'}>136</p>
                        <p className={'font-regular fs-15 text-center'}>{t('COUPON')}</p>
                    </div>
                    <div className={'sm-card md:min-w-[200px]'}>
                        <p className={'font-bold fs-17 text-center'}>{t('TOTAL_AMOUNTS_OF_ACTIVE_OFFERS')}</p>
                        <p className={'font-bold fs-32 text-center value'}>13160</p>
                        <p className={'font-regular fs-15 text-center'}>ريال </p>
                    </div>
                    <div className={'sm-card md:min-w-[200px]'}>
                        <p className={'font-bold fs-17 text-center'}>{t('TOTAL_AMOUNTS_DUE')}</p>
                        <p className={'font-bold fs-32 text-center value'}>13160</p>
                    </div>
                </div>
            </div>
            <div className="white-card h-full w-full mt-3">
                <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('NEW_CARD_DISPLAY_ENTRY_SCREEN')}</p>

                <div className="steppedTable">
                    <CustomTable data={dataTable} numColumns={'13'} />
                    <CustomTable data={dataTable} numColumns={'13'} />
                    <CustomTable data={dataTable} numColumns={'13'} />
                    <CustomTable data={dataTable} numColumns={'13'} />
                </div>
            </div>
        </div>
    );
};
export default ServiceProviderPortfolios;
