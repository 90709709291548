import React, { ReactNode, useState } from 'react';
import isMobile from "../../utils/mobileUtils";
import {useTranslation} from "react-i18next";

interface AccordionProps {
    children: ReactNode;
    showButton ?: boolean;
    filterTitle ?: string;
}

const Filter: React.FC<AccordionProps> = ({ children,filterTitle, showButton }) => {
    const isMobileView = isMobile();
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(true);
    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            {(isMobileView || showButton) &&
                <div
                    className="flex items-center  cursor-pointer pb-3"
                >
                    <button className="btn btn-primary mx-3"  onClick={toggleAccordion}>
                        <em className={'icon-plus'}></em>
                        <span className={'ps-3'}>{filterTitle}</span>
                    </button>
                </div>
            }

            {isOpen && (
               <div className={'bg-white border-radius-40'}>
                   {children}
               </div>
            )}
        </div>
    );
};

export default Filter;
