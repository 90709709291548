export const recommendedChartOptions = {
  labels: ["Extremely likely", "Likely", "Not sure", "Unlikely"],
  colors: ["#C58ADF", "#93C4EA", "#E6D1F4", "#B1BEE9"],
  chart: {
    width: "180px",
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  legend: {
    show: true,
    position: 'bottom',
  },
  dataLabels: {
    enabled: true,
  },
  hover: { mode: null },
  plotOptions: {
    donut: {
      expandOnClick: true,
      donut: {
        labels: {
          show: true,
        },
      },
    },
  },
  fill: {
    colors: ["#C58ADF", "#93C4EA", "#E6D1F4", "#B1BEE9"],
  },
  tooltip: {
    enabled: true,
    theme: "dark",
    style: {
      fontSize: "12px",
      fontFamily: undefined,
      backgroundColor: "#000000",
    },
  },
};
export const recommendedChartData = [25, 18, 15,20, 10];


export const StaffChartData = [
  {
    name: "Yes",
    data: [20, 30, 40],
  },
  {
    name: "No",
    data: [50, 10, 19],
  },

];

export const StaffChartOption = {
  colors: ["#0D45A3", "#2194F5"],
  legend: {
    show: true,
    position: 'bottom'
  },

  theme: {
    mode: "light",
  },
  chart: {
    type: "bar",
    toolbar: {
      show: false,
    },
  },

  tooltip: {
    style: {
      fontSize: "12px",
      fontFamily: undefined,
      backgroundColor: "#000000",
    },
    theme: "dark",
    x: {
      format: "dd/MM/yy HH:mm",
    },
  },
  grid: {
    show: true,
  },
  yaxis: {
    show: true,
    axisBorder: {
      show: true,
    },
  },

  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '13px',
      borderRadius: 10,
    },
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: false,
    curve: "smooth",
    width: 2,
    colors: ['transparent']
  },
  xaxis: {
    categories: ["Hygienic", "Uniform on", "ID Card on"],
    show: true,
    labels: {
      show: true,
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: true,
    },
    axisTicks: {
      show: false,
    },
  },

  fill: {
    colors: ["#DEB660", "#FFE6B1"],
  },
};



export const ServiceChartData = [
  {
    data: [20, 30, 40, 20, 60 ,70, 45],
  },
];

export const ServiceChartOption = {
  colors: ["#FFE6B1"],
  legend: {
    show: true,
    position: 'bottom'
  },
  theme: {
    mode: "light",
  },
  chart: {
    type: "bar",
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    style: {
      fontSize: "12px",
      fontFamily: 'cairo',
      backgroundColor: "#000000",
    },
    theme: "dark",
    x: {
      format: "dd/MM/yy HH:mm",
    },
  },
  grid: {
    show: false,
  },
  yaxis: {
    show: false,
    opposite: true,
    labels: {
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '35px',
      borderRadius: 10,
      dataLabels: {
        position: 'top', // Show data labels at the top of each bar
      }
    },
  },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: '13px',
      fontFamily: 'cairo',
      fontWeight: 'regular',
      colors: ['#000'],
    },
    offsetY: -20, // Adjust vertical position of data labels
    formatter: function(val) {
      return val; // Return the value of the bar as the label
    }
  },
  annotations: {
    points: [{
      x: 0, // X-coordinate
      y: 500, // Y-coordinate (Adjust as needed)
      marker: {
        size: 0,
      },
      label: {
        borderColor: '#FF4560',
        style: {
          color: '#fff',
          background: '#FF4560',
        },
        text: '200', // Display the value at the top
      }
    }]
  },
  stroke: {
    show: false,
    curve: "smooth",
    width: 2,
    colors: ['transparent']
  },
  xaxis: {
    categories: ["السبت", "الاحد", "الاثنين",    "الثلاثاء", "الاربعاء", "الخميس" , "الجمعة"],
    show: true,
    labels: {
      show: true,
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'light',
      type: 'vertical',
      shadeIntensity: 0.5,
      gradientToColors: ['#DEB660'],
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100]
    }
  },
};


// export const ServiceChartOption = {
//   colors: ["#FFE6B1"],
//   legend: {
//     show: true,
//     position: 'bottom'
//   },
//   theme: {
//     mode: "light",
//   },
//   chart: {
//     type: "bar",
//     toolbar: {
//       show: false,
//     },
//   },
//   tooltip: {
//     style: {
//       fontSize: "12px",
//       fontFamily: undefined,
//       backgroundColor: "#000000",
//     },
//     theme: "dark",
//     x: {
//       format: "dd/MM/yy HH:mm",
//     },
//   },
//   grid: {
//     show: false,
//   },
//   yaxis: {
//     show: true,
//     opposite: true,
//     axisBorder: {
//       show: false,
//     },
//   },
//   plotOptions: {
//     bar: {
//       horizontal: false,
//       columnWidth: '35px',
//       borderRadius: 10,
//       dataLabels: {
//         position: 'top', // Show data labels at the top of each bar
//       }
//     },
//   },
//   dataLabels: {
//     enabled: true,
//     style: {
//       fontSize: '14px',
//       fontWeight: 'bold',
//       colors: ['#000'],
//     },
//     offsetY: -20, // Adjust vertical position of data labels
//     formatter: function(val) {
//       return val; // Return the value of the bar as the label
//     }
//   },
//   stroke: {
//     show: false,
//     curve: "smooth",
//     width: 2,
//     colors: ['transparent']
//   },
//   xaxis: {
//     categories: ["السبت", "الاحد", "الاثنين",    "الثلاثاء", "الاربعاء", "الخميس" , "الجمعة"],
//     show: true,
//     labels: {
//       show: true,
//       style: {
//         colors: "#A3AED0",
//         fontSize: "14px",
//         fontWeight: "500",
//       },
//     },
//     axisBorder: {
//       show: false,
//     },
//     axisTicks: {
//       show: false,
//     },
//   },
//   fill: {
//     type: 'gradient',
//     // colors: ["#ff004b"],
//       gradient: {
//         shade: 'light',
//         type: 'vertical',
//         shadeIntensity: 0.5,
//         gradientToColors: ['#DEB660'],
//         inverseColors: false,
//         opacityFrom: 1,
//         opacityTo: 1,
//         stops: [0, 100]
//       }
//   },
//
// };

// *******************/******************** //
//  عدد العملاء المشترون من الزائرين

export const NumberCustomersOptions = {
  labels: ["اشترى", "لم يشتري"],
  colors: ["#826E51", "#826E518C"],
  chart: {
    width: "180px",
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  legend: {
    show: true,
    position: 'bottom',
  },
  dataLabels: {
    enabled: true,
  },
  hover: { mode: null },
  plotOptions: {
    donut: {
      expandOnClick: true,
      donut: {
        labels: {
          show: true,
        },
      },
    },
  },
  fill: {
    colors: ["#826E51", "#826E518C"],
  },
  tooltip: {
    enabled: true,
    theme: "dark",
    style: {
      fontSize: "12px",
      fontFamily: undefined,
      backgroundColor: "#000000",
    },
  },
};
export const NumberCustomersChartData = [25, 18];

// تقيمات العملاء
export const CustomerReviewsOptions = {
  labels: ["تقيم  5 نجوم", "تقيم 4 نجوم", "تقيم  2 نجمة", "تقيم 3 نجوم"],
  colors: ["#DEB660", "#ADA7A1", "#A79065", "#A79065"],
  chart: {
    width: "180px",
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  legend: {
    show: true,
    position: 'bottom',
  },
  dataLabels: {
    enabled: true,
  },
  hover: { mode: null },
  plotOptions: {
    donut: {
      expandOnClick: true,
      donut: {
        labels: {
          show: true,
        },
      },
    },
  },
  fill: {
    colors: ["#DEB660", "#ADA7A1", "#A79065", "#A79065"],
  },
  tooltip: {
    enabled: true,
    theme: "dark",
    style: {
      fontSize: "12px",
      fontFamily: 'cairo',
      backgroundColor: "#000000",
    },
  },
};
export const CustomerReviewsChartData = [300, 250, 150,200];


// اكثر طرق الدفع

export const PaymentMethodsOptions = {
  labels: [" البنوك", "STC PAY", "من النقاط", "APPLE PAY"],
  colors: ["#8D7B60", "#E6D2A9", "#AFADAD", "#D5CDC1"],
  chart: {
    width: "180px",
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  legend: {
    show: true,
    position: 'bottom',
  },
  dataLabels: {
    enabled: true,
  },
  hover: { mode: null },
  plotOptions: {
    donut: {
      expandOnClick: true,
      donut: {
        labels: {
          show: true,
        },
      },
    },
  },
  fill: {
    colors: ["#8D7B60", "#E6D2A9", "#AFADAD", "#D5CDC1"],
  },
  tooltip: {
    enabled: true,
    theme: "dark",
    style: {
      fontSize: "12px",
      fontFamily: undefined,
      backgroundColor: "#000000",
    },
  },
};
export const PaymentMethodsChartData = [25, 18, 15,20];

