import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import React, {useState} from "react";
import CustomSelect from "../../../../components/select/select";
import ProductTable from "../../productManagement/subscriptionProduct/components/ProductTable";
import CardMenu from "../../../../components/card/CardMenu";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import PrintButton from "../../../../components/filterButton/printBtn";

const CustomerSubscriptions = () => {
    const { t } = useTranslation();
    const data = [
        { title: t('INDIVIDUAL_CUSTOMER_MEMBERSHIPS') },
        { title: t('CORPORATE_CUSTOMER_MEMBERSHIPS') },
    ];
    type Option = typeof sampleOptions[0];
    const handleChange = (selectedValues: Option[]) => {
        console.log(selectedValues);
    };
    const sampleOptions = [
        {label: "option 1", value: "option-1"},
        {label: "option 2", value: "option-2"},
        {label: "option 3", value: "option-3"},
    ];
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const handleCardClick = (index: number) => {
        setActiveIndex(index);
    };
    return (
        <div>
            <div>
                <ScreanTitle title={t('CUSTOMER_SUBSCRIPTIONS_SCREEN')}></ScreanTitle>
                <div className={'flex justify-between pb-3'}>
                    <div className={'flex'}>
                        <button className="btn btn-primary mx-3 flex items-center">
                            <span className={'pe-3'}>{t('NEW_MEMBERSHIP_ISSUE')}</span>
                            <em className={'icon-plus'}></em>
                        </button>
                        <button className="btn btn-light mx-3 flex items-center">
                            <span className={'pe-3'}>{t('FREE_MEMBERSHIP_ISSUE')}</span>
                            <em className={'icon-plus'}></em>
                        </button>
                    </div>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                        <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    مؤرشف 
                                </p>
                                <p className="text-black cursor-pointer">
                                    مسترجع
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب المدينة
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب نوع العضوية
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب المصدر
                                </p>
                                <p className="text-black cursor-pointer">
                                    ملغية
                                </p>
                                <p className="text-black cursor-pointer">
                                    افراد
                                </p>
                                <p className="text-black cursor-pointer">
                                    شركات
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>

            <div className={'white-card pb-3 pt-1'}>
                    <div className="flex mt-3 checkListCard  gap-5 lg:gap-20 px-0 pb-3 justify-center">
                        {data.map((item, index) => (
                            <div
                                key={index}
                                className={`simple-card md:min-w-[220px] py-2 px-7 ${index === activeIndex && 'active'}`}
                                onClick={() => handleCardClick(index)}
                            >
                                <p className= {` text-center  fs-19 ${index === activeIndex ? 'font-bold text-primary-500 border-b-primary-500' : 'font-medium text-gray-600'}`}>{item.title}</p>
                            </div>
                        ))}
                    </div>
                    <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 px-4 pb-6 dashed-border py-5">

                        <div>
                            <CustomSelect  onChange={handleChange}
                                placeholder={t('COUNTRY')}
                                options={sampleOptions}
                            />
                        </div>
                        <div>
                            <CustomSelect  onChange={handleChange}
                                placeholder={t('ACCORDING_TO_TYPE')}
                                options={sampleOptions}
                            />
                        </div>
                        <div className={'flex justify-end items-end'}>
                            <button className="btn btn-secondary mx-3 flex items-center h-[45px] justify-center">
                                <span className={'pe-3'}>{t('FILTERING')}</span>
                            </button>
                        </div>
                    </div>
                    <div className="flex justify-center gap-8 pt-5">
                        <div className={'sm-card md:min-w-[200px]'}>
                            <p className={'font-bold fs-17 text-center'}>{t('NUMBER_OF_MEMBERSHIPS')}</p>
                            <p className={'font-bold fs-32 text-center value'}>136</p>
                        </div>
                        <div className={'sm-card md:min-w-[200px]'}>
                            <p className={'font-bold fs-17 text-center'}>{t('ITS_VALUE')}</p>
                            <p className={'font-bold fs-32 text-center value'}>13160</p>
                        </div>
                    </div>

                </div>

            <div className="mt-1 grid h-full grid-cols-1 md:grid-cols-1">
                <ProductTable/>
            </div>
        </div>
    );
};


export default CustomerSubscriptions;
