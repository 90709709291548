import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import InputField from "../../../../components/fields/InputField";
import React from "react";
import CustomSelect from "../../../../components/select/select";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import PrintButton from "../../../../components/filterButton/printBtn";
import Checkbox from "../../../../components/checkbox";
import CustomDatePicker from "../../../../components/fields/DatePicker";
import TextArea from "../../../../components/fields/textArea";

const MarketingCampaigns = () => {
    const { t } = useTranslation();
    type Option = typeof sampleOptions[0];
    const handleChange = (selectedValues: Option[]) => {
        console.log(selectedValues);
    };
    const sampleOptions = [
        {label: "رابط المتجر الالكتروني", value: "option-1"},
        {label: "رابط منتج", value: "option-2"},
        {label: "رابط تحميل التطبيق", value: "option-3"},
    ];
    const TargetOptions = [
        {label: "منطقة", value: "option-1"},
        {label: "مدينة", value: "option-2"},
        {label: "اناث", value: "option-3"},
        {label: "ذكور", value: "option-4"},
        {label: "تاريخ", value: "option-5"},
    ];
    return (
        <div>
            <div>
                <ScreanTitle title={t('MARKETING_CAMPAIGN_SETUP_SCREEN')}></ScreanTitle>
                <div className={'flex justify-between pb-3'}>
                    <div>
                        <button className="btn btn-primary mx-3 flex items-center">
                            <span className={'pe-3'}>{t('CREATE_A_MARKETING_CAMPAIGN')}</span>
                            <em className={'icon-plus'}></em>
                        </button>
                    </div>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                    </div>
                </div>
            </div>
            <div>
                <div className={'white-card pb-3 pt-1'}>
                    <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('MARKETING_CAMPAIGN_SETUP')}</p>
                    <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pb-3">
                        <div>
                            <InputField
                                label={t('NAME_OF_THE_MARKETING_CAMPAIGN')}
                                placeholder={t('NAME_OF_THE_MARKETING_CAMPAIGN')}
                                id="NAME_OF_THE_MARKETING_CAMPAIGN"
                                type="text"
                            />
                        </div>
                        <div className={'md:col-span-3'}>
                            <TextArea
                                label={t('MARKETING_MESSAGE_TEXT')}
                                placeholder={t('MARKETING_MESSAGE_TEXT')}
                                id="MARKETING_MESSAGE_TEXT"
                                type="text"
                            />
                        </div>
                        <div>
                            <CustomSelect  onChange={handleChange}
                                label={t('CAMPAIGN_LINK')}
                                placeholder={t('CHOOSE_LINK')}
                                options={sampleOptions}
                            />
                        </div>
                        <div className="md:col-span-3 flex pt-7 flex-wrap items-center pb-3">
                            <div className={'dashed-border flex-[1] flex justify-center items-center min-h-[47px]'}>
                                <p className={'font-bold fs-19'}>TAKFULALARABIAJKBFCDJSDKFLBVHSAGFSDFSDFVDFGKLGDFNJKKDFGB</p>
                            </div>
                            <button className="btn btn-primary me-3 ms-10 flex items-center">
                                <span className={'pe-3'}>{t('PREVIEW_LINK')}</span>
                                <em className={'icon-share'}></em>
                            </button>
                        </div>
                        <div>
                            <CustomSelect  onChange={handleChange}
                                label={t('TARGET_SEGMENT')}
                                placeholder={t('TARGET_SEGMENT')}
                                options={TargetOptions}
                            />
                        </div>
                        <div>
                            <p>{t('CAMPAIGN_SCHEDULING')}</p>
                            <div className={'flex pt-5'}>
                                <Checkbox label={t('PUBLISH_NOW')} id={'PUBLISH_NOW'} />
                                <Checkbox label={t('CAMPAIGN_SCHEDULING')} id={'CAMPAIGN_SCHEDULING'} />
                            </div>
                        </div>
                        <div>
                            <CustomDatePicker label={t("DATE_START_CAMPAIGN")} placeholder={'DATE_START_CAMPAIGN'}/>
                        </div>
                        <div>
                            <CustomDatePicker label={t("DATE_END_CAMPAIGN")} placeholder={'DATE_END_CAMPAIGN'}/>
                        </div>
                    </div>
                    <div className={'flex justify-center pt-4 flex-wrap'}>
                        <button className="btn btn-primary mx-3 mb-2">
                            <em className={'icon-pen'}></em>
                            <span className={'ps-3'}>{t('AMENDMENT')}</span>
                        </button>
                        <button className="btn btn-secondary mx-3 mb-2">
                            <em className={'icon-check'}></em>
                            <span className={'ps-3'}>{t('SAVE')}</span>
                        </button>
                        <button className="btn btn-outline-secondary mx-3 mb-2">
                            <em className={'icon-close'}></em>
                            <span className={'ps-3'}>{t('CLOSE')}</span>
                        </button>
                        <button className="btn btn-light mx-3 mb-2">
                            <em className={'icon-copy fs-18'}></em>
                            <span className={'ps-3'}>{t('PUBLISH')}</span>
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
};


export default MarketingCampaigns;
