import React from "react";

// private Imports
import MainDashboard from "views/private/dashboard";
// Auth Imports
import SignIn from "views/auth/SignIn";
import SignUp from "views/auth/SignUp";
// Icon Imports
import {
  MdLock,
} from "react-icons/md";
import AddEmployee from "./views/private/userManagement/addEmployee";
import EmployeeDetails from "./views/private/userManagement/employeeDetails";
import EmployeeList from "./views/private/userManagement/employeeList";
import ProductManagement from "./views/private/productManagement/products";
import VerifySubscription from "./views/private/productManagement/verifySubscription";
import SubscriptionProduct from "./views/private/productManagement/subscriptionProduct";
import NewSubscription from "./views/private/productManagement/newSubscription";
import ExpiredSubscription from "./views/private/productManagement/expiredSubscription";
import ReminderExpiredSubscription from "./views/private/productManagement/ReminderExpiredSubscription";
import FreeSubscription from "./views/private/productManagement/freeSubscription";
import SubscriptionManagement from "./views/private/corporate-subscriptions/subscription-management";
import CreatePurchaseVoucher from "./views/private/voucher-products/create-purchase-voucher";
import CurrentCoupons from "./views/private/voucher-products/current-coupons";
import WarrantManagement from "./views/private/userManagement/permission";
import AddPermission from "./views/private/userManagement/permission/AddPermission";
import EmployeeManagement from "./views/private/userManagement/employeeManagement/employeeManagement";
import CompanyEmployee from "./views/private/corporate-subscriptions/company-employee";
import AddictedPreparingCompanies from "./views/private/corporate-subscriptions/addicted-preparing-companies";
import ViewCoupons from "./views/private/voucher-products/current-coupons/components/ViewCoupon";
import CreateProductCard from "./views/private/cards-products/create-product-card";
import CurrentCards from "./views/private/cards-products/current-cards";
import AddCoupons from "./views/private/coupons-products/add-coupons";
import CurrentProductCoupons from "./views/private/coupons-products/current-coupons";
import CustomerOrders from "./views/private/customer-management/customer-requests";
import CustomerSubscriptions from "./views/private/customer-management/customer-subscriptions";
import SubscriptionVerification from "./views/private/customer-management/Subscription-Verification";
import AbandonedCarts from "./views/private/customer-management/Abandoned-Carts";
import CustomersRegistered from "./views/private/customer-management/Customers-Registered";
import CustomerPoints from "./views/private/customer-management/Customer-Points";
import CustomerRatings from "./views/private/customer-management/Customer-Ratings";
import ReminderForAbandonedBaskets from "./views/private/customer-management/Abandoned-Carts/components/reminderForAbandonedBaskets";
import GroupManagement from "./views/private/manage-sections/group-management";
import CategoriesManagement from "./views/private/manage-sections/categories-management";
import AdvertisingBannerManagement from "./views/private/manage-sections/AdvertisingBannerManagement";
import ManagePopupAds from "./views/private/manage-sections/manage-popup-ads";
import SubscriptionReports from "./views/private/reports/subscription-reports";
import PurchasingCouponReports from "./views/private/reports/purchasing-coupon-reports";
import CouponReports from "./views/private/reports/coupon-reports";
import CardReports from "./views/private/reports/card-reports";
import TradersReports from "./views/private/reports/traders-reports";
import AffiliateMarketing from "./views/private/marketing-management/affiliate-marketing";
import MarketingCampaigns from "./views/private/marketing-management/marketing-campaigns";
import SpecialOffers from "./views/private/marketing-management/special-offers";
import DiscountCoupons from "./views/private/marketing-management/discount-coupons";
import ClientPortfolios from "./views/private/portfolio-management/client-portfolios";
import ServiceProviderPortfolios from "./views/private/portfolio-management/service-provider-portfolios";
import Invoices from "./views/private/accounting/invoices";
import TaxAdministration from "./views/private/setting/tax-administration";
import CountriesManagement from "./views/private/setting/countries-management";
import CityManagement from "./views/private/setting/city-management";
import BankManagement from "./views/private/setting/bank-management";
import BillingManagement from "./views/private/setting/billing-management";
import AreaManagement from "./views/private/setting/area-management";
import CurrencyManagement from "./views/private/setting/currency-management";
import CustomerOrganizeCenter from "./views/private/contract/customer-organize-center";
import Complaints from "./views/private/contract/complaints";
import Followup from "./views/private/contract/followup";
import Missions from "./views/private/contract/missions";
import AddContract from "./views/private/contract/add-contract";
import TermsAndUse from "./views/private/website-management/terms-and-use";
import PrivacyPolicy from "./views/private/website-management/privacy-policy";
import CommonQuestions from "./views/private/website-management/common-questions";
import AboutRewards from "./views/private/website-management/about-rewards";
import OurBranches from "./views/private/website-management/our-branches";
import Help from "./views/private/website-management/help";
import OurPrivileges from "./views/private/website-management/our-privileges";
import HowUse from "./views/private/website-management/how-to-use";
import ConnectWithUs from "./views/private/website-management/connect-with-us";
import Tax from "./views/private/accounting/tax";
import AddNewSpecialOffers from "./views/private/marketing-management/special-offers/addNewSpecialOffer";
import AddInvoice from "./views/private/accounting/invoices/addInvoice";
import Offers from "./views/private/establishment-management/offers";
import ViewOffer from "./views/private/establishment-management/offers/view-offer";
import Dealer from "./views/private/establishment-management/dealer";
import ViewDealer from "./views/private/establishment-management/dealer/view-dealer";
import AddDealer from "./views/private/establishment-management/dealer/add-dealer";
import ServiceProviderPortfoliosDetails from "./views/private/portfolio-management/service-provider-portfolios/details";
import EmployeeWallet from "./views/private/userManagement/employeeDetails/employee-wallet";
import Notification from "./views/private/notification";
import SocialManagement from "./views/private/website-management/social-management";

const routes: RoutesType[] = [
  {
    name: "DASHBOARD",
    layout: "/private",
    breadCrumb: "dashboard",
    path: "dashboard",
    icon: <span className="icon icon-home fs-24"><span className="path1"></span><span className="path2"></span></span>,
    component: <MainDashboard/>,
    isParent: false,
  },
  {
    name: "USER_MANAGEMENT",
    layout: "/private",
    breadCrumb: "user-management",
    path: "user-management",
    icon: <span className="icon icon-layers fs-22"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>,
    isParent: true,
    children: [
      {
        name: "EMPLOYEE_MANAGEMENT",
        layout: "/private",
        breadCrumb: "employee-management",
        path: "user-management/employee-management",
        component: <EmployeeManagement/>,
        children: [
          {
            name: "ADD_EMPLOYEE",
            layout: "/private",
            breadCrumb: "add-employee",
            path: "user-management/employee-management/add-employee",
            component: <AddEmployee/>,
          },
          {
            name: "EMPLOYEE_DETAILS",
            layout: "/private",
            breadCrumb: "employee-details",
            path: "user-management/employee-management/employee-details",
            component: <EmployeeDetails/>,
            children: [
                {
                  name: "EMPLOYEE_WALLET",
                  layout: "/private",
                  path: "user-management/employee-management/employee-details/employee-wallet",
                  component: <EmployeeWallet/>,
                },
            ],
          },
        ],
      },
      {
        name: "PERMISSION_MANAGEMENT",
        layout: "/private",
        path: "user-management/permission",
        component: <WarrantManagement/>,
        children: [
          {
            name: "ADD_PERMISSION",
            layout: "/private",
            path: "user-management/permission/add-permission",
            component: <AddPermission/>,
          },
        ],
      },
      {
        name: "EMPLOYEE_COMMISSIONS",
        layout: "/private",
        path: "user-management/employee-list",
        component: <EmployeeList/>,
      },
  ],
    },
  {
    name: "PRODUCT_MANAGEMENT",
    layout: "/private",
    breadCrumb: "product-management",
    path: "product-management",
    icon: <em className="icon icon-list  fs-23">
              <span className="path1"></span><span className="path2"></span>
              <span className="path3"></span><span className="path4"></span>
            </em>,
    isParent: true,
    children: [
      {
        name: "SUBSCRIPTION_PRODUCTS",
        layout: "/private",
        breadCrumb: "subscriptionProduct",
        path: "products-managements/subscriptionProduct",
        component: <SubscriptionProduct/>,
      },
      {
        name: "VERIFY_SUBSCRIPTIONS",
        layout: "/private",
        breadCrumb: "verifySubscription",
        path: "products-managements/verifySubscription",
        component: <VerifySubscription/>,
      },
      {
        name: "ADD_A_SUBSCRIPTION",
        layout: "/private",
        breadCrumb: "productManagement",
        path: "products-managements/productManagement",
        component: <ProductManagement/>,
      },
      {
        name: "NEW_SUBSCRIPTIONS",
        layout: "/private",
        breadCrumb: "newSubscription",
        path: "products-managements/newSubscription",
        component: <NewSubscription/>,
      },
      {
        name: "EXPIRED_SUBSCRIPTIONS",
        layout: "/private",
        breadCrumb: "expiredSubscription",
        path: "products-managements/expiredSubscription",
        component: <ExpiredSubscription/>,
      },
      {
        name: "REMINDERS_FOR_EXPIRED_SUBSCRIPTIONS",
        layout: "/private",
        breadCrumb: "reminderExpiredSubscription",
        path: "products-managements/reminderExpiredSubscription",
        component: <ReminderExpiredSubscription/>,
      },
      {
        name: "FOR_FREE_SUBSCRIPTIONS",
        layout: "/private",
        breadCrumb: "freeSubscription",
        path: "products-managements/freeSubscription",
        component: <FreeSubscription/>,
      },
    ],
  },
  // {
  //   name: "PREPAID_CARDS_MANAGEMENT",
  //   layout: "/private",
  //   path: "PREPAID_CARDS_MANAGEMENT",
  //   icon: <em className={'icon-list fs-27 ps-1'}/>,
  //   isParent: true,
  //   children: [
  //     {
  //       name: "SUBSCRIPTION_PRODUCTS",
  //       layout: "/private",
  //       path:''
  //     },
  //     {
  //       name: "VERIFY_SUBSCRIPTIONS",
  //       layout: "/private",
  //       path:''
  //     },
  //     {
  //       name: "ADD_A_SUBSCRIPTION",
  //       layout: "/private",
  //       path:''
  //     },
  //     {
  //       name: "NEW_SUBSCRIPTIONS",
  //       layout: "/private",
  //       path:''
  //     },
  //     {
  //       name: "EXPIRED_SUBSCRIPTIONS",
  //       layout: "/private",
  //       path:''
  //     },
  //     {
  //       name: "REMINDERS_FOR_EXPIRED_SUBSCRIPTIONS",
  //       layout: "/private",
  //       path:''
  //     },
  //     {
  //       name: "FOR_FREE_SUBSCRIPTIONS",
  //       layout: "/private",
  //       path:''
  //     },
  //   ],
  // },
  {
    name: "CORPORATE_SUBSCRIPTIONS",
    layout: "/private",
    breadCrumb: "corporate-subscriptions",
    path: "corporate-subscriptions",
    icon: <span className="icon icon-coupon-1  fs-22"><span className="path1"></span><span className="path2"></span></span>,
    isParent: true,
    children: [
      {
        name: "CORPORATE_SUBSCRIPTION_MANAGEMENT",
        layout: "/private",
        breadCrumb:'subscription_management',
        path:'corporate-subscriptions/subscription_management',
        component: <SubscriptionManagement/>,
      },
      {
        name: "COMPANY_EMPLOYEES",
        layout: "/private",
        breadCrumb:'company-employees',
        path:'corporate-subscriptions/company-employees',
        component: <CompanyEmployee/>,
      },
      {
        name: "ADDICTED_TO_PREPARING_COMPANIES",
        layout: "/private",
        breadCrumb:'addicted-preparing-companies',
        path:'corporate-subscriptions/addicted-preparing-companies',
        component: <AddictedPreparingCompanies/>,
      },
    ],
  },
  {
    name: "VOUCHER_PRODUCTS",
    layout: "/private",
    path: "voucher-products",
    icon: <span className="icon icon-Coupon-2  fs-18"><span className="path1"></span><span className="path2"></span></span>,
    isParent: true,
    children: [
      {
        name: "CREATE_PURCHASE_VOUCHER",
        layout: "/private",
        path:'voucher-products/create-purchase-voucher',
        component: <CreatePurchaseVoucher/>
      },
      {
        name: "CURRENT_COUPONS",
        layout: "/private",
        path:'voucher-products/current-coupons',
        component: <CurrentCoupons/>,
        children: [
            {
                name: "VIEW_COUPON",
                layout: "/private",
                path:'voucher-products/current-coupons/view-coupon',
                component: <ViewCoupons/>
            },
            ],
      }
    ],
  },
  {
    name: "CARD_PRODUCTS",
    layout: "/private",
    path: "cards-products",
    icon: <span className="icon icon-credit-card  fs-18"><span className="path1"></span><span className="path2"></span></span>,
    isParent: true,
    children: [
      {
        name: "CREATE_CARD",
        layout: "/private",
        path:'cards-products/create-card',
        component: <CreateProductCard/>
      },
      {
        name: "CURRENT_CARDS",
        layout: "/private",
        path:'cards-products/current-card',
        component: <CurrentCards/>
      },
    ],
  },
  {
    name: "COUPON_PRODUCTS",
    layout: "/private",
    path: "coupons-products",
    icon: <span className="icon icon-Coupon fs-24"><span className="path1"></span><span className="path2"></span><span
        className="path3"></span><span className="path4"></span></span>,
    isParent: true,
    children: [
      {
        name: "ADD_COUPONS",
        layout: "/private",
        path:'coupons-products/add-coupons',
        component: <AddCoupons/>
      },
      {
        name: "CURRENT_COUPONS_PRODUCTS",
        layout: "/private",
        path:'coupons-products/current-coupons',
        component: <CurrentProductCoupons/>
      }
    ],
  },
  {
    name: "CUSTOMER_MANAGEMENT",
    layout: "/private",
    path: "customer-management",
    icon: <span className="icon icon-Users fs-18"><span className="path1"></span><span className="path2"></span><span
        className="path3"></span><span className="path4"></span></span>,
    isParent: true,
    children: [
      {
        name: "CUSTOMER_ORDERS",
        layout: "/private",
        path:'customer-management/customer-orders' ,
        component: <CustomerOrders/>
      },
      {
        name: "CUSTOMER_SUBSCRIPTIONS",
        layout: "/private",
        path:'customer-management/customer-subscriptions',
        component: <CustomerSubscriptions/>},

      {
        name: "SUBSCRIPTION_VERIFICATION",
        layout: "/private",
        path:'customer-management/subscription-verification' ,
        component: <SubscriptionVerification/>},
      {
        name: "ABANDONED_CARTS",
        layout: "/private",
        path:'customer-management/abandoned-carts' ,
        component: <AbandonedCarts/>,
        children: [
          {
            name: "REMINDER_FOR_ABANDONED_BASKETS",
            layout: "/private",
            path:'customer-management/abandoned-carts/reminder-for-abandoned-baskets' ,
            component: <ReminderForAbandonedBaskets/>
          },
        ],
      }
      ,
      {
        name: "CUSTOMERS_REGISTERED",
        layout: "/private",
        path:'customer-management/customers-registered' ,
        component: <CustomersRegistered/>},
      {
        name: "CUSTOMER_RATINGS",
        layout: "/private",
        path:'customer-management/customer-ratings',
        component: <CustomerRatings/>},
      {
        name: "CUSTOMER_POINTS",
        layout: "/private",
        path:'customer-management/customer-points',
        component: <CustomerPoints/>
      },
    ],
  },
  {
    name: "MANAGE_SECTIONS",
    layout: "/private",
    path: "manage-sections",
    icon: <span className="icon icon-Document-2  fs-24">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"> </span>
          </span>,
    isParent: true,
    children: [
      {
        name: "CATEGORIES_MANAGEMENT",
        layout: "/private",
        path:'manage-sections/categories-management',
      component: <CategoriesManagement/>
      },
      {
        name: "GROUP_MANAGEMENT",
        layout: "/private",
        path:'manage-sections/group-management',
        component: <GroupManagement/>
       },
      {
        name: "ADVERTISING_AND_BANNER_MANAGEMENT",
        layout: "/private",
        path:'manage-sections/advertising-banner-management',
        component: <AdvertisingBannerManagement/>},
      {
        name: "MANAGE_POPUP_ADS",
        layout: "/private",
        path:'manage-sections/manage-popup-ads',
        component: <ManagePopupAds/>
      },
    ],
  },
  {
    name: "REPORTS",
    layout: "/private",
    path: "reports",
    icon: <span className="icon icon-Document-2  fs-24">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"> </span>
          </span>,
    isParent: true,
    children: [
      {
        name: "SUBSCRIPTION_REPORTS",
        layout: "/private",
        path:'reports/subscription-reports' ,
        component: <SubscriptionReports/>
      },
      {
        name: "PURCHASING_COUPON_REPORTS",
        layout: "/private",
        path:'reports/purchasing-coupon-reports' ,
        component: <PurchasingCouponReports/>
      },
      {
        name: "COUPON_REPORTS",
        layout: "/private",
        path:'reports/coupon-reports' ,
        component: <CouponReports/>
      },
      {
        name: "CARD_REPORTS",
        layout: "/private",
        path:'reports/card-reports',
        component: <CardReports/>
      },
      {
        name: "TRADERS_REPORTS",
        layout: "/private",
        path:'reports/traders-reports',
        component: <TradersReports/>},
    ],
  },
  {
    name: "CONTRACT_MANAGEMENT",
    layout: "/private",
    path: "contract",
    icon: <span className="icon icon-Case  fs-24">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"> </span>
          </span>,
    isParent: true,
    children: [
      {
        name: "ADD_A_CONTRACT",
        layout: "/private",
        path:'contract/add-contract',
        component: <AddContract/>
      },
      {
        name: "CUSTOMER_SUGGESTIONS_TO_ORGANIZE_A_CENTER",
        layout: "/private",
        path:'contract/customer-organize-center',
        component: <CustomerOrganizeCenter/>},
      {
        name: "COMPLAINTS",
        layout: "/private",
        path:'contract/complaints' ,
        component: <Complaints/>
      },
      {
        name: "FOLLOWUP",
        layout: "/private",
        path:'contract/followup',
        component: <Followup/>},
      {
        name: "MISSIONS",
        layout: "/private",
        path:'contract/missions',
        component: <Missions/>},
    ],
  },
  {
    name: "SERVICE_PROVIDERS_MANAGEMENT",
    layout: "/private",
    path: "establishment-management",
    icon: <span className="icon icon-User  fs-24">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"> </span>
          </span>,
    isParent: true,
    children: [
      {
        name: "DEALERS",
        layout: "/private",
        path:'establishment-management/dealer' ,
        component: <Dealer/>,
        children: [
          {
            name: "VIEW_MERCHANTS",
            layout: "/private",
            path:'establishment-management/dealer/view-dealer' ,
            component: <ViewDealer/>
          },
          {
            name: "ADD_DEALER",
            layout: "/private",
            path:'establishment-management/dealer/add-dealer' ,
            component: <AddDealer/>
          },
        ]
      },
      {
        name: "OFFERS",
        layout: "/private",
        path:'establishment-management/offers',
        component: <Offers/>,
        children: [
          {
            name: "VIEW_BRANCH",
            layout: "/private",
            path:'establishment-management/offers/view-offer' ,
            component: <ViewOffer/>
          },
        ]
      },
    ],
  },
  {
    name: "MARKETING_MANAGEMENT",
    layout: "/private",
    breadCrumb: "marketing-management",
    path: "marketing-management",
    icon: <span className="icon icon-Cart  fs-24">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"> </span>
          </span>,
    isParent: true,
    children: [
      {
        name: "AFFILIATE_MARKETING",
        layout: "/private",
        breadCrumb: "affiliate-marketing",
        path:'marketing-management/affiliate-marketing' ,
        component: <AffiliateMarketing/>
      },
      {
        name: "MARKETING_CAMPAIGNS",
        layout: "/private",
        breadCrumb: "marketing-campaigns",
        path:'marketing-management/marketing-campaigns' ,
        component: <MarketingCampaigns/>
      },
      {
        name: "SPECIAL_OFFERS",
        layout: "/private",
        breadCrumb: "special-offers",
        path:'marketing-management/special-offers' ,
        component: <SpecialOffers/>,
        children: [
          {
            name: "ADD_SPECIAL_OFFERS",
            layout: "/private",
            breadCrumb: "add-special-offer",
            path:'marketing-management/special-offers/add-special-offer' ,
            component: <AddNewSpecialOffers/>
          },
            ]
      },
      {
        name: "DISCOUNT_COUPONS",
        layout: "/private",
        path:'marketing-management/discount-coupons' ,
        component: <DiscountCoupons/>
        },
    ],
  },
  {
    name: "PORTFOLIO_MANAGEMENT",
    layout: "/private",
    path: "portfolio-management",
    icon: <span className="icon icon-Color-Palette  fs-24">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"> </span>
          </span>,
    isParent: true,
    children: [
      {
        name: "CLIENT_PORTFOLIOS",
        layout: "/private",
        path:'portfolio-management/client-portfolios',
        component: <ClientPortfolios/>
      },
      {
        name: "SERVICE_PROVIDER_PORTFOLIOS",
        layout: "/private",
        path:'portfolio-management/service-provider-portfolios',
        component: <ServiceProviderPortfolios/>,
        children: [
            {
                name: "WALLET_DETAILS",
                layout: "/private",
               path:'portfolio-management/service-provider-portfolios/details',
                component: <ServiceProviderPortfoliosDetails/>
              },
            ]
      },
    ],
  },
  {
    name: "ACCOUNTING",
    layout: "/private",
    path: "accounting",
    icon: <span className="icon icon-Wallet  fs-24">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"> </span>
          </span>,
    isParent: true,
    children: [
      {
        name: "TAX",
        layout: "/private",
        path:'accounting/tax',
        component: <Tax/>},
      {
        name: "INVOICES",
        layout: "/private",
        path:'accounting/invoices',
        component: <Invoices/>,
        children: [
          {
            name: "ADD_INVOICE",
            layout: "/private",
            path:'accounting/invoices/add-invoice',
            component: <AddInvoice/>},
            ]

      },
    ],
  },
  {
    name: "ELECTRONIC_PAYMENTS_MANAGEMENT",
    layout: "/private",
    path: "ELECTRONIC_PAYMENTS_MANAGEMENT",
    icon: <span className="icon icon-Wallet  fs-24">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"> </span>
          </span>,
    isParent: false,
  },
  {
    name: "SYSTEM_SETTINGS",
    layout: "/private",
    path: "setting",
    icon: <span className="icon icon-gear  fs-24">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"> </span>
          </span>,
    isParent: true,
    children: [
      {
        name: "MANAGEMENT_OF_COUNTRIES",
        layout: "/private",
        path:'setting/countries-management',
        component: <CountriesManagement/>},
      {
        name: "AREA_MANAGEMENT",
        layout: "/private",
        path:'setting/area-management',
        component: <AreaManagement/>},
      {
        name: "CITY_MANAGEMENT",
        layout: "/private",
        path:'setting/city-management',
        component: <CityManagement/>},
      {
        name: "CURRENCY_MANAGEMENT",
        layout: "/private",
        path:'setting/currency-management',
        component: <CurrencyManagement/>},
      {
        name: "BANK_MANAGEMENT",
        layout: "/private",
        path:'setting/bank-management',
        component: <BankManagement/>},
      {
        name: "TAX_ADMINISTRATION",
        layout: "/private",
        path:'setting/tax-administration',
        component: <TaxAdministration/>},
      {
        name: "BILLING_MANAGEMENT",
        layout: "/private",
        path:'setting/billing-management',
        component: <BillingManagement/>}
    ],
  },
  {
    name: "WEBSITE_AND_APP_MANAGEMENT",
    layout: "/private",
    path: "website-management",
    icon: <span className="icon icon-earth  fs-24">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"> </span>
          </span>,
    isParent: true,
    children: [
      {
        name: "SOCIAL_NETWORKS_MANAGEMENT",
        layout: "/private",
        path: "website-management/social-management",
        icon: <em className={'icon-dashboard fs-22 ps-1'}/>,
        component:  <SocialManagement/>
      },
      {
        name: "TERMS_AND_USE",
        layout: "/private",
        path:'website-management/terms-and-use' ,
        component: <TermsAndUse/>},
      {
        name: "PRIVACY_POLICY",
        layout: "/private",
        path:'website-management/privacy-policy',
        component: <PrivacyPolicy/>},
      {
        name: "COMMON_QUESTIONS",
        layout: "/private",
        path:'website-management/common-questions',
        component: <CommonQuestions/>},
      {
        name: "ABOUT_REWARDS",
        layout: "/private",
        path:'website-management/about-rewards',
        component: <AboutRewards/>},
      {
        name: "OUR_BRANCHES",
        layout: "/private",
        path:'website-management/our-branches',
        component: <OurBranches/>},
      {
        name: "HELP",
        layout: "/private",
        path:'website-management/help',
        component: <Help/>},
      {
        name: "OUR_PRIVILEGES",
        layout: "/private",
        path:'website-management/our-privileges',
        component: <OurPrivileges/>},
      {
        name: "HOW_TO_USE",
        layout: "/private",
        path:'website-management/how-use',
        component: <HowUse/>},
      {
        name: "CONNECT_WITH_US",
        layout: "/private",
        path:'website-management/connect-with-us',
        component: <ConnectWithUs/>},
    ],
  },





  {
    name: "ADD_PAGE",
    layout: "/private",
    path: "ADD_PAGE",
    icon: <em className={'icon-dashboard fs-22 ps-1'}/>,
    isParent: false,
  },
  {
    name: "WHATSAPP_INSTANT_CHATS",
    layout: "/private",
    path: "WHATSAPP_INSTANT_CHATS",
    icon: <em className={'icon-dashboard fs-22 ps-1'}/>,
    isParent: false,
  },

  {
    name: "NOTIFICATIONS_MANAGEMENT",
    layout: "/private",
    path: "notification",
    icon: <em className={'icon-dashboard fs-22 ps-1'}/>,
    isParent: false,
    component: <Notification/>
  },
  {
    name: "NEWS_MANAGEMENT",
    layout: "/private",
    path: "NEWS_MANAGEMENT",
    icon: <em className={'icon-dashboard fs-22 ps-1'}/>,
    isParent: false,
  },
  {
    name: "NEWSLETTER_MANAGEMENT",
    layout: "/private",
    path: "NEWSLETTER_MANAGEMENT",
    icon: <em className={'icon-dashboard fs-22 ps-1'}/>,
    isParent: false,
  },
  {
    name: "RECRUITMENT_MANAGEMENT",
    layout: "/private",
    path: "RECRUITMENT_MANAGEMENT",
    icon: <em className={'icon-dashboard fs-22 ps-1'}/>,
    isParent: false,
  },


  /****************/
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,

  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "sign-up",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignUp />,
  },

];
export default routes;
