
import { Routes, Route, Navigate } from "react-router-dom";
import routes from "routes";
import home_bg from "../../assets/img/layout/home-bg.png";
import React from "react";

export default function Auth() {
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  return (
      <div>
        <div className="relative  min-h-screen w-full !bg-white dark:!bg-navy-900 home-page overflow-y-auto"
             style={{ backgroundImage: `url(${home_bg})` , backgroundSize:'cover' , backgroundRepeat:'no-repeat', backgroundAttachment:'fixed'}} >
            <main className={`mx-auto min-h-screen`}>
                <div className="min-h-full w-full  pt-[20px] lg:pt-[50px]  lg:h-screen lg:px-8 xl:h-[100vh] xl:px-0 ">
                  <Routes>
                    {getRoutes(routes)}
                    <Route
                        path="/"
                        element={<Navigate to="/auth/sign-in" replace />}
                    />
                  </Routes>
                </div>
            </main>
        </div>
      </div>
  );
}
