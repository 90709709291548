import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import React from "react";
import PrintButton from "../../../../components/filterButton/printBtn";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import CardMenu from "../../../../components/card/CardMenu";
import AbandonedCardsTable from "./components/abandonedCardsTable";
import {useNavigate} from "react-router-dom";

const AbandonedCarts = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const createReminder  = () => {
        navigate('/private/customer-management/abandoned-carts/reminder-for-abandoned-baskets');
    };

    return (
        <div>
            <div>
                <ScreanTitle title={t('ABANDONED_CARTS_SCREEN')}></ScreanTitle>
                <div className={'flex justify-between pb-3'}>
                    <div className={'flex'}>
                        <button className="btn btn-primary mx-3 flex items-center" onClick={createReminder}>
                            <span className={'pe-3'}>{t('CREATE_REMINDER')}</span>
                            <em className={'icon-plus'}></em>
                        </button>
                    </div>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                        <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    حسب نوع العرض
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب المدينة
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب المدينة
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>

            <div className="mt-1 grid h-full grid-cols-1 md:grid-cols-1">
                <AbandonedCardsTable />
            </div>
        </div>
    );
};


export default AbandonedCarts;
