import React from "react";

interface CardMenuProps {
    action?: () => void; // Properly typing the action prop as a function that takes no arguments and returns void
}

const PrintButton: React.FC<CardMenuProps> = ({ action }) => {
    // Handling the case where action is not provided
    const handleClick = () => {
        if (action) {
            action();
        }
    };

    return (
        <button onClick={handleClick} className="action-btn-lg border border-[1px] border-primary-500 me-3">
            <span className="icon-printer text-primary-500 fs-25">
                <span className="path1"></span><span className="path2"></span>
            </span>
        </button>
    );
}

export default PrintButton;
