import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import React from "react";
import InputField from "../../../../../components/fields/InputField";
import {useTranslation} from "react-i18next";

interface NewOrderModalProps {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
}

const AddBankModal: React.FC<NewOrderModalProps> = ({ isOpen, onOpen, onClose }) => {
    const { t } = useTranslation();
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay className="bg-[#181C30] !opacity-80" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] lg:min-w-[680px] !max-w-[95%]">
                     <span className={'close-modal cursor-pointer w-[35px] h-[35px] bg-red-500 dark:bg-navy-200 flex items-center justify-center'}   onClick={onClose}>
                       <em className={'icon-close fs-20 text-white dark:text-white'}></em>
                     </span>
                    <ModalBody>
                        <div className="modal-body bg-white border-radius-40 px-[15px] md:px-[30px] pt-[35px] pb-[40px] flex flex-col !z-[1004]">
                            <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('ADD_BANK')}</p>
                            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 pb-3">
                                <div>
                                    <InputField
                                        label={t('NAME_ARABIC')}
                                        placeholder={t('NAME_ARABIC')}
                                        id="NAME_ARABIC"
                                        type="text"
                                    />
                                </div>
                                <div>
                                    <InputField
                                        label={t('NAME_ENGLISH')}
                                        placeholder={t('NAME_ENGLISH')}
                                        id="NAME_ENGLISH"
                                        type="text"
                                    />
                                </div>

                                <div>
                                    <InputField
                                        label={t('BANK_ACCOUNT')}
                                        placeholder={t('BANK_ACCOUNT')}
                                        id="BANK_ACCOUNT"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div className={'flex justify-center py-4'}>
                                <button className="btn btn-primary mx-3">
                                    <em className={'icon-check'}></em>
                                    <span className={'ps-3'}>{t('SAVE')}</span>
                                </button>
                                <button className="btn btn-light mx-3">
                                    <em className={'icon-check'}></em>
                                    <span className={'ps-3'}>{t('SAVE_ADD')}</span>
                                </button>
                                <button className="btn btn-outline-secondary mx-3">
                                    <em className={'icon-close'}></em>
                                    <span className={'ps-3'}>{t('CANCEL')}</span>
                                </button>
                            </div>
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
    ); };
export default AddBankModal;