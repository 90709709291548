import Filter from "../../../../components/filter/Filter";
import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import InputField from "../../../../components/fields/InputField";
import React from "react";
import CustomSelect from "../../../../components/select/select";
import ProductTable from "../../productManagement/subscriptionProduct/components/ProductTable";
import CardMenu from "../../../../components/card/CardMenu";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import PrintButton from "../../../../components/filterButton/printBtn";

const AddictedPreparingCompanies = () => {
    const { t } = useTranslation();
    type Option = typeof sampleOptions[0];
    const handleChange = (selectedValues: Option[]) => {
        console.log(selectedValues);
    };
    const sampleOptions = [
        {label: "فضي", value: "option-1"},
        {label: "برونزي", value: "option-2"},
        {label: "ذهبي", value: "option-3"},
    ];

    return (
        <div>
            <div>
                <ScreanTitle title={t('SCREEN_FOR_ENTERING_AN_EMPLOYEE_INTO_COMPANY')} ></ScreanTitle>
                <div className={'flex justify-between pb-3'}>
                    <div>
                        <button className="btn btn-primary mx-3 flex items-center">
                            <span className={'pe-3'}>{t('ADD_A_NEW_PRODUCT')}</span>
                            <em className={'icon-plus'}></em>
                        </button>
                    </div>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                        <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50">
                                <p className="text-black cursor-pointer">
                                    فلتر 1
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 2
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 3
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 4
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <div>
                <Filter filterTitle={t('SCREEN_ADD_COMPANY_EMPLOYEES')}>
                    <div className={'filter pb-3 pt-1'}>
                        <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('ADD_COMPANY_EMPLOYEES')}</p>
                        <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pb-3">
                            <div>
                                <CustomSelect  onChange={handleChange}
                                    label={t('SUBSCRIPTION_CLASS')}
                                    placeholder={t('SUBSCRIPTION_CLASS')}
                                    options={sampleOptions}
                                />
                            </div>
                            <div>
                                <InputField
                                    label={t('EMPLOYEE_NAME')}
                                    placeholder={t('EMPLOYEE_NAME')}
                                    id="EMPLOYEE_NAME"
                                    type="text"
                                />
                            </div>
                            <div>
                                <InputField
                                    label={t('PROOF_NUMBER')}
                                    placeholder={t('PROOF_NUMBER')}
                                    id="PROOF_NUMBER"
                                    type="text"
                                />
                            </div>
                            <div>
                                <InputField
                                    label={t('MOBILE_NUMBER')}
                                    placeholder={t('MOBILE_NUMBER')}
                                    id="MOBILE_NUMBER"
                                    type="text"
                                />
                            </div>
                        </div>
                        <div className={'flex justify-center py-4'}>
                            <button className="btn btn-primary mx-3">
                                <em className={'icon-pen'}></em>
                                <span className={'ps-3'}>{t('AMENDMENT')}</span>
                            </button>
                            <button className="btn btn-secondary mx-3">
                                <em className={'icon-check'}></em>
                                <span className={'ps-3'}>{t('SAVE')}</span>
                            </button>
                            <button className="btn btn-outline-secondary mx-3">
                                <em className={'icon-close'}></em>
                                <span className={'ps-3'}>{t('CLOSE')}</span>
                            </button>
                        </div>
                    </div>
                </Filter>
            </div>
            <div className="mt-1 grid h-full grid-cols-1 md:grid-cols-1">
                <ProductTable/>
            </div>
        </div>
    );
};


export default AddictedPreparingCompanies;
