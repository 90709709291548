import Filter from "../../../../components/filter/Filter";
import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import React, {useState} from "react";
import CardMenu from "../../../../components/card/CardMenu";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import PrintButton from "../../../../components/filterButton/printBtn";
import {useNavigate} from "react-router-dom";
import Switch from "../../../../components/switch";
import CustomTable from "../../../../components/customTable/CustomTable";
import client from "../../../../assets/img/prod/Group1.png";
import QrCode from "../../../../assets/img/prod/qr.png";

const Offers = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const viewOffer = () => {
        navigate('/private/establishment-management/offers/view-offer');
    };
    const data = [
        { title: t('ALL_OFFERS') },
        { title: t('DEALER_OFFER') },
        { title: t('EMPLOYEE_OFFERS') },
    ];
    const dataTable = [
        {content:<div className={'flex items-center h-full'}>
                <span className={'font-regular fs-15 text-gray-600'}> 1</span>
            </div>, colSpan: 1 },
        { label: "DEALER_LOGO", content:<img src={client} alt="avatar4" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>, colSpan: 1 },
        { label: "DEALER_QR", content: <img src={QrCode} alt="avatar4" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>, colSpan: 1 },
        { label: "DEALER_CODE", content: "25487", colSpan: 1 },
        { label: "DEALER_NAME", content: "شركة البيك", colSpan: 1 },
        { label: "BRANCHES_NUMBER", content: "1", colSpan: 1 },
        { label: "NUMBER_OFFERS_WAITING_ACTIVATED", content:'3', colSpan: 2 },
        { label: "START_DATE", content:'1-1-2023', colSpan: 1 },
        { label: "EXPIRY_DATE", content:'1-1-2023', colSpan: 1 },
        { label: "STATE", content: <Switch />, colSpan: 1 },
        { label: "REVIEW", content: <button className={'action-btn border-primary bg-white'} onClick={viewOffer}>
                <span className={'icon-search-normal fs-16 text-primary-500'}> <span className="path1"/> <span className="path2"/></span></button>, colSpan: 1 },


    ];
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const handleCardClick = (index: number) => {
        setActiveIndex(index);
    };


    return (
        <div>
            <div>
                <ScreanTitle title={t('ACTIVATION_SCREEN_MERCHANT_EMPLOYEE_OFFERS')}></ScreanTitle>
                <div className={'flex justify-end pb-3'}>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                        <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    مفعل
                                </p>
                                <p className="text-black cursor-pointer">
                                    منتهي
                                </p>
                                <p className="text-black cursor-pointer">
                                    مغلق
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>

            <div className={'pb-3 pt-1'}>
                <div className="flex mt-3 checkListCard  gap-5 lg:gap-10 px-0 pb-3 justify-center flex-wrap">
                    {data.map((item, index) => (
                        <div
                            key={index}
                            className={`simple-card md:min-w-[150px] py-2 px-7 ${index === activeIndex && 'active'}`}
                            onClick={() => handleCardClick(index)}
                        >
                            <p className= {` text-center fs-19 ${index === activeIndex ? 'font-bold text-primary-500 border-b-primary-500' : 'font-medium text-gray-600'}`}>{item.title}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="white-card h-full w-full mt-3">
                <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('ALL_OFFERS')}</p>
                <div className="steppedTable">
                    <CustomTable data={dataTable} numColumns={'12'} />
                    <CustomTable data={dataTable} numColumns={'12'} />
                    <CustomTable data={dataTable} numColumns={'12'} />
                    <CustomTable data={dataTable} numColumns={'12'} />
                </div>
            </div>
        </div>
    );
};


export default Offers;
