import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import InputField from "../../../../components/fields/InputField";
import React from "react";

const CurrencyManagement = () => {
    const { t } = useTranslation();
    return (
        <div>
            <div>
                <ScreanTitle title={t('CURRENCY_SETTINGS_SCREEN')}></ScreanTitle>
                <div className={'pb-3'}>
                    <button className="btn btn-primary mx-3 flex items-center">
                        <span className={'pe-3'}>{t('ADD_CURRENCY')}</span>
                        <em className={'icon-plus'}></em>
                    </button>
                </div>
            </div>
            <div className={'white-card pb-3 pt-1'}>
                <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pb-3">
                    <div className={'col-span-4'}>
                        <InputField
                            label={t('COUNTRY')}
                            value={'المملكة العربية السعودية'}
                            disabled={true}
                            id="COUNTRY"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('CURRENCY_ARABIC')}
                            placeholder={t('CURRENCY_ARABIC')}
                            id="NAME_IN_ENGLISH"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('CURRENCY_ENGLISH')}
                            placeholder={t('CURRENCY_ENGLISH')}
                            id="CURRENCY_ENGLISH"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('CURRENCY_SYMBOL')}
                            placeholder={t('CURRENCY_SYMBOL')}
                            id="CURRENCY_SYMBOL"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('CURRENCY_VALUE')}
                            placeholder={t('CURRENCY_VALUE')}
                            id="CURRENCY_VALUE"
                            type="text"
                        />
                    </div>
                </div>
                <div className={'flex justify-center py-4'}>
                    <button className="btn btn-primary mx-3">
                        <em className={'icon-pen'}></em>
                        <span className={'ps-3'}>{t('AMENDMENT')}</span>
                    </button>
                    <button className="btn btn-secondary mx-3">
                        <em className={'icon-check'}></em>
                        <span className={'ps-3'}>{t('SAVE')}</span>
                    </button>
                    <button className="btn btn-outline-secondary mx-3">
                        <em className={'icon-close'}></em>
                        <span className={'ps-3'}>{t('CLOSE')}</span>
                    </button>
                </div>
            </div>
        </div>
    );
};


export default CurrencyManagement;
