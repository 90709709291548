import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import React, {useState} from "react";
import CardMenu from "../../../../components/card/CardMenu";
import Checkbox from "../../../../components/checkbox";
import CustomTable from "../../../../components/customTable/CustomTable";

const Complaints = () => {
    const { t } = useTranslation();
    const data = [
        { title: t('ALL_COMPLAINTS') },
        { title: t('CLOSED_COMPLAINTS') },
        { title: t('COMPLAINTS_ARCHIVE') },
    ];
    const dataTable = [
        {content:<div className={'flex items-center h-full'}>
                <span className={'font-regular fs-15 text-gray-600'}> 1</span>
                <div className={'ps-2'}>
                    <Checkbox></Checkbox>
                </div>
            </div>, colSpan: 1 },
        { label: "OPERATION_NUMBER", content: "1145", colSpan: 1 },
        { label: "NAME_OF_THE_COMPLAINANT", content: "بشار بعاج", colSpan: 3 },
        { label: "COUNTRY", content: "المملكة العربية السعودية", colSpan: 2 },
        { label: "CITY", content: "الرياض", colSpan: 1 },
        { label: "DEALER_NAME", content: "مطعم الشاظئ الكبير", colSpan: 2 },
        { label: "TYPE_OF_COMPLAINT", content:'لم يتم الخصم', colSpan: 2 },
        { label: "DATE_OF_COMPLAINT", content:'2001-1-2023', colSpan: 2 },
        { label: "TIME_TO_COMPLAIN", content:'18:24:12', colSpan: 2 },
        { label: "REVIEW", content: <button className={'action-btn border-primary bg-white'} >
                <span className={'icon-search-normal fs-16 text-primary-500'}> <span className="path1"/> <span className="path2"/></span></button>, colSpan: 1 },

        { label: "EDIT_THE_COMPLAINT", content: <div className={'flex justify-end w-full pe-10'}>
                <CardMenu top={'50px'}
                btn={<button className="action-btn border-primary bg-white " >
                    <em className={'icon-arrow-top fs-16 text-primary-500'} />
                </button>}
                menu={<div className="z-50 ">
                    <p className="text-black cursor-pointer">
                        {t('TRANSFER_TO_A_REPRESENTATIVE')}
                    </p>
                    <p className="text-black cursor-pointer">
                        {t('CLOSE')}
                    </p>
                    <p className="text-black cursor-pointer">
                        {t('ARCHIVE')}
                    </p>
                </div>
                }/></div> ,
            colSpan: 2 },

    ];
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const handleCardClick = (index: number) => {
        setActiveIndex(index);
    };
    return (
        <div>
            <div>
                <ScreanTitle title={t('INCOMING_COMPLAINTS_SCREEN')}></ScreanTitle>
                <div className={'flex justify-end pb-3'}>
                    <div className={'flex'}>
                        <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    مؤرشف
                                </p>
                                <p className="text-black cursor-pointer">
                                    مسترجع
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب المدينة
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب نوع العضوية
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب المصدر
                                </p>
                                <p className="text-black cursor-pointer">
                                    ملغية
                                </p>
                                <p className="text-black cursor-pointer">
                                    افراد
                                </p>
                                <p className="text-black cursor-pointer">
                                    شركات
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>

            <div className={'pb-3 pt-1'}>
                    <div className="flex mt-3 checkListCard  gap-5 lg:gap-20 px-0 pb-3 justify-center">
                        {data.map((item, index) => (
                            <div
                                key={index}
                                className={`simple-card md:min-w-[220px] py-2 px-7 ${index === activeIndex && 'active'}`}
                                onClick={() => handleCardClick(index)}
                            >
                                <p className= {` text-center  fs-19 ${index === activeIndex ? 'font-bold text-primary-500 border-b-primary-500' : 'font-medium text-gray-600'}`}>{item.title}</p>
                            </div>
                        ))}
                    </div>
                </div>
            <div className="white-card h-full w-full mt-3">
                <div className="mb-3">
                    <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('RECEIVED_COMPLAINTS')}</p>
                </div>
                <div className="steppedTable">
                    <CustomTable data={dataTable} numColumns={'19'} />
                    <CustomTable data={dataTable} numColumns={'19'} />
                    <CustomTable data={dataTable} numColumns={'19'} />
                    <CustomTable data={dataTable} numColumns={'19'} />
                </div>
            </div>
            <div className={'flex justify-center py-4'}>
                <button className="btn btn-primary mx-3">
                    <em className={'icon-pen'}></em>
                    <span className={'ps-3'}>{t('AMENDMENT')}</span>
                </button>
                <button className="btn btn-secondary mx-3">
                    <em className={'icon-check'}></em>
                    <span className={'ps-3'}>{t('SAVE')}</span>
                </button>
                <button className="btn btn-light mx-3">
                    <em className={'icon-check'}></em>
                    <span className={'ps-3'}>{t('SAVE_ADD')}</span>
                </button>
                <button className="btn btn-outline-secondary mx-3">
                    <em className={'icon-close'}></em>
                    <span className={'ps-3'}>{t('CLOSE')}</span>
                </button>
            </div>
        </div>
    );
};


export default Complaints;
