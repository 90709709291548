import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import Checkbox from "../../../../../components/checkbox";
import avatar4 from "../../../../../assets/img/avatars/avatar4.png";
import avatar3 from "../../../../../assets/img/avatars/avatar3.png";
import avatar2 from "../../../../../assets/img/avatars/avatar2.png";
import CustomTable from "../../../../../components/customTable/CustomTable";
interface ProjectTableProps {
}
const AbandonedCardsTable: FC<ProjectTableProps> = () => {
    const {t} = useTranslation();
    const data = [
        {content:     <div className={'flex items-center h-full'}>
                                                <span className={'font-regular fs-15 text-gray-600'}> 1</span>
                                                <div className={'ps-2'}>
                                                    <Checkbox></Checkbox>
                                                </div>
                                            </div>, colSpan: 1 },
        { label: "MEMBERSHIPS", content: <img src={avatar4} alt="avatar4" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>, colSpan: 1 },
        { label: "MEMBERSHIP_HOLDER_NAME", content: "بشار بعاج", colSpan: 2 },
        { label: "CITY", content: "الرياض", colSpan: 1 },
        { label: "BASKETS_CREATE_DATE", content: "1-03-2024", colSpan: 2 },
        { label: "PRODUCT_TYPE", content:'200 ريال', colSpan: 1 },
        { label: "TOTAL_BASKETS", content: " 200 ريال", colSpan: 2 },
        { label: "STATE", content: "لم يتم تعبئة الطلب", colSpan: 2 },

        { label: "", content:
                <div className={'flex items-center'}>
                    <button className="btn btn-outline-primary flex items-center">
                        <span>{t('FINISH_ORDER')}</span>
                    </button>
                </div> , colSpan: 2 },
    ];
    return (
        <div className="white-card  h-full w-full mt-5">
            <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('ABANDONED_CARTS')}</p>
            <div className={'steppedTable pt-3'}>
                <CustomTable data={data} numColumns="14" />
                <CustomTable data={data} numColumns="14" />
                <CustomTable data={data} numColumns="14" />
                <CustomTable data={data} numColumns="14" />

            </div>
        </div>
    );
};

export default AbandonedCardsTable;