import React from "react";
import Dropdown from "components/dropdown";
import { useTranslation } from 'react-i18next';
import { BsArrowBarUp } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import avatar from "assets/img/avatars/avatar3.png";
import logo from "../../assets/img/layout/logo-colored.png";
import {useNavigate} from "react-router-dom";

const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
  open: boolean;
}) => {
  const { i18n } = useTranslation();
    const navigate = useNavigate();
    const goToDashboard = () => {
        navigate('/private/dashboard');
    };
  const {t} = useTranslation()
  const handleLanguageChange = (event: { target: { value: any; }; }) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage).then(r =>{} );
  };
  const { onOpenSidenav,  brandText, open } = props;
  const customBoxShadow ='10px 7px 16px 0px rgba(65, 163, 244, 0.06)'
  const redBoxShadow = '6px 7px 24px 0 #DE0606';
  return (
      <nav className="navbar sticky px-[20px] top-0 z-20 flex  items-center
       justify-between ">
        <div className={'flex ps-3 h-full items-center'}>
            <img src={logo} alt="sidebarImg" className={'h-[35px] cursor-pointer'} onClick={goToDashboard}/>
            {/*<button className={`hidden md:flex absolute ${open ? 'right-[250px]' : 'right-[120px]'} top-[113px] sidebarControlBtn border-radius-50 bg-white shadow-xl shadow-shadow-500 hover:bg-navy-200 active:bg-navy-700 */}
            {/*   flex items-center justify-center rounded-full p-2 text-navy-500 transition duration-200 hover:cursor-pointer `} onClick={onOpenSidenav}>*/}
            {/*    <em className={`${ !open ? 'icon-chevron-right' : 'icon-chevron-left' }`}></em>*/}
            {/*</button>*/}
        </div>
        {/*<select onChange={handleLanguageChange} value={i18n.language}>*/}
        {/*  <option value="en">English</option>*/}
        {/*  <option value="fr">French</option>*/}
        {/*  <option value="ar">Arabic</option>*/}
        {/*</select>*/}

        <div className="md:w-auto relative flex items-center justify-end lg:justify-around gap-2 px-2 py-2  xl:gap-2">
          <div className="ps-5 me-10 hidden md:flex  border border-navy-200 h-full items-center box-shadow border-radius-10  bg-white text-navy-700 xl:w-[225px]" style={{boxShadow:customBoxShadow}}>
            <input
                type="text"
                placeholder="بحث"
                className="block h-full w-full  text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 sm:w-fit"
            />
            <div className="pl-3 pr-2 text-xl">
              <FiSearch className="h-4 w-4 text-gray-400" />
            </div>
          </div>
            <div className="flex items-center">
                <div className={'bg-white border-radius-10'}>
                    <img
                        className="h-[45px] w-[45px] border-radius-10"
                        src={avatar}
                        alt="avatar"
                    />
                </div>
                <div className={'bg-red h-full ps-10'}>
                     <span className="cursor-pointer text-xl text-gray-600 "
                           onClick={onOpenSidenav}>
                      <em className="icon-burger fs-20" />
                   </span>
                </div>
            </div>

          <div className="hidden md:flex">
                <Dropdown

                    button={
                        <span className={'hidden md:flex h-[45px] w-[45px] cursor-pointer bg-white  box-shadow border-radius-10 flex items-center p-2 justify-center relative'}>
                  <div className={`absolute flex items-center justify-center top-[-13px] right-0 border-radius-8 
                  bg-red-500 w-[22px] h-[22px] text-xs font-bold uppercase text-white transition duration-200`}
                       style={{
                           boxShadow: redBoxShadow,
                       }}>
                    8
                  </div>
                   <em className={'icon-notification fs-25'}></em>
                </span>
                    }
                    animation="origin-[65%_0%] md:origin-top-left transition-all duration-300 ease-in-out"
                    children={
                        <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 sm:w-[460px]">
                            <div className="flex items-center justify-between">
                                <p className="text-base font-bold text-navy-700">
                                    Notification
                                </p>
                                <p className="text-sm font-bold text-navy-700">
                                    Mark all read
                                </p>
                            </div>

                            <button className="flex w-full items-center">
                                <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brandLinear to-navy-500 py-4 text-2xl text-white">
                                    <BsArrowBarUp />
                                </div>
                                <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                                    <p className="mb-1 text-left text-base font-bold text-gray-900">
                                        New Update
                                    </p>
                                    <p className="font-base text-left text-xs text-gray-900">
                                        A new updat
                                    </p>
                                </div>
                            </button>

                        </div>
                    }
                    classNames={"py-2 top-8 md:top-4 -left-[45px] md:-left-[440px] w-max"}
                />
            </div>
          <div className="hidden md:flex">
                <Dropdown
                    button={
                        <span className={'h-[45px] w-[45px] cursor-pointer bg-white  box-shadow border-radius-10 flex items-center p-2 justify-center relative'}>
                  <div className={`absolute flex items-center justify-center top-[-13px] right-0 border-radius-8 
                  bg-red-500 w-[22px] h-[22px] text-xs font-bold uppercase text-white transition duration-200`}
                       style={{
                           boxShadow: redBoxShadow,
                       }}>
                    10
                  </div>
                   <em className={'icon-mail fs-22'}>
                       <span className={'path1'}></span>
                       <span className={'path2'}></span>
                   </em>
                </span>
                    }
                    animation="origin-[65%_0%] md:origin-top-left transition-all duration-300 ease-in-out"
                    children={
                        <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 sm:w-[460px]">
                            <div className="flex items-center justify-between">
                                <p className="text-base font-bold text-navy-700">
                                    Mail
                                </p>
                                <p className="text-sm font-bold text-navy-700">
                                    Mark all read
                                </p>
                            </div>
                        </div>
                    }
                    classNames={"py-2 top-8 md:top-4 -left-[45px] md:-left-[440px] w-max"}
                />
            </div>
        </div>
      </nav>
  );
};

export default Navbar;
