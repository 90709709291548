import {useTranslation} from "react-i18next";
import React from "react";
import ScreanTitle from "../../../components/screanTitle/screanTitle";
import InputField from "../../../components/fields/InputField";
import CustomSelect from "../../../components/select/select";
import CustomDatePicker from "../../../components/fields/DatePicker";
import Upload from "../../../components/customUpload/Upload";
import PrintButton from "../../../components/filterButton/printBtn";
import UploadButton from "../../../components/filterButton/uploadBtn";
import CardMenu from "../../../components/card/CardMenu";
import Checkbox from "../../../components/checkbox";
import TextArea from "../../../components/fields/textArea";
import notif from  "../../../assets/img/prod/immmm.png";
import CustomTable from "../../../components/customTable/CustomTable";


const Notification = () => {
    const { t } = useTranslation();
    type Option = typeof sampleOptions[0];
    const handleChange = (selectedValues: Option[]) => {
        console.log(selectedValues);
    };
    const sampleOptions = [
        {label: "option 1", value: "option-1"},
        {label: "option 2", value: "option-2"},
        {label: "option 3", value: "option-3"},
    ];

    const dataTable = [
        { label: "POINT_NAME", content: "انظمام مقهى تيم هورتنز", colSpan: 3 },
        { label: "COUNTRY", content: "المملكة العربية السعودية", colSpan: 3 },
        { label: "CITY", content: "الرياض", colSpan: 1 },
        { label: "DATE", content:'1-1-2023', colSpan: 1 },
        { label: "AMENDMENT", content: <button className={'action-btn border-primary bg-white'}>
                <span className={'icon-pen fs-16 text-primary-500'}> </span></button>, colSpan: 1 },
        { label: "REVIEW", content: <button className={'action-btn border-primary bg-white'}>
                <span className={'icon-search-normal fs-16 text-primary-500'}> <span className="path1"/> <span className="path2"/></span></button>, colSpan: 1 },


    ];

    return (
        <div>
            <div>
                <ScreanTitle title={t('APPLICATION_NOTIFICATION')}></ScreanTitle>
                <div className={'flex justify-between pb-3'}>
                    <div>
                        <button className="btn btn-primary mx-3 flex items-center">
                            <span className={'pe-3'}>{t('ADD_NOTICE')}</span>
                            <em className={'icon-plus'}></em>
                        </button>
                    </div>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                        <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div
                                className="z-50 w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                <p className="hover:text-black flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium">
                                    Panel 1
                                </p>
                                <p className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium">
                                    Panel 2
                                </p>
                                <p className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium">
                                    Panel 3
                                </p>
                                <p className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium">
                                    Panel 4
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="white-card h-full w-full mt-1">
                <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('APPLICATION_NOTIFICATION')}</p>
                <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pb-3">
                    <div>
                        <CustomSelect onChange={handleChange}
                                      label={t('COUNTRY')}
                                      placeholder={t('COUNTRY')}
                                      options={sampleOptions}
                        />
                    </div>
                    <div>
                        <CustomSelect onChange={handleChange}
                                      label={t('CITY')}
                                      placeholder={t('CITY')}
                                      options={sampleOptions}
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('POINT_ADDRESS')}
                            placeholder={t('POINT_ADDRESS')}
                            id="POINT_ADDRESS"
                            type="text"
                        />
                    </div>
                    <div>
                        <p className={'font-medium fs-15 ps-3 pb-5'}>{t('CAMPAIGN_SCHEDULE')}</p>
                        <div className={'flex justify-between'}>
                            <Checkbox id={'PUBLISH_NOW'} label={t('PUBLISH_NOW')}></Checkbox>
                            <Checkbox id={'SCHEDULE_NOTICE'} label={t('SCHEDULE_NOTICE')}></Checkbox>
                        </div>
                    </div>
                    <div className={'2xl:col-span-2'}>
                        <Upload label={t('LOGO_ATTACHMENT')} id={'DOWNLOAD_CENTER_LOGO'}/>
                    </div>
                    <div className={'2xl:col-span-2'}>
                        <Upload label={t('ATTACH_PICTURE')} id={'DOWNLOAD_CENTER_LOGO'}/>
                    </div>
                    <div className={'2xl:col-span-2'}>
                        <CustomDatePicker label={t('OFFER_START_DATE')}
                                          placeholder={t('OFFER_START_DATE')}/>
                    </div>
                    <div className={'2xl:col-span-2'}>
                        <CustomDatePicker label={t('OFFER_EXPIRY_DATE')} placeholder={t('OFFER_EXPIRY_DATE')}/>
                    </div>
                    <div className={'2xl:col-span-2'}>
                        <TextArea label={t('TEXT_NOTICE')} placeholder={t('TEXT_NOTICE')}></TextArea>
                    </div>
                    <div className={'2xl:col-span-2'}>
                        <p className={'font-medium fs-15 ps-3 pb-1'}>{t('CAMPAIGN_SCHEDULE')}</p>
                        <div className={'border-light border-r-20'}>
                            <img src={notif} alt="notif"/>
                        </div>
                    </div>

                </div>
                <div className={'flex justify-center py-5 flex-wrap'}>
                    <button className="btn btn-secondary mx-3 mb-2">
                        <em className={'icon-check'}></em>
                        <span className={'ps-3'}>{t('SAVE')}</span>
                    </button>
                    <button className="btn btn-light mx-3 mb-2">
                        <em className={'icon-check'}></em>
                        <span className={'ps-3'}>{t('SAVE_ADD')}</span>
                    </button>
                    <button className="btn btn-outline-secondary mx-3 mb-2">
                        <em className={'icon-close'}></em>
                        <span className={'ps-3'}>{t('CLOSE')}</span>
                    </button>
                </div>
            </div>
            <div className="white-card h-full w-full mt-3">
                <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('ALL_OFFERS')}</p>
                <div className="steppedTable">
                    <CustomTable data={dataTable} numColumns={'10'}/>
                    <CustomTable data={dataTable} numColumns={'10'}/>
                    <CustomTable data={dataTable} numColumns={'10'}/>
                </div>
            </div>
        </div>
    );
};


export default Notification;
