import React, {useState} from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import isMobile from "../../../utils/mobileUtils";
import {useTranslation} from "react-i18next";


export const SidebarLinks = (props: { routes: any; onClose: any; }): JSX.Element => {
  const isMobileDevice = isMobile();
    const { t } = useTranslation();
  let location = useLocation();
  const { routes, onClose } = props;
  const customBorderRadius = '3px';
  const customGradient = 'linear-gradient(90deg, rgb(222 182 96 / 0%) 0%, rgb(222 182 96 / 20%) 100%)';

    const [activeParent, setActiveParent] = useState<string | null>(null);

    const isActive = (route: RoutesType): boolean => {
        // Check if the current route is active
        if (location.pathname.includes(route.path)) {
            return true;
        }

        // Check if any child link is active
        if (route.children) {
            return route.children.some((child: { path: string; }) => location.pathname.includes(child.path));
        }

        return false;
    };
    const closeSideBarMobile = () => {
        if (isMobileDevice && onClose){
                onClose();
        }

    };
    const handleParentClick = (path: string): void => {
        setActiveParent((prevPath) => (prevPath === path ? null : path));
    };

    const renderChildren = (parentPath: string, children: RoutesType[] | undefined): JSX.Element | null => {
        if (activeParent === parentPath) {
            return (
                <ul className="ml-4">
                    {children?.map((child, index) => {
                        console.log(child.path); // Log child path
                        return (
                            <li key={index} className="mb-2 ps-5">
                                <Link onClick={closeSideBarMobile}
                                      className={`link ps-11 flex hover:text-primary-500 ${onClose ? "" : "ps-12"}  ${isActive(child) ? "active" : ""}`}
                                      to={child.path}
                                >
                                    {t(child.name)}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            );
        }
        return null;
    };


    const createLinks = (routes: RoutesType[]) => {

        return routes.map((route, index) => {
            if (route.layout === "/private" && route.show !== false) {
                const commonContent = (
                    <div
                        className={`sidebarLink relative py-2 flex hover:cursor-pointer  ${
                            isActive(route) ? "text-amber-500" : ""
                        }`}
                        style={{
                            // boxShadow: isActive(route) ? customBoxShadow : 'none',
                            borderRadius: isActive(route) ? customBorderRadius : '0',
                            background: isActive(route) ? customGradient : 'transparent',
                        }}
                    >
                        <li
                            className={'my-[3px] flex cursor-pointer items-center px-5 sidebar-link'}
                            key={index}>
                                <span
                                    className={`flex items-center ${
                                        isActive(route) ? "text-amber-500" : "text-black"
                                    }`}
                                >
                                    {route.icon ? route.icon : <DashIcon />}
                                </span>
                                <p
                                    className={`leading-1 ms-4 flex fs-16 ${
                                        isActive(route) ? "font-bold text-amber-500" : "font-regular"
                                    }`}
                                >
                                    {t("SIDEBAR." + route.name)}
                                </p>
                        </li>
                    </div>
                );

                // Conditionally render a Link or a plain div based on route.isParent
                if (route.isParent !== true) {
                    return (
                        <Link onClick={closeSideBarMobile}
                            className={`link customLink ${isActive(route) ? "active" : ""}`}
                            key={index}
                            to={route.layout + "/" + route.path}
                        >
                            {commonContent}
                        </Link>
                    );
                } else {
                    return (
                        <div
                            className={`link parent ${isActive(route) ? "active" : ""}`}
                            key={index}

                        >
                            <div  onClick={() => handleParentClick(route.path)}>
                                   {commonContent}
                            </div>
                            {renderChildren(route.path, route.children)}
                        </div>
                    );
                }
            }
            return null;
        });

    };

    return <>{createLinks(routes)}</>;
};

export default SidebarLinks;
