import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import InputField from "../../../../components/fields/InputField";
import React from "react";
import CustomSelect from "../../../../components/select/select";
import CustomDatePicker from "../../../../components/fields/DatePicker";
import CustomTimePicker from "../../../../components/fields/TimePicker";
import TextArea from "../../../../components/fields/textArea";
import Checkbox from "../../../../components/checkbox";
import CustomTable from "../../../../components/customTable/CustomTable";
import avatar4 from "../../../../assets/img/avatars/avatar4.png";

const Missions = () => {
    const { t } = useTranslation();
    type Option = typeof sampleOptions[0];
    const handleChange = (selectedValues: Option[]) => {
        console.log(selectedValues);
    };
    const sampleOptions = [
        {label: "option 1", value: "option-1"},
        {label: "option 2", value: "option-2"},
        {label: "option 3", value: "option-3"},
    ];
    const data = [
        {content:<div className={'flex items-center h-full'}>
                <span className={'font-regular fs-15 text-gray-600'}> 1</span>
                <div className={'ps-2'}>
                    <Checkbox></Checkbox>
                </div>
            </div>, colSpan: 1 },
        { label: "OPERATION_NUMBER", content: "1145", colSpan: 2 },
        { label: "MISSION_OWNER", content: <img src={avatar4} alt="avatar4" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>, colSpan: 2 },
        { label: "MISSION_OWNER_NAME", content: "بشار بعاج", colSpan: 3 },
        { label: "CITY", content: "الرياض", colSpan: 1 },
        { label: "TASK_TYPE", content: "فسيمة", colSpan: 2 },
        { label: "START_DATE", content:'2001-1-2023', colSpan: 2 },
        { label: "EXPIRY_DATE", content:'2001-1-2023', colSpan: 2 },
        { label: "ENTRY_TIME", content:'18:24:12', colSpan: 2 },
        { label: "TIME_OUT", content: "18:50:12", colSpan: 2 },
        { label: "REVIEW", content: <button className={'action-btn border-primary bg-white'} >
                <span className={'icon-search-normal fs-16 text-primary-500'}> <span className="path1"/> <span className="path2"/></span></button>, colSpan: 1 },

    ];
    return (
        <div>
            <div>
                <ScreanTitle title={t('SCREEN_ADDING_TASK_VISIT_PROCESS_DELEGATE')}></ScreanTitle>
                <div className={'pb-3'}>
                    <button className="btn btn-primary mx-3 flex items-center">
                        <span className={'pe-3'}>{t('ADD_TASK')}</span>
                        <em className={'icon-plus'}></em>
                    </button>
                </div>
            </div>
            <div className={'white-card pb-3 pt-1'}>
                        <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pb-3">
                            <div>
                                <InputField
                                    label={t('EMPLOYEE_NAME')}
                                    placeholder={t('EMPLOYEE_NAME')}
                                    id="EMPLOYEE_NAME"
                                    type="text"
                                />
                            </div>
                            <div>
                                <CustomSelect  onChange={handleChange}
                                    label={t('TASK_TYPE')}
                                    placeholder={t('TASK_TYPE')}
                                    options={sampleOptions}
                                />
                            </div>

                            <div>
                               <CustomDatePicker label={t('DATE')} placeholder={t('DATE')}></CustomDatePicker>
                            </div>
                            <div>
                               <CustomTimePicker label={t('TIME')} placeholder={t('DATE')}/>
                            </div>
                            <div className={'col-span-4'}>
                               <TextArea label={t('MISSION_TEXT')} placeholder={t('MISSION_TEXT')}></TextArea>
                            </div>
                        </div>
                        <div className={'flex justify-center py-4'}>
                            <button className="btn btn-primary mx-3">
                                <em className={'icon-pen'}></em>
                                <span className={'ps-3'}>{t('AMENDMENT')}</span>
                            </button>
                            <button className="btn btn-secondary mx-3">
                                <em className={'icon-check'}></em>
                                <span className={'ps-3'}>{t('SAVE')}</span>
                            </button>
                            <button className="btn btn-outline-secondary mx-3">
                                <em className={'icon-close'}></em>
                                <span className={'ps-3'}>{t('CLOSE')}</span>
                            </button>
                        </div>
                    </div>
            <div className="white-card h-full w-full mt-3">
                <div className="mb-3">
                    <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('DELEGATE_PREVIOUS_OPERATIONS')}</p>
                </div>
                <div className="steppedTable">
                    <CustomTable data={data} numColumns="20" />
                </div>
            </div>
        </div>
    );
};
export default Missions;
