import React, { ReactNode } from 'react';

interface CheckCardProps {
  headerContent: ReactNode;
  bodyContent: ReactNode;
  theme: string;
}

const CheckCard: React.FC<CheckCardProps> = ({ headerContent, bodyContent, theme }) => {
  return (
      <div className={`check-card overflow-hidden relative ${theme}`}>
        <div className={`check-card-header py-4 px-5 bg-${theme}`}>{headerContent}</div>
        <div className="check-card-body px-5 pt-5 pb-7 bg-white">
            {bodyContent}
        </div>
      </div>
  );
};

export default CheckCard;
