import InputField from "../../../../components/fields/InputField";
import React from "react";
import Filter from "../../../../components/filter/Filter";
import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import Popover from "../../../../components/popover";
import CheckCard from "../../../../components/card/CheckCard";
import Checkbox from "../../../../components/checkbox";
import Switch from "../../../../components/switch";
import PrintButton from "../../../../components/filterButton/printBtn";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import CardMenu from "../../../../components/card/CardMenu";

const AddPermission = () => {
    const { t } = useTranslation();
    return (
    <div>
        <div>
            <ScreanTitle title={t('ADD_EMPLOYEE_SCREEN_WITH_ADDED_POWERS')}></ScreanTitle>
            <div className={'flex justify-between pb-3'}>
                <div>
                    <button className="btn btn-primary mx-3 flex items-center">
                        <span className={'pe-3'}>{t('ADD_PERMISSION')}</span>
                        <em className={'icon-plus'}></em>
                    </button>
                </div>
                <div className={'flex'}>
                    <PrintButton/>
                    <UploadButton/>
                    <CardMenu
                        classList="icon-filter fs-25"
                        menu={<div className="z-50 w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                            <p className="hover:text-black flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium">
                                Panel 1
                            </p>
                            <p className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium">
                                Panel 2
                            </p>
                            <p className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium">
                                Panel 3
                            </p>
                            <p className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium">
                                Panel 4
                            </p>
                        </div>
                        }
                    />
                </div>

            </div>
        </div>

      <div className="mt-1 grid h-full grid-cols-1 gap-5 md:grid-cols-1 bg-white border-radius-40 px-4 pt-2">
            <div className={'flex justify-between'}>
                <div className="flex pt-3">
                    <p className={'title ps-6  font-bold text-primary-500 fs-19'}>{t('WARRANTS')}</p>
                    <p className={'text-gray-400 font-bold fs-19 ps-4'}>( بشار بعاج )</p>
                </div>

                <div className="flex items-center">
                   <div className={'px-2'}>
                       <Checkbox id={'CHECK_ALL'} color={'yellow'} label={t('CHECK_ALL')} reverse={true}/>
                   </div>
                    <div className={'divider'}/>
                    <div className={'flex items-center px-2'}>
                        <p className={'font-regular fs-15 pe-3'}>جواز التوكيل للكل</p>
                        <Switch id="primary" color="primary"/>
                    </div>
                    <div className={'divider'}/>
                    <div className={'flex items-center px-2'}>
                        <p className={'font-regular fs-15 pe-3'}>جواز الانابة للكل</p>
                        <Switch id="primary" color="primary"/>
                    </div>
                </div>
            </div>
             <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-4 lg:grid-cols-4 2xl:grid-cols-9 pb-3">
                 <div className={'col-span-1 md:col-span-3'}>
                     <CheckCard
                         headerContent={
                             <div className="flex items-center justify-between">
                                 <div className={'px-2'}>
                                     <p className={'font-bold fs-16'}>{t('CREATE_A_NEW_EMPLOYEE')}</p>
                                 </div>
                                 <div className={'px-2'}>
                                     <Checkbox id={'CHECK_ALL_2'} color={'yellow'} label={t('CHECK_ALL')}/>
                                 </div>
                             </div>
                         }
                         bodyContent={
                             <div className="grid h-full gap-5 grid-cols-3">
                                 <div>
                                     <Checkbox id={'ADD-1'} color="yellow" label={t('ADD')}  />
                                 </div>
                                 <div>
                                     <Checkbox id={'EDIT-1'} color="yellow" label={t('EDIT')}  />
                                 </div>
                                 <div>
                                     <Checkbox id={'CANCEL-1'} color="yellow" label={t('CANCEL')}  />
                                 </div>
                             </div>
                         }
                         theme="navy-100 indigo"
                     />
                 </div>
                  <div className={'col-span-1 md:col-span-3'}>
                      <CheckCard
                          headerContent={
                              <div className="flex items-center justify-between">
                                  <div className={'px-2'}>
                                      <p className={'font-bold fs-16'}>{t('ADDING_A_NEW_COUPON_OFFER')}</p>
                                  </div>
                                  <div className={'px-2'}>
                                      <Checkbox id={'CHECK_ALL_3'} color={'yellow'} label={t('CHECK_ALL')}/>
                                  </div>
                              </div>
                          }
                          bodyContent={
                              <div className="grid h-full gap-5 grid-cols-3">
                                  <div>
                                      <Checkbox id={'ADD-2'} color="yellow" label={t('ADD')}  />
                                  </div>
                                  <div>
                                      <Checkbox id={'EDIT-2'} color="yellow" label={t('EDIT')}  />
                                  </div>
                                  <div>
                                      <Checkbox id={'CANCEL-3'} color="yellow" label={t('CANCEL')}  />
                                  </div>
                              </div>
                          }
                          theme="gray-200 indigo"
                      />
                  </div>
                  <div className={'col-span-1 md:col-span-3'}>
                           <CheckCard
                               headerContent={
                                   <div className="flex items-center justify-between">
                                       <div className={'px-2'}>
                                           <p className={'font-bold fs-16'}>{t('CREATE_CARDS')}</p>
                                       </div>
                                       <div className={'px-2'}>
                                           <Checkbox id={'CHECK_ALL_4'} color={'yellow'} label={t('CHECK_ALL')}/>
                                       </div>
                                   </div>
                               }
                               bodyContent={
                                   <div className="grid h-full gap-5 grid-cols-3">
                                       <div>
                                           <Checkbox id={'CREATE_A_NEW_EMPLOYEE'} color="yellow" label={t('ADD')}  />
                                       </div>
                                       <div>
                                           <Checkbox id={'WEBSITE_MANAGEMENT'} color="yellow" label={t('EDIT')}  />
                                       </div>
                                       <div>
                                           <Checkbox id={'MODIFY_EMPLOYEE_SETTINGS'} color="yellow" label={t('CANCEL')}  />
                                       </div>
                                   </div>
                               }
                               theme="navy-200 indigo"
                           />
                  </div>
                 <div className={'col-span-1 md:col-span-3'}>
                         <CheckCard
                             headerContent={
                                 <div className="flex items-center justify-between">
                                     <div className={'px-2'}>
                                         <p className={'font-bold fs-16'}>{t('CURRENT_COUPONS_PRODUCTS')}</p>
                                     </div>
                                     <div className={'px-2'}>
                                         <Checkbox id={'CHECK_ALL_5'} color={'yellow'} label={t('CHECK_ALL')}/>
                                     </div>
                                 </div>
                             }
                             bodyContent={
                                 <div className="grid h-full gap-5 grid-cols-3">
                                     <div>
                                         <Checkbox id={'ADD-4'} color="yellow" label={t('ADD')}  />
                                     </div>
                                     <div>
                                         <Checkbox id={'EDIT-5'} color="yellow" label={t('EDIT')}  />
                                     </div>
                                     <div>
                                         <Checkbox id={'CANCEL-6'} color="yellow" label={t('CANCEL')}  />
                                     </div>
                                 </div>
                             }
                             theme="gray-200 indigo"
                         />
                 </div>
                 <div className={'col-span-1 md:col-span-3'}>
                         <CheckCard
                             headerContent={
                                 <div className="flex items-center justify-between">
                                     <div className={'px-2'}>
                                         <p className={'font-bold fs-16'}>{t('CREATE_MARKETING_CAMPAIGNS')}</p>
                                     </div>
                                     <div className={'px-2'}>
                                         <Checkbox id={'CHECK_ALL_6'} color={'yellow'} label={t('CHECK_ALL')}/>
                                     </div>
                                 </div>
                             }
                             bodyContent={
                                 <div className="grid h-full gap-5 grid-cols-3">
                                     <div>
                                         <Checkbox id={'ADD-7'} color="yellow" label={t('ADD')}  />
                                     </div>
                                     <div>
                                         <Checkbox id={'EDIT-8'} color="yellow" label={t('EDIT')}  />
                                     </div>
                                     <div>
                                         <Checkbox id={'CANCEL-9'} color="yellow" label={t('CANCEL')}  />
                                     </div>
                                 </div>
                             }
                             theme="navy-100 indigo"
                         />
                 </div>
              </div>

          <div>
              <div className={'flex justify-center py-5 flex-wrap'}>
                  <button className="btn btn-primary mx-3">
                      <em className={'icon-pen'}></em>
                      <span className={'ps-3'}>{t('AMENDMENT')}</span>
                  </button>
                  <button className="btn btn-secondary mx-3">
                      <em className={'icon-check'}></em>
                      <span className={'ps-3'}>{t('SAVE')}</span>
                  </button>
                  <button className="btn btn-outline-secondary mx-3">
                      <em className={'icon-close'}></em>
                      <span className={'ps-3'}>{t('CLOSE')}</span>
                  </button>
              </div>
          </div>
      </div>

    </div>
  );
};


export default AddPermission;
