import React from "react";

function InputField(props: {
  id?: string;
  label?: string;
  value?: string;
  extra?: string;
  placeholder?: string;
  variant?: string;
  state?: string;
  disabled?: boolean;
  type?: string;
  fixedValue?: React.ReactNode;
}) {
  const { label, id, extra, type, placeholder, state, disabled,value,  fixedValue } =
    props;

  return (
    <div className={`relative ${extra}`}>
        {label && <label
            htmlFor={id}
            className={'font-medium fs-15 ps-3'}
        >
            {label}
        </label>}

      <input
        disabled={disabled}
        value={value}
        type={type}
        id={id}
        placeholder={placeholder}
        className={`mt-1 flex w-full items-center h-[47px] justify-center border-radius-10 border bg-white px-2 outline-none ${
          disabled === true
            ? "!border-none !bg-light"
            : state === "error"
            ? "border-red-500 text-red-500 placeholder:text-red-500"
            : state === "success"
            ? "border-green-500 text-green-500 placeholder:text-green-500"
            : "border-gray"
        }`}
      />
      {fixedValue &&
          <div className={'fixedValue absolute left-[15px] bottom-[11px]'}>
            {fixedValue}
          </div>}

    </div>
  );
}

export default InputField;
