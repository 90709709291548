
import { Link} from "react-router-dom";

import home_bg from "../../../assets/img/layout/home-bg.png";
import logo from "../../../assets/img/layout/logo.png";
import vision from "../../../assets/img/layout/vision.png";
import React from "react";
import {useTranslation} from "react-i18next";

export default function Public() {
    const { t } = useTranslation();
    return (
        <div>
            <div className="relative  min-h-screen w-full !bg-white dark:!bg-navy-900 home-page"
                 style={{ backgroundImage: `url(${home_bg})` , backgroundSize:'cover' , backgroundRepeat:'no-repeat'}}>
                <main className={'lg:px-[50px] lg:pt-10'}>
                    <div className="min-h-full lg:max-w-[830px] pt-5 lg:text-center md:pt-12  lg:pt-20 px-10 md:px-15 lg:px-20 pb-5">
                        <div className="flex justify-center flex-wrap pt-3 md:pt-8">
                            <img className='mb-3 logo' src={logo} alt="logo" />
                            {/*<img className="vision" src={vision} alt="vision-2030" />*/}
                        </div>
                        <h1 className={'font-semiBold fs-44  pt-5 md:pt-14 text-white text-center'}> {t('HOME.TITLE')}</h1>
                        <h2 className={'font-semiBold fs-27 pt-5 md:pt-6 text-white'}> الخدمات عبر الأنترنت كما يجب أن تكون سهلة ، سريعة وامنة . </h2>
                        <div className={'flex pt-[60px] justify-center pt-5'}>
                            <div className={'px-3'}>
                                <Link to="/auth" className="mt-0 w-max">
                                    <button className="min-w-[160px] min-h-[60px] w-full home-btn flex-col md:flex-row flex items-center justify-center px-3 md:px-7 py-2 text-white
                                     transition duration-200 hover:bg-primary-500  active:bg-primary-500 hover:text-white active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                        <span className={'font-semiBold fs-20'}>شركة</span>
                                    </button>
                                </Link>
                            </div>
                            <div className={'px-3'}>
                               <Link to="/auth" className="mt-0 w-max">
                                   <button className="min-w-[160px] min-h-[60px] w-full home-btn flex-col md:flex-row flex items-center justify-center px-3 md:px-7 py-2 text-white
                                     transition duration-200 hover:bg-primary-500  active:bg-primary-500 hover:text-primary.500 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                       <span className={'font-semiBold fs-20'}>عميل</span>
                                   </button>
                               </Link>
                           </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}
