import { useState } from 'react';
import Card from "components/card";

function Upload(props: {
  id?: string;
  label?: string;

}) {
  const { label, id } =
      props;

  const [isDragging, setIsDragging] = useState(true);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleDragEnter = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e: { preventDefault: () => void; dataTransfer: { files: any; }; }) => {
    e.preventDefault();
    setIsDragging(false);

    const files = e.dataTransfer.files;

    if (files.length > 0) {
      // Update the state with the selected file name
      setSelectedFile(files[0].name);
      // Handle additional file upload logic if needed
    }
  };

  const handleFileInputChange = (e: { target: { files: any; }; }) => {
    const files = e.target.files;

    if (files.length > 0) {
      // Update the state with the selected file name
      setSelectedFile(files[0].name);
      // Handle additional file upload logic if needed
    }
  };
  return (
      <Card
          className={`py-3`}
          onDragEnter={handleDragEnter}
          onDragOver={(e: { preventDefault: () => any; }) => e.preventDefault()}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          id={id}
      >
        <div className="max-w-[850px] col-span-5 h-full w-full dashed-border px-5 py-3 bg-gray-600/10 2xl:col-span-6">
          <label
              htmlFor="fileInput"
              className="flex h-full w-full flex-col items-center justify-center rounded-xl dark:!border-navy-700 lg:pb-0 cursor-pointer"
          >
            <input
                type="file"
                id="fileInput"
                className="hidden"
                onChange={handleFileInputChange}
            />
            <div className={'flex items-center pb-4 justify-center flex-col'}>
              <em className={'icon-upload text-gray-600 fs-30'}></em>

              <div>
                {selectedFile ? (
                    <p className="mt-2 text-sm font-medium text-gray-600">
                      {selectedFile}
                    </p>
                ) : (
                    <p className="mt-2 text-sm font-medium fs-15">
                      {label}
                    </p>
                )}
              </div>
            </div>
          </label>
        </div>
      </Card>
  );
};

export default Upload;
