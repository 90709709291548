import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import InputField from "../../../../components/fields/InputField";
import React from "react";
import CustomSelect from "../../../../components/select/select";
import CardMenu from "../../../../components/card/CardMenu";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import PrintButton from "../../../../components/filterButton/printBtn";
import CheckCard from "../../../../components/card/CheckCard";

const CategoriesManagement = () => {
    const { t } = useTranslation();
    type Option = typeof sampleOptions[0];
    const handleChange = (selectedValues: Option[]) => {
        console.log(selectedValues);
    };
    const sampleOptions = [
        {label: "option 1", value: "option-1"},
        {label: "option 2", value: "option-2"},
        {label: "option 3", value: "option-3"},
    ];
    return (
        <div>
            <div>
                <ScreanTitle title={t('ADD_SERVICE_TYPE_AND_CLASSIFICATION_SCREEN')}></ScreanTitle>
                <div className={'flex justify-between pb-3'}>
                    <div>
                        <button className="btn btn-primary mx-3 flex items-center">
                            <span className={'pe-3'}>{t('ADD_A_SERVICE_TYPE')}</span>
                            <em className={'icon-plus'}></em>
                        </button>
                    </div>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                               <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    فلتر 1
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 2
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 3
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 4
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <div className={'white-card pb-3 pt-1 mb-3'}>
                <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 px-4 pb-3">
                    <div>
                        <InputField
                            label={t('CLASSIFICATION_NAME_IN_ARABIC')}
                            placeholder={t('CLASSIFICATION_NAME_IN_ARABIC')}
                            id="CLASSIFICATION_NAME_IN_ARABIC"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('CLASSIFICATION_NAME_IN_ENGLISH')}
                            placeholder={t('CLASSIFICATION_NAME_IN_ENGLISH')}
                            id="NAME_IN_ENGLISH"
                            type="text"
                        />
                    </div>
                </div>
                <div className={'flex justify-center py-4'}>
                    <button className="btn btn-primary mx-3">
                        <span className={'ps-3'}>{t('ADD_A_CATEGORY')}</span>
                        <em className={'icon-plus'}></em>
                    </button>
                </div>
                <div className={'flex justify-center flex-wrap py-4'}>
                    <button className="btn btn-primary mx-3 mb-2">
                        <em className={'icon-pen'}></em>
                        <span className={'ps-3'}>{t('AMENDMENT')}</span>
                    </button>
                    <button className="btn btn-secondary mx-3 mb-2">
                        <em className={'icon-check'}></em>
                        <span className={'ps-3'}>{t('SAVE')}</span>
                    </button>
                    <button className="btn btn-light mx-3 mb-2">
                        <em className={'icon-check'}></em>
                        <span className={'ps-3'}>{t('SAVE_ADD')}</span>
                    </button>
                    <button className="btn btn-outline-secondary mx-3 mb-2">
                        <em className={'icon-close'}></em>
                        <span className={'ps-3'}>{t('CLOSE')}</span>
                    </button>
                </div>
            </div>
            <div className={'white-card pb-3 pt-1 mb-3'}>
                <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-3 px-4 pb-3">
                    <div>
                        <InputField
                            label={t('DEPARTMENT_NAME_IN_ARABIC')}
                            placeholder={t('DEPARTMENT_NAME_IN_ARABIC')}
                            id="DEPARTMENT_NAME_IN_ARABIC"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('DEPARTMENT_NAME_IN_ENGLISH')}
                            placeholder={t('DEPARTMENT_NAME_IN_ENGLISH')}
                            id="DEPARTMENT_NAME_IN_ENGLISH"
                            type="text"
                        />
                    </div>
                    <div>
                        <CustomSelect  onChange={handleChange}
                                       label={t('SELECT_THE_CLASSIFICATION_SECTION')}
                                       placeholder={t('SELECT_THE_CLASSIFICATION_SECTION')}
                                       options={sampleOptions}
                        />
                    </div>
                </div>
                <div className={'flex justify-center py-4'}>
                    <button className="btn btn-primary mx-3">
                        <span className={'ps-3'}>{t('ADD_SECTION')}</span>
                        <em className={'icon-plus'}></em>
                    </button>
                </div>
                <div className={'flex justify-center flex-wrap py-4'}>
                    <button className="btn btn-primary mx-3 mb-2">
                        <em className={'icon-pen'}></em>
                        <span className={'ps-3'}>{t('AMENDMENT')}</span>
                    </button>
                    <button className="btn btn-secondary mx-3 mb-2">
                        <em className={'icon-check'}></em>
                        <span className={'ps-3'}>{t('SAVE')}</span>
                    </button>
                    <button className="btn btn-light mx-3 mb-2">
                        <em className={'icon-check'}></em>
                        <span className={'ps-3'}>{t('SAVE_ADD')}</span>
                    </button>
                    <button className="btn btn-outline-secondary mx-3 mb-2">
                        <em className={'icon-close'}></em>
                        <span className={'ps-3'}>{t('CLOSE')}</span>
                    </button>
                </div>
            </div>
            <div className={'white-card pb-3 pt-1 mb-3'}>
                <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-3 px-4 pb-3">
                    <div>
                        <InputField
                            label={t('SERVICE_NAME_IN_ARABIC')}
                            placeholder={t('SERVICE_NAME_IN_ARABIC')}
                            id="SERVICE_NAME_IN_ARABIC"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('SERVICE_NAME_IN_ENGLISH')}
                            placeholder={t('SERVICE_NAME_IN_ENGLISH')}
                            id="SERVICE_NAME_IN_ENGLISH"
                            type="text"
                        />
                    </div>
                    <div>
                        <CustomSelect  onChange={handleChange}
                                       label={t('SELECT_THE_CLASSIFICATION_SECTION')}
                                       placeholder={t('SELECT_THE_CLASSIFICATION_SECTION')}
                                       options={sampleOptions}
                        />
                    </div>
                </div>
                <div className={'flex justify-center py-4'}>
                    <button className="btn btn-primary mx-3">
                        <span className={'ps-3'}>{t('ADD_SERVICE')}</span>
                        <em className={'icon-plus'}></em>
                    </button>
                </div>
                <div className={'flex justify-center flex-wrap py-4'}>
                    <button className="btn btn-primary mx-3 mb-2">
                        <em className={'icon-pen'}></em>
                        <span className={'ps-3'}>{t('AMENDMENT')}</span>
                    </button>
                    <button className="btn btn-secondary mx-3 mb-2">
                        <em className={'icon-check'}></em>
                        <span className={'ps-3'}>{t('SAVE')}</span>
                    </button>
                    <button className="btn btn-light mx-3 mb-2">
                        <em className={'icon-check'}></em>
                        <span className={'ps-3'}>{t('SAVE_ADD')}</span>
                    </button>
                    <button className="btn btn-outline-secondary mx-3 mb-2">
                        <em className={'icon-close'}></em>
                        <span className={'ps-3'}>{t('CLOSE')}</span>
                    </button>
                </div>
            </div>
            <div className={'white-card pb-3 pt-1 mb-3'}>
                <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-3 px-4 pb-3">
                    <div>
                        <InputField
                            label={t('FILTER_NAME_IN_ARABIC')}
                            placeholder={t('FILTER_NAME_IN_ARABIC')}
                            id="FILTER_NAME_IN_ARABIC"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('THE_NAME_OF_THE_FILTER_IN_ENGLISH')}
                            placeholder={t('THE_NAME_OF_THE_FILTER_IN_ENGLISH')}
                            id="THE_NAME_OF_THE_FILTER_IN_ENGLISH"
                            type="text"
                        />
                    </div>
                    <div>
                        <CustomSelect  onChange={handleChange}
                                       label={t('SELECT_THE_CLASSIFICATION_SECTION')}
                                       placeholder={t('SELECT_THE_CLASSIFICATION_SECTION')}
                                       options={sampleOptions}
                        />
                    </div>
                </div>
                <div className={'flex justify-center py-4'}>
                    <button className="btn btn-primary mx-3">
                        <span className={'ps-3'}>{t('ADD_FILTERING')}</span>
                        <em className={'icon-plus'}></em>
                    </button>
                </div>
                <div className={'flex justify-center flex-wrap py-4'}>
                    <button className="btn btn-primary mx-3 mb-2">
                        <em className={'icon-pen'}></em>
                        <span className={'ps-3'}>{t('AMENDMENT')}</span>
                    </button>
                    <button className="btn btn-secondary mx-3 mb-2">
                        <em className={'icon-check'}></em>
                        <span className={'ps-3'}>{t('SAVE')}</span>
                    </button>
                    <button className="btn btn-light mx-3 mb-2">
                        <em className={'icon-check'}></em>
                        <span className={'ps-3'}>{t('SAVE_ADD')}</span>
                    </button>
                    <button className="btn btn-outline-secondary mx-3 mb-2">
                        <em className={'icon-close'}></em>
                        <span className={'ps-3'}>{t('CLOSE')}</span>
                    </button>
                </div>
            </div>
            <div className={'white-card pb-3 pt-1'}>
                <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('CURRENT_RATINGS')}</p>
                <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5 px-4 pb-3">
                    <div>
                        <CheckCard
                            headerContent={
                                <p className={'font-medium fs-15'}>{t('SUBSCRIPTION_RATINGS_2')}</p>
                            }
                            bodyContent={
                                <div className="grid h-full grid-cols-1 gap-5 ">
                                    <p className={'font-regular fs-14'}>مطاعم</p>
                                    <p className={'font-regular fs-14'}>تسلية وترفيه</p>
                                    <p className={'font-regular fs-14'}>صحة وجمال</p>
                                    <p className={'font-regular fs-14'}>خدمات يومية</p>
                                    <p className={'font-regular fs-14'}>أزياء وموضة</p>
                                    <p className={'font-regular fs-14'}>الكترونيات</p>
                                    <p className={'font-regular fs-14'}>متاجر الكترونية</p>
                                </div>
                            }
                            theme="navy-100"
                        />
                    </div>
                    <div>
                        <CheckCard
                            headerContent={
                                <p className={'font-medium fs-15'}>{t('COUPON_CLASSIFICATIONS')}</p>
                            }
                            bodyContent={
                                <div className="grid h-full grid-cols-1 gap-5 ">
                                    <p className={'font-regular fs-14'}>مطاعم</p>
                                    <p className={'font-regular fs-14'}>تسلية وترفيه</p>
                                    <p className={'font-regular fs-14'}>صحة وجمال</p>
                                    <p className={'font-regular fs-14'}>خدمات يومية</p>
                                    <p className={'font-regular fs-14'}>أزياء وموضة</p>
                                    <p className={'font-regular fs-14'}>الكترونيات</p>
                                    <p className={'font-regular fs-14'}>متاجر الكترونية</p>
                                </div>
                            }
                            theme="gray-200"
                        />
                    </div>
                    <div>
                        <CheckCard
                            headerContent={
                                <p className={'font-medium fs-15'}>{t('CARD_CLASSIFICATIONS')}</p>
                            }
                            bodyContent={
                                <div className="grid h-full grid-cols-1 gap-5 ">
                                    <p className={'font-regular fs-14'}>عيد ميلاده</p>
                                    <p className={'font-regular fs-14'}>عيد ميلادها</p>
                                    <p className={'font-regular fs-14'}>عيد ميلاد طفل</p>
                                    <p className={'font-regular fs-14'}>ذكرى سنوية</p>
                                    <p className={'font-regular fs-14'}>تخرج</p>
                                    <p className={'font-regular fs-14'}>عيد ام</p>
                                    <p className={'font-regular fs-14'}>هدية حب</p>
                                </div>
                            }
                            theme="navy-200"
                        />
                    </div>
                    <div>
                        <CheckCard
                            headerContent={
                                <p className={'font-medium fs-15'}>{t('THE_FILTERING')}</p>
                            }
                            bodyContent={
                                <div className="grid h-full grid-cols-1 gap-5 ">
                                    <p className={'font-regular fs-14'}>المؤكلات السرعية</p>
                                    <p className={'font-regular fs-14'}>مطاعم فاخرة</p>
                                    <p className={'font-regular fs-14'}>مطاعم  غير رسمية وطلبات خارجية</p>
                                    <p className={'font-regular fs-14'}>مقاهي</p>
                                    <p className={'font-regular fs-14'}>برانش</p>
                                    <p className={'font-regular fs-14'}>مطاعم  غير رسمية وطلبات خارجية</p>
                                    <p className={'font-regular fs-14'}>مقاهي</p>

                                </div>
                            }
                            theme="navy-100"
                        />
                    </div>
                    <div>
                        <CheckCard
                            headerContent={
                                <p className={'font-medium fs-15'}>{t('THE_FILTERING')}</p>
                            }
                            bodyContent={
                                <div className="grid h-full grid-cols-1 gap-5">
                                    <p className={'font-regular fs-14'}>الاقرب</p>
                                    <p className={'font-regular fs-14'}>اشتر 1 واحصل على 1 مجانا</p>
                                    <p className={'font-regular fs-14'}>عروض جديدة</p>
                                    <p className={'font-regular fs-14'}>تناول الطعام </p>
                                    <p className={'font-regular fs-14'}>نسبة خصم</p>
                                    <p className={'font-regular fs-14'}>عروض لايت</p>
                                    <p className={'font-regular fs-14'}>ماكولات بحرية</p>
                                </div>
                            }
                            theme="gray-200"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};


export default CategoriesManagement;
