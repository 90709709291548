import React from "react";
import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import Popover from "../../../../components/popover";
import Switch from "../../../../components/switch";
import Filter from "components/filter/Filter";
import InputField from "../../../../components/fields/InputField";
import CustomTable from "../../../../components/customTable/CustomTable";
import CardMenu from "../../../../components/card/CardMenu";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import PrintButton from "../../../../components/filterButton/printBtn";
import {useNavigate} from "react-router-dom";

const EmployeeDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const EmployeeWallet = () => {
        navigate('/private/user-management/employee-management/employee-details/employee-wallet');
    };
    const data = [
        { label: "WARRANT", content: "اعدادات النظام", colSpan: 1 },
        { label: "CREATION_DATE", content: " 1-10-2023", colSpan: 1 },
        { label: "ON_OFF", content: <Switch id="switch1" color="primary" />, colSpan: 1 },
    ];


    return (
        <div>
            <div>
                <ScreanTitle title={t('EMPLOYEE_DISPLAY_SCREEN')}></ScreanTitle>
                <div className={'flex justify-between pb-3'}>
                    <div className={"flex"}>
                        <button className="btn btn-primary mx-3 flex items-center">
                            <span className={'pe-3'}>{t('EMPLOYEE_DISPLAY')}</span>
                            <em className={'icon-plus'}></em>
                        </button>
                        <button className="btn btn-primary mx-3 flex items-center" onClick={EmployeeWallet}>
                            <span className={'pe-3'}>{t('EMPLOYEE_WALLET')}</span>
                        </button>
                    </div>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                               <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    فلتر 1
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 2
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 3
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 4
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <div>
                <Filter>
                    <div className={'filter pb-3 pt-1'}>
                        <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('EMPLOYEE_PROPERTIES_SCREEN')}</p>
                        <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pb-3">
                            <div>
                                <InputField
                                    label={t('DISPLAY_NAME_IN_ARABIC')}
                                    placeholder={t('DISPLAY_NAME_IN_ARABIC')}
                                    id="NAME_ARABIC"
                                    type="text"
                                />
                            </div>
                            <div>
                                <InputField
                                    label={t('DISPLAY_NAME_IN_ENGLISH')}
                                    placeholder={t('DISPLAY_NAME_IN_ENGLISH')}
                                    id="NAME_IN_ENGLISH"
                                    type="text"
                                />
                            </div>

                            <div>
                                <InputField
                                    label={t('ORIGINAL_AMOUNT')}
                                    placeholder={t('ORIGINAL_AMOUNT')}
                                    id="ORIGINAL_AMOUNT"
                                    type="text"
                                />
                            </div>
                            <div>
                                <InputField
                                    label={t('DISCOUNTED_AMOUNT')}
                                    placeholder={t('DISCOUNTED_AMOUNT')}
                                    id="DISCOUNTED_AMOUNT"
                                    type="text"
                                />
                            </div>
                            <div>
                                <InputField
                                    label={t('THE_NUMBER')}
                                    placeholder={t('THE_NUMBER')}
                                    id="THE_NUMBER"
                                    type="text"
                                />
                            </div>
                            <div>
                                <InputField
                                    label={t('ADDED_TAX_RATE')}
                                    placeholder={t('ADDED_TAX_RATE')}
                                    id="ADDED_TAX_RATE"
                                    type="text"
                                />
                            </div>
                        </div>
                        <div className={'flex justify-center py-4'}>
                            <button className="btn btn-primary mx-3">
                                <em className={'icon-pen'}></em>
                                <span className={'ps-3'}>{t('AMENDMENT')}</span>
                            </button>
                            <button className="btn btn-secondary mx-3">
                                <em className={'icon-check'}></em>
                                <span className={'ps-3'}>{t('SAVE')}</span>
                            </button>
                            <button className="btn btn-outline-secondary mx-3">
                                <em className={'icon-close'}></em>
                                <span className={'ps-3'}>{t('CLOSE')}</span>
                            </button>
                        </div>
                    </div>
                </Filter>
            </div>
            <div className="mt-1 grid h-full grid-cols-1 gap-5 md:grid-cols-2">
                <div className="white-card  h-full w-full mt-5">
                    <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('CURRENT_PERMISSION')}</p>
                    <div className={'steppedTable pt-3'}>
                        <CustomTable data={data} numColumns="3" />
                        <CustomTable data={data} numColumns="3" />
                        <CustomTable data={data} numColumns="3" />
                        <CustomTable data={data} numColumns="3" />
                        <CustomTable data={data} numColumns="3" />
                        <CustomTable data={data} numColumns="3" />
                        <CustomTable data={data} numColumns="3" />
                        <CustomTable data={data} numColumns="3" />
                    </div>
                </div>
                <div className="white-card  h-full w-full mt-5">
                    <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('CURRENT_PERMISSION')}</p>
                    <div className={'steppedTable pt-3'}>
                        <CustomTable data={data} numColumns="3" />
                        <CustomTable data={data} numColumns="3" />
                        <CustomTable data={data} numColumns="3" />
                        <CustomTable data={data} numColumns="3" />
                        <CustomTable data={data} numColumns="3" />
                        <CustomTable data={data} numColumns="3" />
                        <CustomTable data={data} numColumns="3" />
                        <CustomTable data={data} numColumns="3" />
                    </div>
                </div>
            </div>
        </div>
  );
};


export default EmployeeDetails;
