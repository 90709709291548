import {useTranslation} from "react-i18next";
import React from "react";
import avatar2 from "../../../../../assets/img/avatars/avatar2.png";
import coupon from "../../../../../assets/img/coupon.png";
import QR from "../../../../../assets/img/qr.png";
import ScreanTitle from "../../../../../components/screanTitle/screanTitle";
import CardMenu from "../../../../../components/card/CardMenu";
import UploadButton from "../../../../../components/filterButton/uploadBtn";
import PrintButton from "../../../../../components/filterButton/printBtn";
import CustomTable from "../../../../../components/customTable/CustomTable";
import avatar4 from "../../../../../assets/img/avatars/avatar4.png";
import QrCode from "../../../../../assets/img/prod/qr.png";
import Switch from "../../../../../components/switch";

interface CardData {
    title?: string;
}
const ViewCoupons: React.FC<CardData> = () => {
    const { t } = useTranslation();
    const dataTable = [
        { label: "CLIENT", content: <img src={avatar2} alt="avatar4" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>, colSpan: 1 },
        { label: "QR_COUPON", content: <img src={QrCode} alt="QrCode" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>, colSpan: 2 },
        { label: "COUPON_CODE", content: "545465", colSpan: 3 },
        { label: "NAME_COUPON_HOLDER", content: "بشار بعاج", colSpan: 3 },
        { label: "DISPLAY_NAME", content: "وجبة مقابل وجبة", colSpan: 3 },
        { label: "THE_NUMBER", content:'10', colSpan: 1 },
        { label: "OFFER_PRICE", content:'200 ريال', colSpan: 2 },
        { label: "CITY", content:'الرياض', colSpan: 1 },
        { label: "BYE_DATE", content:'1-1-2023', colSpan: 2 },
        { label: "END_DATE", content:'10-1-2023', colSpan: 2 },
        { label: "DEALER", content:'كناكي', colSpan: 1 },
        { label: "STATE", content:'غير مفعلة', colSpan: 2 },
    ];
    return (
        <div>
            <div>
                <ScreanTitle title={t('VIEW_COUPONS')}></ScreanTitle>
                <div className={'flex justify-end pb-3'}>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                               <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    فلتر 1
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 2
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 3
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 4
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>

            <div className={'custom-shadow mt-5 border-radius-20 overflow-hidden dashed-border bg-white'}>
                <CustomTable data={dataTable} numColumns={'23'} customClass={'py-10'} />
            </div>

            <div className={'bg-white custom-shadow mt-5 border-radius-40 flex justify-center'}>
                <img src={coupon} alt="avatar1" width={'70%'} className={'border-radius-10 h-[100%] me-2'}/>
            </div>
        </div>
    );
};


export default ViewCoupons;
