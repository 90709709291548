import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import Switch from "../../../../../components/switch";
interface ProjectTableProps {
    openModal: () => void;
}
const CompanyEmployeesTable: FC<ProjectTableProps> = ({ openModal }) => {
    const {t} = useTranslation();
    return (
        <div className="white-card  h-full w-full mt-5">
            <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('CURRENT_SUBSCRIPTIONS')}</p>
            <div className={'steppedTable pt-3'}>
                <div className="stepped-element grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pt-2 pb-1">
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('DISPLAY_TYPE')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>اشتراك لمدة سنة ب 299</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('CREATION_DATE')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>1-1-2023</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('ON_OFF')}</p>
                        <Switch id="switch1" color={'primary'}/>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('REVIEW')}</p>
                        <button onClick={openModal} className="action-btn border-primary bg-white ">
                            <em className={'icon-search-normal fs-16 text-primary-500'} />
                        </button>
                    </div>
                </div>
                <div className="stepped-element grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pt-2 pb-1">
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('DISPLAY_TYPE')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>اشتراك لمدة سنة ب 299</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('CREATION_DATE')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>1-1-2023</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('ON_OFF')}</p>
                        <Switch id="switch1" color={'primary'}/>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('REVIEW')}</p>
                        <button onClick={openModal} className="action-btn border-primary bg-white ">
                            <em className={'icon-search-normal fs-16 text-primary-500'} />
                        </button>
                    </div>
                </div>
                <div className="stepped-element grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pt-2 pb-1">
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('DISPLAY_TYPE')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>اشتراك لمدة سنة ب 299</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('CREATION_DATE')}</p>
                        <p className={'font-regular fs-15 text-gray-600'}>1-1-2023</p>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('ON_OFF')}</p>
                        <Switch id="switch1" color={'primary'}/>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-1'}>{t('REVIEW')}</p>
                        <button onClick={openModal} className="action-btn border-primary bg-white ">
                            <em className={'icon-search-normal fs-16 text-primary-500'} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyEmployeesTable;