
import avatar2 from "../../../assets/img/avatars/avatar2.png"
import avatar3 from "../../../assets/img/avatars/avatar3.png"
import avatar4 from "../../../assets/img/avatars/avatar4.png"
import avatar5 from "../../../assets/img/avatars/avatar5.png"
import React from "react";
import ProductSalesRate from "./components/productSalesRate";
import DonutChart from "../../../components/charts/Donut";
import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../components/screanTitle/screanTitle";
import CardMenu from "../../../components/card/CardMenu";
import ServiceChart from "./components/ServicesChart";



import {
    CustomerReviewsChartData,
    CustomerReviewsOptions,
    NumberCustomersChartData,
    NumberCustomersOptions, PaymentMethodsChartData, PaymentMethodsOptions
} from "./components/charts-data";
const Dashboard = () => {
    type Option = typeof sampleOptions[0];
    const handleChange = (selectedValues: Option[]) => {
        console.log(selectedValues);
    };
    const sampleOptions = [
        {label: "option 1", value: "option-1"},
        {label: "option 2", value: "option-2"},
        {label: "option 3", value: "option-3"},
    ];
    const { t } = useTranslation();
    return (
    <div>
        <div>

            <ScreanTitle title={t('ADMIN_PANEL_HOME_SCREEN')}></ScreanTitle>
            <div className={'flex justify-end pb-3'}>
                <div className={'flex'}>
                    <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    فلتر 1
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 2
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 3
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 4
                                </p>
                            </div>
                            }
                        />
                </div>
            </div>
        </div>
        <div className={'grid grid-cols-1 gap-5 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-7'}>
            <div className={'col-span-1 lg:col-span-2 2xl:col-span-5   h-full'}>
                <div className={'bg-white  border-radius-40 mb-5 p-[20px]'}>
                    <p className={'font-semiBold fs-17 pb-0'}>معدل مبيعات المنتجات لهذا الشهر</p>
                    <ProductSalesRate></ProductSalesRate>
                </div>
                <div className={'grid grid-cols-1 lg:gap-5 md:grid-cols-1 lg:grid-cols-9 '}>
                    <div className={'col-span-5 bg-white  border-radius-40 mb-5 py-[20px] px-[5px]'}>
                        <p className={'font-semiBold fs-17 pb-5 px-[10px]'}> ميزان مبيعات الأسبوع الحالي</p>
                        <div className={'cardBody'}>
                            <ServiceChart/>
                        </div>
                    </div>
                    <div className={'col-span-4 bg-white  border-radius-40 mb-5 p-[20px]'}>
                        <p className={'font-semiBold fs-17 pb-5'}>اكثر المنتجات مبيعا</p>
                        <div>
                            <div className="">
                                <div className="mb-4 flex align-middle bg-gray-200 border-[1px] border-white custom-shadow hover:bg-white p-2 border-radius-10" >
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">وجبة مقابل وجبة  مطعم زمان ب 23 ريال</p>
                                    </div>
                                    <div className="w-50 flex justify-between">
                                        <p className={'font-bold fs-16'}>عدد 450</p>
                                        <p className={'font-bold fs-16 text-primary-500'}>مطعم أيام زمان 450</p>
                                    </div>
                                </div>
                                <div className="mb-4 flex align-middle bg-gray-200 border-[1px] border-white custom-shadow hover:bg-white p-2 border-radius-10" >
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">وجبة مقابل وجبة  مطعم زمان ب 23 ريال</p>
                                    </div>
                                    <div className="w-50 flex justify-between">
                                        <p className={'font-bold fs-16'}>عدد 450</p>
                                        <p className={'font-bold fs-16 text-primary-500'}>مطعم أيام زمان 450</p>
                                    </div>
                                </div>
                                <div className="mb-4 flex align-middle bg-gray-200 border-[1px] border-white custom-shadow hover:bg-white p-2 border-radius-10" >
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">وجبة مقابل وجبة  مطعم زمان ب 23 ريال</p>
                                    </div>
                                    <div className="w-50 flex justify-between">
                                        <p className={'font-bold fs-16'}>عدد 450</p>
                                        <p className={'font-bold fs-16 text-primary-500'}>مطعم أيام زمان 450</p>
                                    </div>
                                </div>
                                <div className="mb-4 flex align-middle bg-gray-200 border-[1px] border-white custom-shadow hover:bg-white p-2 border-radius-10" >
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">وجبة مقابل وجبة  مطعم زمان ب 23 ريال</p>
                                    </div>
                                    <div className="w-50 flex justify-between">
                                        <p className={'font-bold fs-16'}>عدد 450</p>
                                        <p className={'font-bold fs-16 text-primary-500'}>مطعم أيام زمان 450</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'grid grid-cols-1 gap-5 md:grid-cols-1 lg:grid-cols-3 '}>
                    <div className={'custom-card col-span-1 bg-white  border-radius-40 p-[20px]'}>
                        <div className={'relative z-10'}>
                            <p className={'font-semiBold fs-17 pb-5 text-center'}> عدد العملاء المشترون من الزائرين <span className={'font-bold'}>هذا الشهر </span> </p>
                            <div className={'cardBody'}>
                                <div className="h-[230px]">
                                    <DonutChart chartOptions={NumberCustomersOptions} chartData={NumberCustomersChartData} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'custom-card col-span-1 bg-white  border-radius-40 py-[20px]'}>
                        <div className={'relative z-10'}>
                            <div className={"flex justify-between px-[20px]"}>
                                <p className={'font-semiBold fs-17 pb-5'}>تقيمات العملاء</p>
                                <p className={'font-bold fs-17 pb-5'}>هذا الشهر </p>
                            </div>
                            <div className={'cardBody'}>
                                <div className="h-[250px]">
                                    <DonutChart chartOptions={CustomerReviewsOptions} chartData={CustomerReviewsChartData} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'custom-card col-span-1 bg-white  border-radius-40 p-[20px]'}>
                        <div className={'relative z-10'}>
                            <div className={"flex justify-between"}>
                                <p className={'font-semiBold fs-17 pb-5'}>اكثر طرق الدفع</p>
                                <p className={'font-bold fs-17 pb-5'}>هذا الشهر </p>
                            </div>
                            <div className={'cardBody'}>
                                <div className="h-[250px]">
                                    <DonutChart chartOptions={ PaymentMethodsOptions} chartData={PaymentMethodsChartData} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'col-span-1 lg:col-span-2 2xl:col-span-2 h-full'}>
                <div className={'col-span-4 bg-white  border-radius-40 py-[20px] px-[10px] h-full'}>
                    <div className={'flex justify-between pb-3 px-2'}>
                        <p className={'font-semiBold fs-17 '}>احدث المبيعات</p>
                        <p className={'font-bold fs-17'}>اخر اسبوع</p>
                    </div>
                    <div>
                        <div className="">
                            <div className={'custom-sell-box'}>
                                <div className="custom-sell-content mb-4 flex align-middle bg-gray-200 border-[1px] border-white custom-shadow hover:bg-white p-2 border-radius-10" >

                                    <img src={avatar3} alt="avatar1" width={'42px'} className={'border-radius-10 h-[42px] me-2'}/>
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">روجينا فتوح</p>
                                        <p className="font-regular fs-15">#134578</p>
                                    </div>
                                    <div className="w-50 flex items-end h-auto">
                                        <p className={'font-semiBold fs-12 text-red-600' }>لم يتم التفعيل</p>
                                    </div>
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">120 SAR</p>
                                        <p className="font-semiBold fs-15">منذ 3  ساعات</p>
                                    </div>

                                </div>
                            </div>
                            <div className={'custom-sell-box'}>
                                <div className="custom-sell-content mb-4 flex align-middle bg-gray-200 border-[1px] border-white custom-shadow hover:bg-white p-2 border-radius-10" >

                                    <img src={avatar2} alt="avatar1" width={'42px'} className={'border-radius-10 h-[42px] me-2'}/>
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">روجينا فتوح</p>
                                        <p className="font-regular fs-15">#134578</p>
                                    </div>
                                    <div className="w-50 flex items-end h-auto">
                                        <p className={'font-semiBold fs-12 text-red-600' }>لم يتم التفعيل</p>
                                    </div>
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">120 SAR</p>
                                        <p className="font-semiBold fs-15">منذ 3  ساعات</p>
                                    </div>

                                </div>
                            </div>
                            <div className={'custom-sell-box'}>
                                <div className="custom-sell-content mb-4 flex align-middle bg-gray-200 border-[1px] border-white custom-shadow hover:bg-white p-2 border-radius-10" >

                                    <img src={avatar4} alt="avatar1" width={'42px'} className={'border-radius-10 h-[42px] me-2'}/>
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">روجينا فتوح</p>
                                        <p className="font-regular fs-15">#134578</p>
                                    </div>
                                    <div className="w-50 flex items-end h-auto">
                                        <p className={'font-semiBold fs-12 text-red-600' }>لم يتم التفعيل</p>
                                    </div>
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">120 SAR</p>
                                        <p className="font-semiBold fs-15">منذ 3  ساعات</p>
                                    </div>

                                </div>
                            </div>
                            <div className={'custom-sell-box'}>
                                <div className="custom-sell-content mb-4 flex align-middle bg-gray-200 border-[1px] border-white custom-shadow hover:bg-white p-2 border-radius-10" >

                                    <img src={avatar3} alt="avatar1" width={'42px'} className={'border-radius-10 h-[42px] me-2'}/>
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">روجينا فتوح</p>
                                        <p className="font-regular fs-15">#134578</p>
                                    </div>
                                    <div className="w-50 flex items-end h-auto">
                                        <p className={'font-semiBold fs-12 text-red-600' }>لم يتم التفعيل</p>
                                    </div>
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">120 SAR</p>
                                        <p className="font-semiBold fs-15">منذ 3  ساعات</p>
                                    </div>

                                </div>
                            </div>
                            <div className={'custom-sell-box'}>
                                <div className="custom-sell-content mb-4 flex align-middle bg-gray-200 border-[1px] border-white custom-shadow hover:bg-white p-2 border-radius-10" >

                                    <img src={avatar5} alt="avatar1" width={'42px'} className={'border-radius-10 h-[42px] me-2'}/>
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">روجينا فتوح</p>
                                        <p className="font-regular fs-15">#134578</p>
                                    </div>
                                    <div className="w-50 flex items-end h-auto">
                                        <p className={'font-semiBold fs-12 text-red-600' }>لم يتم التفعيل</p>
                                    </div>
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">120 SAR</p>
                                        <p className="font-semiBold fs-15">منذ 3  ساعات</p>
                                    </div>

                                </div>
                            </div>
                            <div className={'custom-sell-box'}>
                                <div className="custom-sell-content mb-4 flex align-middle bg-gray-200 border-[1px] border-white custom-shadow hover:bg-white p-2 border-radius-10" >

                                    <img src={avatar2} alt="avatar1" width={'42px'} className={'border-radius-10 h-[42px] me-2'}/>
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">روجينا فتوح</p>
                                        <p className="font-regular fs-15">#134578</p>
                                    </div>
                                    <div className="w-50 flex items-end h-auto">
                                        <p className={'font-semiBold fs-12 text-red-600' }>لم يتم التفعيل</p>
                                    </div>
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">120 SAR</p>
                                        <p className="font-semiBold fs-15">منذ 3  ساعات</p>
                                    </div>

                                </div>
                            </div>
                            <div className={'custom-sell-box'}>
                                <div className="custom-sell-content mb-4 flex align-middle bg-gray-200 border-[1px] border-white custom-shadow hover:bg-white p-2 border-radius-10" >

                                    <img src={avatar4} alt="avatar1" width={'42px'} className={'border-radius-10 h-[42px] me-2'}/>
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">روجينا فتوح</p>
                                        <p className="font-regular fs-15">#134578</p>
                                    </div>
                                    <div className="w-50 flex items-end h-auto">
                                        <p className={'font-semiBold fs-12 text-red-600' }>لم يتم التفعيل</p>
                                    </div>
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">120 SAR</p>
                                        <p className="font-semiBold fs-15">منذ 3  ساعات</p>
                                    </div>

                                </div>
                            </div>
                            <div className={'custom-sell-box'}>
                                <div className="custom-sell-content mb-4 flex align-middle bg-gray-200 border-[1px] border-white custom-shadow hover:bg-white p-2 border-radius-10" >

                                    <img src={avatar3} alt="avatar1" width={'42px'} className={'border-radius-10 h-[42px] me-2'}/>
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">روجينا فتوح</p>
                                        <p className="font-regular fs-15">#134578</p>
                                    </div>
                                    <div className="w-50 flex items-end h-auto">
                                        <p className={'font-semiBold fs-12 text-red-600' }>لم يتم التفعيل</p>
                                    </div>
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">120 SAR</p>
                                        <p className="font-semiBold fs-15">منذ 3  ساعات</p>
                                    </div>

                                </div>
                            </div>
                            <div className={'custom-sell-box'}>
                                <div className="custom-sell-content mb-4 flex align-middle bg-gray-200 border-[1px] border-white custom-shadow hover:bg-white p-2 border-radius-10" >

                                    <img src={avatar2} alt="avatar1" width={'42px'} className={'border-radius-10 h-[42px] me-2'}/>
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">روجينا فتوح</p>
                                        <p className="font-regular fs-15">#134578</p>
                                    </div>
                                    <div className="w-50 flex items-end h-auto">
                                        <p className={'font-semiBold fs-12 text-red-600' }>لم يتم التفعيل</p>
                                    </div>
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">120 SAR</p>
                                        <p className="font-semiBold fs-15">منذ 3  ساعات</p>
                                    </div>

                                </div>
                            </div>
                            <div className={'custom-sell-box'}>
                                <div className="custom-sell-content mb-4 flex align-middle bg-gray-200 border-[1px] border-white custom-shadow hover:bg-white p-2 border-radius-10" >

                                    <img src={avatar3} alt="avatar1" width={'42px'} className={'border-radius-10 h-[42px] me-2'}/>
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">روجينا فتوح</p>
                                        <p className="font-regular fs-15">#134578</p>
                                    </div>
                                    <div className="w-50 flex items-end h-auto">
                                        <p className={'font-semiBold fs-12 text-red-600' }>لم يتم التفعيل</p>
                                    </div>
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">120 SAR</p>
                                        <p className="font-semiBold fs-15">منذ 3  ساعات</p>
                                    </div>

                                </div>
                            </div>
                            <div className={'custom-sell-box'}>
                                <div className="custom-sell-content mb-4 flex align-middle bg-gray-200 border-[1px] border-white custom-shadow hover:bg-white p-2 border-radius-10" >

                                    <img src={avatar5} alt="avatar1" width={'42px'} className={'border-radius-10 h-[42px] me-2'}/>
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">روجينا فتوح</p>
                                        <p className="font-regular fs-15">#134578</p>
                                    </div>
                                    <div className="w-50 flex items-end h-auto">
                                        <p className={'font-semiBold fs-12 text-red-600' }>لم يتم التفعيل</p>
                                    </div>
                                    <div className="w-50">
                                        <p className="font-semiBold fs-15">120 SAR</p>
                                        <p className="font-semiBold fs-15">منذ 3  ساعات</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    );
};

export default Dashboard;
