import ProductTable from "./components/ProductTable";
import CustomSelect from "../../../../components/select/select";
import InputField from "../../../../components/fields/InputField";
import React from "react";
import Filter from "../../../../components/filter/Filter";
import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import PrintButton from "../../../../components/filterButton/printBtn";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import CardMenu from "../../../../components/card/CardMenu";
          type Option = typeof sampleOptions[0];
    const handleChange = (selectedValues: Option[]) => {
        console.log(selectedValues);
    }; const sampleOptions = [
    {label: "option 1", value: "option-1"},
    {label: "option 2", value: "option-2"},
    {label: "option 3", value: "option-3"},
];

const SubscriptionProduct = () => {
    const { t } = useTranslation();
    return (
    <div>
        <div>
            <ScreanTitle title={t('SUBSCRIPTION_PRODUCTS')}></ScreanTitle>
            <div className={'flex justify-between pb-3'}>
                <div>
                    <button className="btn btn-primary mx-3 flex items-center">
                        <span className={'pe-3'}>{t('ADD_A_NEW_PRODUCT')}</span>
                        <em className={'icon-plus'}></em>
                    </button>
                </div>
                <div className={'flex'}>
                    <PrintButton/>
                    <UploadButton/>
                    <CardMenu
                        classList="icon-filter fs-25"
                        menu={<div className="z-50 w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                            <p className="hover:text-black flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium">
                                Panel 1
                            </p>
                            <p className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium">
                                Panel 2
                            </p>
                            <p className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium">
                                Panel 3
                            </p>
                            <p className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium">
                                Panel 4
                            </p>
                        </div>
                        }
                    />
                </div>
            </div>
        </div>
        <div className={'white-card pb-3 pt-1'}>
            <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('NEW_CARD_DISPLAY_ENTRY_SCREEN')}</p>
            <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pb-3">
                <div>
                   <InputField
                       label={t('DISPLAY_NAME_IN_ARABIC')}
                       placeholder={t('DISPLAY_NAME_IN_ARABIC')}
                       id="NAME_ARABIC"
                       type="text"
                    />
               </div>
                <div>
                    <InputField
                        label={t('DISPLAY_NAME_IN_ENGLISH')}
                        placeholder={t('DISPLAY_NAME_IN_ENGLISH')}
                        id="NAME_IN_ENGLISH"
                        type="text"
                    />
                </div>
                <div>
                     <CustomSelect  onChange={handleChange}
                         label={t('PERIOD_TYPE')}
                         placeholder={t('PERIOD_TYPE')}
                         options={sampleOptions}
                     />
                 </div>
                <div>
                    <CustomSelect  onChange={handleChange}
                        label={t('EXPIRE')}
                        placeholder={t('EXPIRE')}
                        options={sampleOptions}
                    />
                </div>
                <div>
                    <InputField
                        label={t('ORIGINAL_AMOUNT')}
                        placeholder={t('ORIGINAL_AMOUNT')}
                        id="ORIGINAL_AMOUNT"
                        type="text"
                    />
                </div>
                <div>
                    <InputField
                        label={t('DISCOUNTED_AMOUNT')}
                        placeholder={t('DISCOUNTED_AMOUNT')}
                        id="DISCOUNTED_AMOUNT"
                        type="text"
                    />
                </div>
                <div>
                    <InputField
                        label={t('THE_NUMBER')}
                        placeholder={t('THE_NUMBER')}
                        id="THE_NUMBER"
                        type="text"
                    />
                </div>
                <div>
                    <InputField
                        label={t('ADDED_TAX_RATE')}
                        placeholder={t('ADDED_TAX_RATE')}
                        id="ADDED_TAX_RATE"
                        type="text"
                    />
                </div>
            </div>
            <div className={'flex justify-center py-4'}>
                <button className="btn btn-primary mx-3">
                    <em className={'icon-pen'}></em>
                    <span className={'ps-3'}>{t('AMENDMENT')}</span>
                </button>
                <button className="btn btn-secondary mx-3">
                    <em className={'icon-check'}></em>
                    <span className={'ps-3'}>{t('SAVE')}</span>
                </button>
                <button className="btn btn-outline-secondary mx-3">
                    <em className={'icon-close'}></em>
                    <span className={'ps-3'}>{t('CLOSE')}</span>
                </button>
            </div>
        </div>
      <div className="mt-1 grid h-full grid-cols-1 md:grid-cols-1">
          <ProductTable/>
      </div>
    </div>
  );
};


export default SubscriptionProduct;
