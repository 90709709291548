import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import React, {useState} from "react";
import CardMenu from "../../../../components/card/CardMenu";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import PrintButton from "../../../../components/filterButton/printBtn";
import RegisteredTable from "./components/registeredCustomerTable";

const CustomersRegistered = () => {
    const { t } = useTranslation();
    const data = [
        { title: t('CUSTOMERS') },
        { title: t('SERVICE_PROVIDERS') },
    ];
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const handleCardClick = (index: number) => {
        setActiveIndex(index);
    };
    return (
        <div>
            <div>
                <ScreanTitle title={t('MANAGE_CUSTOMERS_REGISTERED_ON_THE_WEBSITE_AND_APPLICATION')}></ScreanTitle>
                <div className={'flex justify-between pb-3'}>
                    <div className={'flex'}>
                        <button className="btn btn-primary mx-3 flex items-center">
                            <span className={'pe-3'}>{t('PROPERTIES_REGISTRATION')}</span>
                            <em className={'icon-plus'}></em>
                        </button>
                    </div>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                        <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    حسب الفرع
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب المدينة
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب الدولة
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب الحالة
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب الاسم
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب رقم الجوال
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="flex mt-3 checkListCard  gap-5 lg:gap-20 px-0 pb-3 justify-center ">
                {data.map((item, index) => (
                    <div
                        key={index}
                        className={`simple-card py-2 px-7 md:min-w-[220px] ${index === activeIndex && 'active'}`}
                        onClick={() => handleCardClick(index)}
                    >
                        <p className= {` text-center fs-19 ${index === activeIndex ? 'font-bold text-primary-500 border-b-primary-500' : 'font-medium text-gray-600'}`}>{item.title}</p>
                    </div>
                ))}
            </div>

            <div className="mt-1 grid h-full grid-cols-1 md:grid-cols-1">

                <RegisteredTable />
            </div>
        </div>
    );
};


export default CustomersRegistered;
