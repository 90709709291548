import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import React, {useState} from "react";
import CardMenu from "../../../../components/card/CardMenu";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import PrintButton from "../../../../components/filterButton/printBtn";
import CustomerRatingTable from "./components/customerRatingTable";
import Checkbox from "../../../../components/checkbox";
import avatar4 from "../../../../assets/img/avatars/avatar4.png";

const CustomerRatings = () => {
    const { t } = useTranslation();
    const data = [
        { title: t('SUBSCRIPTION_RATINGS') },
        { title: t('RATINGS_DEALER_OFFER') },
    ];
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const handleCardClick = (index: number) => {
        setActiveIndex(index);
    };
    return (
        <div>
            <div>
                <ScreanTitle title={t('CUSTOMER_RATINGS_SCREEN')}></ScreanTitle>
                <div className={'flex justify-end pb-3'}>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                        <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    حسب الفرع
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب المدينة
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب الدولة
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب الحالة
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب الاسم
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب رقم الجوال
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="flex my-3 checkListCard  gap-5 lg:gap-20 px-0 pb-3 justify-center ">
                {data.map((item, index) => (
                    <div
                        key={index}
                        className={`simple-card py-2 px-7 md:min-w-[220px] ${index === activeIndex && 'active'}`}
                        onClick={() => handleCardClick(index)}
                    >
                        <p className= {` text-center fs-19 ${index === activeIndex ? 'font-bold text-primary-500 border-b-primary-500' : 'font-medium text-gray-600'}`}>{item.title}</p>
                    </div>
                ))}
            </div>

            <div className="mt-1 grid h-full grid-cols-1 md:grid-cols-1 px-2 bg-white border-radius-20">
                <div className={'steppedTable pt-3'}>
                    <div className="stepped-element grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-11 px-4 pt-2 pb-1">
                        <div className={'col-span-1'}>
                            <div className={'flex items-center h-full'}>
                                <span className={'font-regular fs-15 text-gray-600'}> 1</span>
                                <div className={'ps-2'}>
                                    <Checkbox></Checkbox>
                                </div>
                            </div>

                        </div>
                        <div className={'col-span-1'}>
                            <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIPS')}</p>
                            <img src={avatar4} alt="avatar4" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>
                        </div>
                        <div className={'col-span-2'}>
                            <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIP_HOLDER_NAME')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>بشار بعاج</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('CITY')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>الرياض</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('PHONE')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>0563231328</p>
                        </div>
                        <div className={'col-span-2'}>
                            <p className={'font-regular fs-15 pb-1'}>{t('MAIL')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>tkaful@gmail.com</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('PASSWORD')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>عادي</p>
                        </div>
                        <div className={'col-span-1'}>
                            <p className={'font-regular fs-15 pb-1'}>{t('DATE_INSCRIPTION')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>1-1-2023</p>
                        </div>

                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('REVIEW')}</p>
                            <CardMenu
                                btn={<button className="action-btn border-primary bg-white " >
                                    <em className={'icon-search-normal fs-16 text-primary-500'} />
                                </button>}
                                menu={<div className="z-50 ">
                                    <p className="text-black cursor-pointer">
                                        ارسال الى مجموعة
                                    </p>
                                    <p className="text-black cursor-pointer">
                                        عملاء  الأكثر شراء
                                    </p>
                                    <p className="text-black cursor-pointer">
                                        أصحاب ولاء مرتفع
                                    </p>
                                    <p className="text-black cursor-pointer">
                                        الفئة الماسية
                                    </p>
                                    <p className="text-black cursor-pointer">
                                        ارشيف
                                    </p>
                                </div>
                                }
                            />
                        </div>
                    </div>
                    <div className="stepped-element grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-11 px-4 pt-2 pb-1">
                        <div className={'col-span-1'}>
                            <div className={'flex items-center h-full'}>
                                <span className={'font-regular fs-15 text-gray-600'}> 2</span>
                                <div className={'ps-2'}>
                                    <Checkbox></Checkbox>
                                </div>
                            </div>

                        </div>
                        <div className={'col-span-1'}>
                            <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIPS')}</p>
                            <img src={avatar4} alt="avatar4" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>
                        </div>
                        <div className={'col-span-2'}>
                            <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIP_HOLDER_NAME')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>بشار بعاج</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('CITY')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>الرياض</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('PHONE')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>0563231328</p>
                        </div>
                        <div className={'col-span-2'}>
                            <p className={'font-regular fs-15 pb-1'}>{t('MAIL')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>tkaful@gmail.com</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('PASSWORD')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>عادي</p>
                        </div>
                        <div className={'col-span-1'}>
                            <p className={'font-regular fs-15 pb-1'}>{t('DATE_INSCRIPTION')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>1-1-2023</p>
                        </div>

                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('REVIEW')}</p>
                            <CardMenu
                                btn={<button className="action-btn border-primary bg-white " >
                                    <em className={'icon-search-normal fs-16 text-primary-500'} />
                                </button>}
                                menu={<div className="z-50 ">
                                    <p className="text-black cursor-pointer">
                                        ارسال الى مجموعة
                                    </p>
                                    <p className="text-black cursor-pointer">
                                        عملاء  الأكثر شراء
                                    </p>
                                    <p className="text-black cursor-pointer">
                                        أصحاب ولاء مرتفع
                                    </p>
                                    <p className="text-black cursor-pointer">
                                        الفئة الماسية
                                    </p>
                                    <p className="text-black cursor-pointer">
                                        ارشيف
                                    </p>
                                </div>
                                }
                            />
                        </div>
                    </div>
                    <div className="stepped-element grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-11 px-4 pt-2 pb-1">
                        <div className={'col-span-1'}>
                            <div className={'flex items-center h-full'}>
                                <span className={'font-regular fs-15 text-gray-600'}> 1</span>
                                <div className={'ps-2'}>
                                    <Checkbox></Checkbox>
                                </div>
                            </div>

                        </div>
                        <div className={'col-span-1'}>
                            <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIPS')}</p>
                            <img src={avatar4} alt="avatar4" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>
                        </div>
                        <div className={'col-span-2'}>
                            <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIP_HOLDER_NAME')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>بشار بعاج</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('CITY')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>الرياض</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('PHONE')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>0563231328</p>
                        </div>
                        <div className={'col-span-2'}>
                            <p className={'font-regular fs-15 pb-1'}>{t('MAIL')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>tkaful@gmail.com</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('PASSWORD')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>عادي</p>
                        </div>
                        <div className={'col-span-1'}>
                            <p className={'font-regular fs-15 pb-1'}>{t('DATE_INSCRIPTION')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>1-1-2023</p>
                        </div>

                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('REVIEW')}</p>
                            <CardMenu
                                btn={<button className="action-btn border-primary bg-white " >
                                    <em className={'icon-search-normal fs-16 text-primary-500'} />
                                </button>}
                                menu={<div className="z-50 ">
                                    <p className="text-black cursor-pointer">
                                        ارسال الى مجموعة
                                    </p>
                                    <p className="text-black cursor-pointer">
                                        عملاء  الأكثر شراء
                                    </p>
                                    <p className="text-black cursor-pointer">
                                        أصحاب ولاء مرتفع
                                    </p>
                                    <p className="text-black cursor-pointer">
                                        الفئة الماسية
                                    </p>
                                    <p className="text-black cursor-pointer">
                                        ارشيف
                                    </p>
                                </div>
                                }
                            />
                        </div>
                    </div>
                    <div className="stepped-element grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-11 px-4 pt-2 pb-1">
                        <div className={'col-span-1'}>
                            <div className={'flex items-center h-full'}>
                                <span className={'font-regular fs-15 text-gray-600'}> 1</span>
                                <div className={'ps-2'}>
                                    <Checkbox></Checkbox>
                                </div>
                            </div>

                        </div>
                        <div className={'col-span-1'}>
                            <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIPS')}</p>
                            <img src={avatar4} alt="avatar4" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>
                        </div>
                        <div className={'col-span-2'}>
                            <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIP_HOLDER_NAME')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>بشار بعاج</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('CITY')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>الرياض</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('PHONE')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>0563231328</p>
                        </div>
                        <div className={'col-span-2'}>
                            <p className={'font-regular fs-15 pb-1'}>{t('MAIL')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>tkaful@gmail.com</p>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('PASSWORD')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>عادي</p>
                        </div>
                        <div className={'col-span-1'}>
                            <p className={'font-regular fs-15 pb-1'}>{t('DATE_INSCRIPTION')}</p>
                            <p className={'font-regular fs-15 text-gray-600'}>1-1-2023</p>
                        </div>

                        <div>
                            <p className={'font-regular fs-15 pb-1'}>{t('REVIEW')}</p>
                            <CardMenu
                                btn={<button className="action-btn border-primary bg-white " >
                                    <em className={'icon-search-normal fs-16 text-primary-500'} />
                                </button>}
                                menu={<div className="z-50 ">
                                    <p className="text-black cursor-pointer">
                                        ارسال الى مجموعة
                                    </p>
                                    <p className="text-black cursor-pointer">
                                        عملاء  الأكثر شراء
                                    </p>
                                    <p className="text-black cursor-pointer">
                                        أصحاب ولاء مرتفع
                                    </p>
                                    <p className="text-black cursor-pointer">
                                        الفئة الماسية
                                    </p>
                                    <p className="text-black cursor-pointer">
                                        ارشيف
                                    </p>
                                </div>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default CustomerRatings;
