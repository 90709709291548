// Custom components
import React from "react";

function TextArea(props: {
  id?: string;
  label?: string;
  name?: string;
  extra?: string;
  placeholder?: string;
  variant?: string;
  state?: string;
  disabled?: boolean;
  type?: string;
}) {
  const { label, id, extra, type, placeholder, state,name, disabled } =
    props;

  return (
    <div className={`${extra}`}>
        {label && <label
            htmlFor={id}
            className={`font-medium fs-15 ps-3 dark:text-white`}
        >
            {label}
        </label>}
      <textarea
          disabled={disabled}
          id={id}
          placeholder={placeholder}
          className={`mt-1 flex w-full items-center h-[150px] justify-center border-radius-10 border bg-white px-2 pt-2 outline-none ${
              disabled === true
                  ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
                  : state === "error"
                      ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
                      : state === "success"
                          ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                          : "border-gray dark:!border-white/10 dark:text-white"
          }`} name={name} >
     </textarea>
    </div>
  );
}

export default TextArea;
