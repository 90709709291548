import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import React from "react";
import CustomSelect from "../../../../../components/select/select";
import InputField from "../../../../../components/fields/InputField";
import {useTranslation} from "react-i18next";

interface NewOrderModalProps {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
}
     type Option = typeof sampleOptions[0];
    const handleChange = (selectedValues: Option[]) => {
        console.log(selectedValues);
    };
    const sampleOptions = [
    { label: "option 1", value: "option-1" },
    { label: "option 2", value: "option-2" },
    { label: "option 3", value: "option-3" },
];

const CompanyEmployeesModal: React.FC<NewOrderModalProps> = ({ isOpen, onOpen, onClose }) => {
    const { t } = useTranslation();
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay className="bg-[#181C30] !opacity-80" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] lg:min-w-[680px] !max-w-[95%]">
                     <span className={'close-modal cursor-pointer w-[35px] h-[35px] bg-red-500 dark:bg-navy-200 flex items-center justify-center'}   onClick={onClose}>
                       <em className={'icon-close fs-20 text-white dark:text-white'}></em>
                     </span>
                    <ModalBody>
                        <div className="modal-body bg-white border-radius-40 px-[15px] md:px-[30px] pt-[35px] pb-[40px] flex flex-col !z-[1004]">
                            <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('CARD_DISPLAY_SCREEN')}</p>
                            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 pb-3">
                                <div>
                                    <InputField
                                        label={t('DISPLAY_NAME_IN_ARABIC')}
                                        placeholder={t('DISPLAY_NAME_IN_ARABIC')}
                                        id="NAME_ARABIC"
                                        type="text"
                                    />
                                </div>
                                <div>
                                    <InputField
                                        label={t('DISPLAY_NAME_IN_ENGLISH')}
                                        placeholder={t('DISPLAY_NAME_IN_ENGLISH')}
                                        id="NAME_IN_ENGLISH"
                                        type="text"
                                    />
                                </div>
                                <div>
                                    <CustomSelect  onChange={handleChange}
                                        label={t('PERIOD_TYPE')}
                                        placeholder={t('PERIOD_TYPE')}
                                        options={sampleOptions}
                                    />
                                </div>
                                <div>
                                    <CustomSelect  onChange={handleChange}
                                        label={t('EXPIRE')}
                                        placeholder={t('EXPIRE')}
                                        options={sampleOptions}
                                    />
                                </div>
                                <div>
                                    <InputField
                                        label={t('ORIGINAL_AMOUNT')}
                                        placeholder={t('ORIGINAL_AMOUNT')}
                                        id="ORIGINAL_AMOUNT"
                                        type="text"
                                    />
                                </div>
                                <div>
                                    <InputField
                                        label={t('DISCOUNTED_AMOUNT')}
                                        placeholder={t('DISCOUNTED_AMOUNT')}
                                        id="DISCOUNTED_AMOUNT"
                                        type="text"
                                    />
                                </div>
                                <div>
                                    <InputField
                                        label={t('THE_NUMBER')}
                                        placeholder={t('THE_NUMBER')}
                                        id="THE_NUMBER"
                                        type="text"
                                    />
                                </div>
                                <div>
                                    <InputField
                                        label={t('ADDED_TAX_RATE')}
                                        placeholder={t('ADDED_TAX_RATE')}
                                        id="ADDED_TAX_RATE"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div className={'flex justify-center py-4'}>
                                <button className="btn btn-primary mx-3">
                                    <em className={'icon-pen'}></em>
                                    <span className={'ps-3'}>{t('AMENDMENT')}</span>
                                </button>
                                <button className="btn btn-secondary mx-3">
                                    <em className={'icon-check'}></em>
                                    <span className={'ps-3'}>{t('SAVE')}</span>
                                </button>
                            </div>
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
    ); };
export default CompanyEmployeesModal;