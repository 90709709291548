import Filter from "../../../../components/filter/Filter";
import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";

import InputField from "../../../../components/fields/InputField";
import React from "react";
import CustomSelect from "../../../../components/select/select";
import CardMenu from "../../../../components/card/CardMenu";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import PrintButton from "../../../../components/filterButton/printBtn";
import Progress from "../../../../components/progress";

const Followup = () => {
    const { t } = useTranslation();
    type Option = typeof sampleOptions[0];
    const handleChange = (selectedValues: Option[]) => {
        console.log(selectedValues);
    };
    const sampleOptions = [
        {label: "option 1", value: "option-1"},
        {label: "option 2", value: "option-2"},
        {label: "option 3", value: "option-3"},
    ];
    return (
        <div>
            <div>
                <ScreanTitle title={t('DELEGATES_STATISTICS_SCREEN_FOR_THE_CONTRACTS_MANAGER')}></ScreanTitle>
                <div className={'flex justify-end pb-3'}>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                               <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    فلتر 1
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 2
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 3
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 4
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>

            <div className={'white-card border-radius-20 dashed-border  pb-3 pt-1'}>
                        <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pb-3">
                            <div>
                                <CustomSelect  onChange={handleChange}
                                    label={t('COUNTRY')}
                                    placeholder={t('COUNTRY')}
                                    options={sampleOptions}
                                />
                            </div>
                            <div>
                                <CustomSelect  onChange={handleChange}
                                    label={t('ACCORDING_CITY')}
                                    placeholder={t('CITY_FILTER')}
                                    options={sampleOptions}
                                />
                            </div>
                            <div>
                                <CustomSelect  onChange={handleChange}
                                    label={t('ACCORDING_TO_TYPE')}
                                    placeholder={t('ACCORDING_TO_TYPE')}
                                    options={sampleOptions}
                                />
                            </div>
                            <div>
                                <CustomSelect  onChange={handleChange}
                                    label={t('ACCORDING_TO_DELEGATE')}
                                    placeholder={t('ACCORDING_TO_DELEGATE')}
                                    options={sampleOptions}
                                />
                            </div>
                        </div>
                        <div className={'flex justify-center py-4'}>
                            <button className="btn btn-secondary">
                                <span className={''}>{t('FILTERING')}</span>
                            </button>
                        </div>
                    </div>
            <div className={'white-card pb-3 pt-1 mt-5'}>
                <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-2 px-4 pb-3">

                    <div className={'card-slider'}>
                        <div className="card-content">
                            <div className={'flex justify-between'} >
                                <p className={'font-bold fs-16'}>{t('NUMBER_OF_NEW_CONTRACTS')}</p>
                                <p className={'font-regular fs-16'}> {t('NUMBER')} <span className={'font-bold'}>4500</span></p>
                            </div>
                            <div className={'flex items-center'}>
                                <span className={'font-medium pe-3'}>1</span>
                                 <Progress value={70} color="yellow" showValue={true}/>
                            </div>
                        </div>
                    </div>
                    <div className={'card-slider'}>
                        <div className="card-content">
                            <div className={'flex justify-between'} >
                                <p className={'font-bold fs-16'}>{t('NUMBER_OF_IMPROVED_CONTRACTS')}</p>
                                <p className={'font-regular fs-16'}> {t('NUMBER')} <span className={'font-bold'}>4500</span></p>
                            </div>
                            <div className={'flex items-center'}>
                                <span className={'font-medium pe-3'}>1</span>
                                 <Progress value={70} color="yellow" showValue={true}/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className={'white-card pb-3 pt-1 mt-5'}>
                <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 px-4 pb-3">
                    <div className={'card-slider'}>
                        <div className="card-content">
                            <div className={'flex justify-between'} >
                                <p className={'font-bold fs-16'}>{t('NUMBER_OF_VOUCHERS')}</p>
                                <p className={'font-regular fs-16'}> {t('NUMBER')} <span className={'font-bold'}>4500</span></p>
                            </div>
                            <div className={'flex items-center'}>
                                <span className={'font-medium pe-3'}>1</span>
                                 <Progress value={70} color="yellow" showValue={true}/>
                            </div>
                        </div>
                    </div>
                    <div className={'card-slider'}>
                        <div className="card-content">
                            <div className={'flex justify-between'} >
                                <p className={'font-bold fs-16'}>{t('NUMBER_OF_GIFT_CARDS')}</p>
                                <p className={'font-regular fs-16'}> {t('NUMBER')} <span className={'font-bold'}>4500</span></p>
                            </div>
                            <div className={'flex items-center'}>
                                <span className={'font-medium pe-3'}>1</span>
                                 <Progress value={70} color="yellow" showValue={true}/>
                            </div>
                        </div>
                    </div>
                    <div className={'card-slider'}>
                        <div className="card-content">
                            <div className={'flex justify-between'} >
                                <p className={'font-bold fs-16'}>{t('NUMBER_OF_COUPONS')}</p>
                                <p className={'font-regular fs-16'}> {t('NUMBER')} <span className={'font-bold'}>4500</span></p>
                            </div>
                            <div className={'flex items-center'}>
                                <span className={'font-medium pe-3'}>1</span>
                                 <Progress value={70} color="yellow" showValue={true}/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className={'white-card pb-3 pt-1 mt-5'}>
                <div className="mt-4 grid grid-cols-1 gap-5 px-4 pb-3">
                    <div className={'card-slider'}>
                        <div className="card-content">
                            <div className={'flex justify-between'} >
                                <p className={'font-bold fs-16'}>{t('NUMBER_OF_VISITS')}</p>
                                <p className={'font-regular fs-16'}> {t('NUMBER')} <span className={'font-bold'}>4500</span></p>
                            </div>
                            <div className={'flex items-center'}>
                                <span className={'font-medium pe-3'}>1</span>
                                 <Progress value={70} color="yellow" showValue={true}/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className={'white-card pb-3 pt-1 mt-5'}>
                <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-2 px-4 pb-3">

                    <div className={'card-slider'}>
                        <div className="card-content">
                            <div className={'flex justify-between'} >
                                <p className={'font-bold fs-16'}>{t('NUMBER_OF_COMPLAINTS')}</p>
                                <p className={'font-regular fs-16'}> {t('NUMBER')} <span className={'font-bold'}>4500</span></p>
                            </div>
                            <div className={'flex items-center'}>
                                <span className={'font-medium pe-3'}>1</span>
                                 <Progress value={70} color="yellow" showValue={true}/>
                            </div>
                        </div>
                    </div>
                    <div className={'card-slider'}>
                        <div className="card-content">
                            <div className={'flex justify-between'} >
                                <p className={'font-bold fs-16'}>{t('NUMBER_OF_FOLLOW')}</p>
                                <p className={'font-regular fs-16'}> {t('NUMBER')} <span className={'font-bold'}>4500</span></p>
                            </div>
                            <div className={'flex items-center'}>
                                <span className={'font-medium pe-3'}>1</span>
                                <Progress value={70} color="yellow" showValue={true}/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};


export default Followup;
