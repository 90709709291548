import React from 'react';

interface ScreanTitleProps {
  title: string;
}

const ScreanTitle: React.FC<ScreanTitleProps> = ({ title}) => {
  return (
      <div className={'flex justify-center pb-3'}>
        <h2 className={'font-bold text-primary-500 fs-27'}>{title}</h2>
      </div>
  );
};

export default ScreanTitle;
