import React from "react";

const Checkbox = (props: {
  label?: string;
  id?: string;
  reverse?: boolean;
  extra?: string;
  color?:
    | "red"
    | "blue"
    | "green"
    | "yellow"
    | "orange"
    | "teal"
    | "navy"
    | "lime"
    | "cyan"
    | "pink"
    | "purple"
    | "amber"
    | "indigo"
    | "gray";
  [x: string]: any;
}) => {
  const { extra,label, color,id,reverse, ...rest } = props;
  return (
    <div className={`flex ${reverse ? 'flex-row-reverse' : ''}`}>
      <input
          type="checkbox"
          id={id}
          className={`defaultCheckbox bg-white relative flex h-[20px] min-h-[20px] w-[20px] min-w-[20px] appearance-none items-center 
              justify-center rounded-md border border-navy-50 text-white/0 outline-none transition duration-[0.2s] border-radius-3 
              checked:border-none checked:text-white hover:cursor-pointer dark:border-white/10 ${
               color === "yellow"
                              ? "checked:border-none checked:bg-yellow-500 dark:checked:bg-yellow-400"
                 : "checked:bg-primary-500 "
          } ${extra}`}
          name="weekly"
          {...rest}
      />
      {label && <label htmlFor={id} className={'font-semiBold fs-13 cursor-pointer px-3 dark:text-white '} >{label}</label>}
    </div>
  );
};

export default Checkbox;
