
import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import React from "react";
import CustomSelect from "../../../../components/select/select";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import PrintButton from "../../../../components/filterButton/printBtn";
import Switch from "../../../../components/switch";
import CustomTable from "../../../../components/customTable/CustomTable";
import Checkbox from "../../../../components/checkbox";
import {useNavigate} from "react-router-dom";
import CardMenu from "../../../../components/card/CardMenu";

const SpecialOffers = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const goToAddNewOffer = () => {
        navigate('/private/marketing-management/special-offers/add-special-offer');
    };
    type Option = typeof sampleOptions[0];
    const handleChange = (selectedValues: Option[]) => {
        console.log(selectedValues);
    };
    const sampleOptions = [
        {label: "option 1", value: "option-1"},
        {label: "option 2", value: "option-2"},
        {label: "option 3", value: "option-3"},
    ];

    const data = [
        {content: <div className={'flex items-center h-full'}>
                            <span className={'font-regular fs-15 text-gray-600'}> 1</span>
                            <div className={'ps-2'}>
                                <Checkbox></Checkbox>
                            </div>
                        </div>, colSpan: 1 },
        { label: "SHOW_NAME", content: "بطاقة عليك وبطاقة عاينا", colSpan: 2 },
        { label: "OFFER_START_DATE", content: "1-1-2023 20:19:02", colSpan: 2 },
        { label: "OFFER_EXPIRY_DATE", content: "1-1-2023 20:19:02", colSpan: 2 },
        { label: "STATISTIC", content: <div className="flex">
                    <button className={'action-btn border-primary bg-white me-2'} >
                        <span className={'icon-copy fs-18 text-primary-500'}/>
                    </button>
                </div> , colSpan: 1 },
        { label: "STOP_TEMPORARY", content: <Switch id="switch1"/>, colSpan: 1 },
    ];
    return (
        <div>
            <div>
                <ScreanTitle title={t('SPECIAL_OFFERS_SCREEN')}></ScreanTitle>
                <div className={'flex justify-between pb-3'}>
                    <div>
                        <button className="btn btn-primary flex items-center" onClick={goToAddNewOffer}>
                            <span className={'pe-3'}>{t('ADD_SPECIAL_OFFERS')}</span>
                            <em className={'icon-plus'}></em>
                        </button>
                    </div>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                        <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    فلتر 1
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 2
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 3
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 4
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <div>
                <div className={'pb-5'}>
                    <CustomSelect  onChange={handleChange}
                        placeholder={t('COMMISSION_TYPE')}
                        options={sampleOptions}
                        minWidth={'250px'}
                    />
                </div>

                <div className="white-card">
                    <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('CURRENT_MARKETERS')}</p>

                    <div className={'steppedTable pt-3'}>
                        <CustomTable data={data} numColumns="9" />
                        <CustomTable data={data} numColumns="9" />
                        <CustomTable data={data} numColumns="9" />
                        <CustomTable data={data} numColumns="9" />
                    </div>
                </div>
            </div>
        </div>
    );
};


export default SpecialOffers;
