import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Slider from "react-slick";
import Progress from "../../../../components/progress";
import React from "react";
import isMobile from "../../../../utils/mobileUtils";
import {useTranslation} from "react-i18next";

function SampleNextArrow(props: any) {
    const { className, onClick } = props;
    return (
        <div >
            <div onClick={onClick} className={className}>
                <em className={'chevron-left text-primary-500 bg-primary-500'}/>
            </div>
        </div>
    );
}

function SamplePrevArrow(props: any) {
    const { className, onClick } = props;
    return (
        <div >
            <div onClick={onClick} className={className}>
                <em className={'chevron-left text-primary-500 bg-primary-500'} />
            </div>
        </div>
    );
}
const ProductSalesRate = () => {
    const { t } = useTranslation();
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,

        rtl: true,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    vertical :true,
                    verticalSwiping: true,
                    swipe: true,
                    swipeToSlide: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    vertical :true,
                    verticalSwiping: true,
                    swipe: true,
                    swipeToSlide: true,
                    slidesToShow: 3,

                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
    <div>
        <Slider {...settings}>
            <div className={'card-slider'}>
                <div className="card-content">
                    <div className={'flex justify-between flex-row-reverse'} >
                        <p className={'font-bold fs-16'}>{t('CURRENT_SUBSCRIPTIONS')}</p>
                        <p className={'font-regular fs-16'}> {t('NUMBER')} <span className={'font-bold'}>4500</span></p>
                    </div>
                    <div className={'flex items-center'}>
                        <span className={'font-medium pe-3'}>1</span>
                        <Progress value={70} color="yellow"/>
                    </div>
                </div>
            </div>
            <div className={'card-slider'}>
                <div className="card-content">
                    <div className={'flex justify-between flex-row-reverse'} >
                        <p className={'font-bold fs-16'}>{t('CURRENT_SUBSCRIPTIONS')}</p>
                        <p className={'font-regular fs-16'}> {t('NUMBER')} <span className={'font-bold'}>4500</span></p>
                    </div>
                    <div className={'flex items-center'}>
                        <span className={'font-medium pe-3'}>1</span>
                        <Progress value={70} color="yellow"/>
                    </div>
                </div>
            </div>
            <div className={'card-slider'}>
                <div className="card-content">
                    <div className={'flex justify-between flex-row-reverse'} >
                        <p className={'font-bold fs-16'}>{t('CURRENT_SUBSCRIPTIONS')}</p>
                        <p className={'font-regular fs-16'}> {t('NUMBER')} <span className={'font-bold'}>4500</span></p>
                    </div>
                    <div className={'flex items-center'}>
                        <span className={'font-medium pe-3'}>1</span>
                        <Progress value={70} color="yellow"/>
                    </div>
                </div>
            </div>
            <div className={'card-slider'}>
                <div className="card-content">
                    <div className={'flex justify-between flex-row-reverse'} >
                        <p className={'font-bold fs-16'}>{t('CURRENT_SUBSCRIPTIONS')}</p>
                        <p className={'font-regular fs-16'}> {t('NUMBER')} <span className={'font-bold'}>4500</span></p>
                    </div>
                    <div className={'flex items-center'}>
                        <span className={'font-medium pe-3'}>1</span>
                        <Progress value={70} color="yellow"/>
                    </div>
                </div>
            </div>
            <div className={'card-slider'}>
                <div className="card-content">
                    <div className={'flex justify-between flex-row-reverse'} >
                        <p className={'font-bold fs-16'}>{t('CURRENT_SUBSCRIPTIONS')}</p>
                        <p className={'font-regular fs-16'}> {t('NUMBER')} <span className={'font-bold'}>4500</span></p>
                    </div>
                    <div className={'flex items-center'}>
                        <span className={'font-medium pe-3'}>1</span>
                        <Progress value={70} color="yellow"/>
                    </div>
                </div>
            </div>
            <div className={'card-slider'}>
                <div className="card-content">
                    <div className={'flex justify-between flex-row-reverse'} >
                        <p className={'font-bold fs-16'}>{t('CURRENT_SUBSCRIPTIONS')}</p>
                        <p className={'font-regular fs-16'}> {t('NUMBER')} <span className={'font-bold'}>4500</span></p>
                    </div>
                    <div className={'flex items-center'}>
                        <span className={'font-medium pe-3'}>1</span>
                        <Progress value={70} color="yellow"/>
                    </div>
                </div>
            </div>
            <div className={'card-slider'}>
                <div className="card-content">
                    <div className={'flex justify-between flex-row-reverse'} >
                        <p className={'font-bold fs-16'}>{t('CURRENT_SUBSCRIPTIONS')}</p>
                        <p className={'font-regular fs-16'}> {t('NUMBER')} <span className={'font-bold'}>4500</span></p>
                    </div>
                    <div className={'flex items-center'}>
                        <span className={'font-medium pe-3'}>1</span>
                        <Progress value={70} color="yellow"/>
                    </div>
                </div>
            </div>
            <div className={'card-slider'}>
                <div className="card-content">
                    <div className={'flex justify-between flex-row-reverse'} >
                        <p className={'font-bold fs-16'}>{t('CURRENT_SUBSCRIPTIONS')}</p>
                        <p className={'font-regular fs-16'}> {t('NUMBER')} <span className={'font-bold'}>4500</span></p>
                    </div>
                    <div className={'flex items-center'}>
                        <span className={'font-medium pe-3'}>1</span>
                        <Progress value={70} color="yellow"/>
                    </div>
                </div>
            </div>
        </Slider>
    </div>
  );
};

export default ProductSalesRate;
