import {useTranslation} from "react-i18next";
import React from "react";
import ScreanTitle from "../../../../../components/screanTitle/screanTitle";
import PrintButton from "../../../../../components/filterButton/printBtn";
import UploadButton from "../../../../../components/filterButton/uploadBtn";
import InputField from "../../../../../components/fields/InputField";
import CustomSelect from "../../../../../components/select/select";
import TextArea from "../../../../../components/fields/textArea";


const ReminderForAbandonedBaskets = () => {
    const { t } = useTranslation();
    type Option = typeof sampleOptions[0];
    const handleChange = (selectedValues: Option[]) => {
        console.log(selectedValues);
    };
    const sampleOptions = [
        {label: "option 1", value: "option-1"},
        {label: "option 2", value: "option-2"},
        {label: "option 3", value: "option-3"},
    ];
    return (
        <div>
            <div>
                <ScreanTitle title={t('REMINDER_OF_ABANDONED_BASKETS_SCREEN')}></ScreanTitle>
                <div className={'flex justify-between pb-3'}>
                    <div className={''}>
                        <button className="btn btn-primary mx-3 flex items-center">
                            <span className={'pe-3'}>{t('CREATE_REMINDER')}</span>
                            <em className={'icon-plus'}></em>
                        </button>
                    </div>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                    </div>
                </div>
            </div>
            <div  className={'bg-white border-radius-40 py-4 ps-2'}>
                        <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('REMINDER_OF_ABANDONED_BASKETS_SCREEN2')}</p>
                        <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4 px-4 pb-3">
                            <div className={'lg:col-span-2'}>
                                <InputField
                                    label={t('TIME_LEAVE_THE_BASKET')}
                                    placeholder={t('TIME_LEAVE_THE_BASKET')}
                                    id="NAME_ARABIC"
                                    type="text"
                                    fixedValue={<span className={'font-regular text-gray-600 fs-14'}>{t('HOUR')}</span>}
                                />
                            </div>
                            <div className={'lg:col-span-2'}>
                                <InputField
                                    label={t('TOTAL_BASKET_AMOUNT')}
                                    placeholder={t('TOTAL_BASKET_AMOUNT')}
                                    id="NAME_IN_ENGLISH"
                                    type="text"
                                    fixedValue={<span className={'font-regular text-gray-600 fs-14'}>{t('RIEL')}</span>}
                                />
                            </div>
                            <div>
                                <CustomSelect  onChange={handleChange}
                                    label={t('FIXED_AMOUNT_FROM_TOTAL_BASKET')}
                                    placeholder={t('FIXED_AMOUNT_FROM_TOTAL_BASKET')}
                                    options={sampleOptions}
                                />
                            </div>
                            <div>
                                <InputField
                                    label={t('SELECTED_AMOUNT_PERCENTAGE')}
                                    placeholder={t('SELECTED_AMOUNT_PERCENTAGE')}
                                    id="ORIGINAL_AMOUNT"
                                    type="text"
                                />
                            </div>
                            <div>
                                <CustomSelect  onChange={handleChange}
                                    label={t('DISCOUNT_VALIDITY_PERIOD')}
                                    placeholder={t('DISCOUNT_VALIDITY_PERIOD')}
                                    options={sampleOptions}
                                />
                            </div>
                            <div>
                                <CustomSelect  onChange={handleChange}
                                    label={t('TRANSMITTER_CHANNEL')}
                                    placeholder={t('TRANSMITTER_CHANNEL')}
                                    options={sampleOptions}
                                />
                            </div>
                            <div className={'lg:col-span-4'}>
                                <TextArea
                                    label={t('MESSAGE_BODY')}
                                    placeholder={t('TRANSMITTER_CHANNEL')}
                                />
                            </div>
                        </div>
                        <div className={'flex justify-center py-4'}>
                            <button className="btn btn-secondary mx-3">
                                <em className={'icon-check'}></em>
                                <span className={'ps-3'}>{t('SAVE')}</span>
                            </button>
                            <button className="btn btn-outline-secondary mx-3">
                                <em className={'icon-close'}></em>
                                <span className={'ps-3'}>{t('CLOSE')}</span>
                            </button>
                        </div>
                    </div>
        </div>
    );
};


export default ReminderForAbandonedBaskets;
