const Switch = (props: {
  extra?: string;
  color?:
    | "primary"
    | "secondary"
  [x: string]: any;
}) => {
  const { extra, color, ...rest } = props;
  return (
    <input
      type="checkbox"
      className={`customSwitch relative h-[27px] w-[68px]  appearance-none rounded-[13px] bg-primary-500 outline-none transition duration-[0.5s]X 
      before:absolute before:top-[50%] before:h-5 before:w-5 before:translate-x-[-5px] before:translate-y-[-50%] before:rounded-[20px]
      before:bg-white before:shadow-[0_2px_5px_rgba(0,_0,_0,_.2)] before:transition before:content-[""]
      checked:before:translate-x-[-43px] hover:cursor-pointer
      dark:bg-white/5 ${
        color === "primary"
          ? "checked:bg-gray-600/20"
          : color === "secondary"
          ? "checked:bg-secondary-500"
          : "checked:bg-gray-600/20"
      } ${extra}`}
      name="weekly"
      {...rest}
    />
  );
};

export default Switch;
