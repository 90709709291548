import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import Popover from "../../../../components/popover";
import InputField from "../../../../components/fields/InputField";
import React from "react";
import TextArea from "../../../../components/fields/textArea";
import CustomDatePicker from "../../../../components/fields/DatePicker";
import Upload from "../../../../components/customUpload/Upload";
import Checkbox from "../../../../components/checkbox";
import CustomSelect from "../../../../components/select/select";
import CardMenu from "components/card/CardMenu";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import PrintButton from "../../../../components/filterButton/printBtn";
    type Option = typeof sampleOptions[0];
    const handleChange = (selectedValues: Option[]) => {
        console.log(selectedValues);
    };
    const sampleOptions = [
    {label: "option 1", value: "option-1"},
    {label: "option 2", value: "option-2"},
    {label: "option 3", value: "option-3"},
]
;const QUANTITY = [
    {label: "عدد محدد", value: "option-1"},
    {label: "عدد مفتوح", value: "option-2"},
];

const AddCoupons = () => {
    const { t } = useTranslation();
    return (
        <div>
            <div>
                <ScreanTitle title={t('ADD_DISCOUNT_COUPONS_SCREEN')}></ScreanTitle>
                <div className={'flex justify-between pb-3'}>
                    <div>
                        <button className="btn btn-primary mx-3 flex items-center">
                            <span className={'pe-3'}>{t('ADD_COUPONS')}</span>
                            <em className={'icon-plus'}></em>
                        </button>
                    </div>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                        <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    فلتر 1
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 2
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 3
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 4
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="white-card h-full w-full mt-1">
                <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('ADD_COUPONS')}</p>
                <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pb-3">
                    <div>
                        <InputField
                            label={t('NOM_COUPONS_ARABIC_2')}
                            placeholder={t('NOM_COUPONS_ARABIC_2')}
                            id="NOM_COUPONS_ARABIC"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('NOM_COUPONS_ENGLISH_2')}
                            placeholder={t('NOM_COUPONS_ENGLISH_2')}
                            id="NOM_COUPONS_ENGLISH"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('COUPONS_CODE')}
                            placeholder={t('COUPONS_CODE')}
                            id="COUPONS_CODE"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('COUPONS_STORE_LINK')}
                            placeholder={t('COUPONS_STORE_LINK')}
                            id="COUPONS_STORE_LINK"
                            type="text"
                        />
                    </div>
                    <div className={'col-span-2'}>
                        <TextArea
                            label={t('TERMS_AND_CONDITION_ARABIC')}
                            placeholder={t('TERMS_AND_CONDITION_ARABIC')}
                            id="TERMS_AND_CONDITION_ARABIC"
                        />
                    </div>
                    <div className={'col-span-2'}>
                        <TextArea
                            label={t('TERMS_AND_CONDITION_ENGLISH')}
                            placeholder={t('TERMS_AND_CONDITION_ENGLISH')}
                            id="TERMS_AND_CONDITION_ENGLISH"
                        />
                    </div>
                    <div>
                        <CustomSelect  onChange={handleChange}
                            label={t('COUNTRY')}
                            placeholder={t('COUNTRY')}
                            options={sampleOptions}
                        />
                    </div>
                    <div>
                        <CustomSelect  onChange={handleChange}
                            label={t('REGION')}
                            placeholder={t('REGION')}
                            options={sampleOptions}
                        />
                    </div>
                    <div>
                        <CustomSelect  onChange={handleChange}
                            label={t('CITY')}
                            placeholder={t('CITY')}
                            options={sampleOptions}
                        />
                    </div>
                    <div>
                        <CustomSelect  onChange={handleChange}
                            label={t('COUNTRY')}
                            placeholder={t('QUANTITY')}
                            options={QUANTITY}
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('DEALER_NAME')}
                            placeholder={t('DEALER_NAME')}
                            id="DEALER_NAME"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('BRANCH_NAME')}
                            placeholder={t('BRANCH_NAME')}
                            id="BRANCH_NAME"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('NAME_OF_ADMINISTRATOR')}
                            placeholder={t('NAME_OF_ADMINISTRATOR')}
                            id="NAME_OF_ADMINISTRATOR"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('ADMIN_PHONE')}
                            placeholder={t('ADMIN_PHONE')}
                            id="ADMIN_PHONE"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('CENTER_PHONE')}
                            placeholder={t('CENTER_PHONE')}
                            id="CENTER_PHONE"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('BRANCH_PHONE')}
                            placeholder={t('BRANCH_PHONE')}
                            id="BRANCH_PHONE"
                            type="text"
                        />
                    </div>
                    <div>
                        <CustomDatePicker label={t('VOUCHER_START_DATE')} placeholder={t('VOUCHER_START_DATE')} />
                    </div>
                    <div>
                        <CustomDatePicker label={t('COUPON_END_DATE')} placeholder={t('COUPON_END_DATE')} />
                    </div>
                    <div>
                        <InputField
                            label={t('NAME_OF_CONTRACTING_AGENT')}
                            placeholder={t('NAME_OF_CONTRACTING_AGENT')}
                            id="NAME_OF_CONTRACTING_AGENT"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('THE_NUMBER_IN_THE_ORDER_IN_WHICH_THE_SECTION_APPEARS')}
                            placeholder={t('THE_NUMBER_IN_THE_ORDER_IN_WHICH_THE_SECTION_APPEARS')}
                            id="THE_NUMBER_IN_THE_ORDER_IN_WHICH_THE_SECTION_APPEARS"
                            type="text"
                        />
                    </div>
                    <div>
                        <CustomSelect  onChange={handleChange}
                            label={t('COUPON_SECTION')}
                            placeholder={t('COUPON_SECTION')}
                            options={sampleOptions}
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('THE_NUMBER_IN_THE_ORDER_TO_APPEAR_FOR_THE_RATING')}
                            placeholder={t('THE_NUMBER_IN_THE_ORDER_TO_APPEAR_FOR_THE_RATING')}
                            id="THE_NUMBER_IN_THE_ORDER_TO_APPEAR_FOR_THE_RATING"
                            type="text"
                        />
                    </div>
                    <div>
                        <Upload  label={t('DOWNLOAD_CENTER_LOGO')} id={'DOWNLOAD_CENTER_LOGO'}/>
                    </div>
                    <div>
                        <Upload  label={t('UPLOAD_COUPON_IMAGE')} id={'UPLOAD_COUPON_IMAGE'}/>
                    </div>
                    <div className={'col-span-2 flex justify-between items-center'}>
                        <div>
                            <Checkbox id={'FREE'} color="yellow" label={t('FREE')} />
                        </div>
                        <div>
                            <Checkbox id={'DISCOUNT'} color="yellow" label={t('DISCOUNT')} />
                        </div>
                        <div>
                            <Checkbox id={'AMOUNT_DEDUCTED'} color="yellow" label={t('AMOUNT_DEDUCTED')} />
                        </div>
                        <div>
                            <Checkbox id={'SERVICE_PRICE'} color="yellow" label={t('SERVICE_PRICE')} />
                        </div>
                    </div>
                    <div>
                        <InputField
                            label={t('VOUCHER_VALUE_BEFORE_DISCOUNT')}
                            placeholder={t('VOUCHER_VALUE_BEFORE_DISCOUNT')}
                            id="VOUCHER_VALUE_BEFORE_DISCOUNT"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('VOUCHER_VALUE_AFTER_DISCOUNT')}
                            placeholder={t('VOUCHER_VALUE_AFTER_DISCOUNT')}
                            id="VOUCHER_VALUE_AFTER_DISCOUNT"
                            type="text"
                        />
                    </div>
                    <div>
                        <CustomSelect  onChange={handleChange}
                            label={t('PAYMENT_REDUCTION_PERCENTAGE')}
                            placeholder={t('PAYMENT_REDUCTION_PERCENTAGE')}
                            options={sampleOptions}
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('BROWSE_PROCESS')}
                            placeholder={t('BROWSE_PROCESS')}
                            id="BROWSE_PROCESS"
                            type="text"
                        />
                    </div>
                </div>
                <div>
                    <div className={'flex justify-center py-5 flex-wrap'}>
                        <button className="btn btn-primary mx-3">
                            <em className={'icon-pen'}></em>
                            <span className={'ps-3'}>{t('AMENDMENT')}</span>
                        </button>
                        <button className="btn btn-secondary mx-3">
                            <em className={'icon-check'}></em>
                            <span className={'ps-3'}>{t('SAVE')}</span>
                        </button>
                        <button className="btn btn-light mx-3">
                            <em className={'icon-check'}></em>
                            <span className={'ps-3'}>{t('SAVE_ADD')}</span>
                        </button>
                        <button className="btn btn-outline-secondary mx-3">
                            <em className={'icon-close'}></em>
                            <span className={'ps-3'}>{t('CLOSE')}</span>
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
};


export default AddCoupons;
