import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import React from "react";
import CustomSelect from "../../../../../components/select/select";
import InputField from "../../../../../components/fields/InputField";
import {useTranslation} from "react-i18next";
import Datepicker from "react-tailwindcss-datepicker";
import CustomDatePicker from "../../../../../components/fields/DatePicker";

interface NewOrderModalProps {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
}
    type Option = typeof sampleOptions[0];
    const handleChange = (selectedValues: Option[]) => {
        console.log(selectedValues);
    };
    const sampleOptions = [
    { label: "option 1", value: "option-1" },
    { label: "option 2", value: "option-2" },
    { label: "option 3", value: "option-3" },
];

const AffiliateMarketingModal: React.FC<NewOrderModalProps> = ({ isOpen, onOpen, onClose }) => {
    const { t } = useTranslation();
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay className="bg-[#181C30] !opacity-80" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] lg:min-w-[680px] !max-w-[95%]">
                     <span className={'close-modal cursor-pointer w-[35px] h-[35px] bg-red-500 dark:bg-navy-200 flex items-center justify-center'}   onClick={onClose}>
                       <em className={'icon-close fs-20 text-white dark:text-white'}></em>
                     </span>
                    <ModalBody>
                        <div className="modal-body bg-white border-radius-40 px-[15px] md:px-[30px] pt-[35px] pb-[40px] flex flex-col !z-[1004]">
                            <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('MARKETER_COMMISSION_REVIEW')}</p>
                            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5 pb-3">
                                <div className={'md:col-span-2'}>
                                    <CustomSelect  onChange={handleChange}
                                        label={t('PERIOD_TYPE')}
                                        placeholder={t('PERIOD_TYPE')}
                                        options={sampleOptions}
                                    />
                                </div>
                                <div className={'md:col-span-2'}>
                                    <CustomDatePicker label={t('DATE')} placeholder={t('VOUCHER_START_DATE')} />
                                </div>
                                <div className={'col-span-1 flex items-end'}>
                                    <button className="btn btn-secondary mx-3">
                                        <span>{t('FILTERING')}</span>
                                    </button>
                                </div>
                            </div>
                            <div className="flex justify-center gap-8 pt-5">
                                <div className={'sm-card md:min-w-[200px]'}>
                                    <p className={'font-bold fs-17 text-center'}>{t('USAGE_TIMES')}</p>
                                    <p className={'font-bold fs-32 text-center value'}>136</p>
                                </div>
                                <div className={'sm-card md:min-w-[200px]'}>
                                    <p className={'font-bold fs-17 text-center'}>{t('TOTAL_PROFITS')}</p>
                                    <p className={'font-bold fs-32 text-center value'}>13160</p>
                                </div>
                                <div className={'sm-card md:min-w-[200px]'}>
                                    <p className={'font-bold fs-17 text-center'}>{t('TOTAL_SALES')}</p>
                                    <p className={'font-bold fs-32 text-center value'}>136</p>
                                </div>
                            </div>
                            <div className={'flex justify-center py-4'}>
                                <button className="btn btn-outline-secondary">
                                    <em className={'icon-close fs-13'}></em>
                                    <span className={'ps-3'}>{t('CANCEL')}</span>
                                </button>
                            </div>
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
    ); };
export default AffiliateMarketingModal;