import React from "react";
import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import Switch from "../../../../components/switch";
import avatar2 from "../../../../assets/img/avatars/avatar2.png";
import {useNavigate} from "react-router-dom";
import CardMenu from "components/card/CardMenu";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import CustomTable from "../../../../components/customTable/CustomTable";
import client from "../../../../assets/img/prod/Group1.png";
import QrCode from "../../../../assets/img/prod/qr.png";

const EmployeeManagement = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const goToEmployeeDetails = () => {
        navigate('/private/user-management/employee-management/employee-details');
    };
    const dataTable = [
        { label: "MEMBERSHIPS", headerClass:'px-5',  content:   <div className={'flex'}>
                                                <div className={'flex items-center h-full pe-5'}>
                                                    <span className={'font-regular fs-15 text-gray-600'}> 1</span>
                                                </div>
                                                <img src={avatar2} alt="avatar4" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>
                                            </div>
             , colSpan: 1 },
        { label: "NAME_OF_EMPLOYEE_OWNER", content: "بشار بعاج", colSpan: 2 },
        { label: "CITY", content: "الرياض", colSpan: 1 },
        { label: "PHONE", content: "0563231328", colSpan: 1 },
        { label: "MAIL", content:'tkaful@gmail.com', colSpan: 2 },
        { label: "JOB", content:'مدير مبيعات', colSpan: 1 },
        { label: "ON_OFF", content: <Switch />, colSpan: 1 },
        { label: "AMENDMENT", content: <button className={'action-btn border-primary bg-white'} onClick={goToEmployeeDetails}>
                <span className={'icon-pen fs-16 text-primary-500'}/></button>, colSpan: 1 },

        { label: "REVIEW", content: <button className={'action-btn border-primary bg-white'} onClick={goToEmployeeDetails}>
                <span className={'icon-search-normal fs-16 text-primary-500'}> <span className="path1"/> <span className="path2"/></span></button>, colSpan: 1 },


    ];


    const redirectToAddEmployee = () => {
        navigate('/private/user-management/employee-management/add-employee');
    };
    return (
        <div>
            <div>
                <ScreanTitle title={t('EMPLOYEES_SCREEN_IN_USER_MANAGEMENT')}></ScreanTitle>
                <div className={'flex justify-between pb-3'}>
                    <div>
                        <button onClick={redirectToAddEmployee} className="btn btn-primary mx-3 flex items-center">
                            <span className={'pe-3'}>{t('ADD_A_NEW_EMPLOYEE')}</span>
                            <em className={'icon-plus'}></em>
                        </button>
                    </div>
                    <div className={'flex'}>
                        <button className="action-btn-lg primary  me-3">
                                <span className="icon-printer fs-25">
                                    <span className="path1 before:text-gray-600"></span>
                                    <span className="path2 before:text-gray-600!important"></span>
                                </span>
                        </button>
                        <UploadButton/>
                         <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    فلتر 1
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 2
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 3
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 4
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-1 mt-[-16px]">
                <div className="white-card  h-full w-full mt-5">
                    <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('EMPLOYEES_SCREEN_IN_USER_MANAGEMENT')}</p>
                    <div className={'steppedTable pt-3'}>
                        <CustomTable data={dataTable} numColumns="11" />
                        <CustomTable data={dataTable} numColumns="11" />
                        <CustomTable data={dataTable} numColumns="11" />
                    </div>
                </div>
            </div>
        </div>
  );
};


export default EmployeeManagement;
