import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import React from "react";
import CustomTable from "../../../../components/customTable/CustomTable";
import {useDisclosure} from "@chakra-ui/hooks";
import AddBankModal from "./components/AddBankModal";

const BankManagement = () => {
    const { t } = useTranslation();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const openModal = () => {
        onOpen();
    };
    const data = [
        { label: "NAME", content: "البنك السعودي للإستثمار", colSpan: 5 },
        { label: "AMENDMENT", content: <button className={'action-btn border-primary bg-white'} >
                <span className={'icon-pen fs-16 text-primary-500'}> <span className="path1"/> <span className="path2"/></span></button>, colSpan: 5},
        { label: "DELETE", content: <button className={'action-btn border-primary bg-white'} >
                <span className={'icon-trash fs-16 text-primary-500'}> <span className="path1"/> <span className="path2"/></span></button>, colSpan: 1 },

    ];
    return (
        <div>
            <div>
                <ScreanTitle title={t('BANK_MANAGEMENT_SCREEN')}></ScreanTitle>
                <div className={'flex justify-between pb-3'}>
                    <div>
                        <button className="btn btn-primary mx-3 flex items-center" onClick={openModal}>
                            <span className={'pe-3'}>{t('ADD_BANK')}</span>
                            <em className={'icon-plus'}></em>
                        </button>
                    </div>
                </div>
            </div>
            <div className="white-card h-full w-full mt-1">
                <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('BANKS')}</p>
                <div className="steppedTable">
                    <CustomTable data={data} numColumns="11" />
                    <CustomTable data={data} numColumns="11" />
                    <CustomTable data={data} numColumns="11" />
                    <CustomTable data={data} numColumns="11" />
                </div>
            </div>
            <AddBankModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
        </div>
    );
};


export default BankManagement;
