import {useTranslation} from "react-i18next";
import React from "react";
import ScreanTitle from "../../../../../components/screanTitle/screanTitle";

import produit from "../../../../../assets/img/prod/image 4.png";
import slide from "../../../../../assets/img/prod/image 7.png";

const ViewOffer = () => {
    const { t } = useTranslation();
    return (
        <div>
            <div>
                <ScreanTitle title={t('SCREEN_REVIEW_OF_A_DISPLAY_OF_THE_MERCHANTS_OFFERS')}></ScreanTitle>
            </div>

            <div className={'white-card pb-3 pt-1 mt-5'}>
                <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('TIM_HORTONS_ARABIA_COMPANY')}</p>
                <div className="mt-4 grid grid-cols-1  md:grid-cols-2 xxl-grid-cols-15 p-4 pb-3 dashed-border">
                    <div className={' 2xl:col-span-3'}>
                      <div className={'flex justify-center'}>
                          <img src={produit} alt="sidebarImg" className={'h-[133px] cursor-pointer'}/>
                      </div>
                        <p className={'fs-16 text-center pt-3'}> Timhortons@gmail.com </p>
                    </div>
                    <div className={' 2xl:col-span-3 flex items-center'}>
                        <div className="flex md:border-right-1  border-l border-[#cecece] justify-center items-center px-3 w-full">
                            <div className={'flex justify-center items-center'}>
                                <div>
                                    <p className={'fs-20 text-center font-medium'}> {t('OFFER')} </p>
                                    <p className={'fs-20 text-center font-bold text-primary-500'}>
                                        اشتري 1 واحصل على 1
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={' 2xl:col-span-4 flex items-center'}>
                        <div className={'flex md:border-right-1  border-l border-[#cecece] justify-center items-center px-3 w-full'}>
                              <div>
                                  <p className={'fs-20 text-center font-medium'}> {t('NUMBER_OF_BRANCHES_WHERE_THE_OFFER_IS_ACTIVATED')} </p>
                                  <p className={'fs-20 text-center font-bold text-primary-500'}>
                                    4
                                  </p>
                              </div>
                            </div>
                    </div>
                    <div className={' 2xl:col-span-3 flex items-center'}>
                        <div className={'flex md:border-right-1  border-l border-[#cecece] justify-center items-center px-3 w-full'}>
                                <div>
                                    <p className={'fs-20 text-center font-medium'}> {t('NUMBER_OF_ACTIVATED_OFFERS')} </p>
                                    <p className={'fs-20 text-center font-bold text-primary-500'}>
                                        1
                                    </p>
                                </div>
                            </div>
                    </div>
                    <div className={'2xl:col-span-2 flex items-center justify-center'}>
                          <div className={'flex justify-center items-center w-full'}>
                                <div>
                                    <p className={'fs-20 text-center font-medium'}> {t('PRICE')} </p>
                                    <p className={'fs-20 text-center font-bold text-primary-500'}>
                                        18 ريال
                                    </p>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <div className={'white-card pb-3 pt-1 mt-5'}>
                <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('NUMBER_OF_BRANCHES_WHERE_THE_OFFER_IS_ACTIVATED')} 4</p>

                <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-3 xxl-grid-cols-6 p-4 pb-3 dashed-border">
                    <div>
                        <p className={'fs-15 font-regular'}> {t('DISPLAY_NUMBER')} </p>
                        <p className={'fs-16 font-regular text-[#808080]'}>
                            1145
                        </p>
                    </div>
                    <div>
                        <p className={'fs-15 font-regular'}> {t('COUNTRY')} </p>
                        <p className={'fs-16 font-regular text-[#808080]'}>
                            المملكة العربية السعودية
                        </p>
                    </div>
                    <div>
                        <p className={'fs-15 font-regular'}> {t('CITY')} </p>
                        <p className={'fs-16 font-regular text-[#808080]'}>
                            الرياض
                        </p>
                    </div>
                    <div>
                        <p className={'fs-15 font-regular'}> {t('DISPLAY_TYPE')} </p>
                        <p className={'fs-16 font-regular text-[#808080]'}>
                            قسيمة شرائية
                        </p>
                    </div>
                    <div>
                        <p className={'fs-15 font-regular'}> {t('START_DATE')} </p>
                        <p className={'fs-16 font-regular text-[#808080]'}>
                            1-1-2023
                        </p>
                    </div>
                    <div>
                        <p className={'fs-15 font-regular'}> {t('EXPIRY_DATE')} </p>
                        <p className={'fs-16 font-regular text-[#808080]'}>
                            1-1-2023
                        </p>
                    </div>

                </div>
                <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2   pb-3">
                    <div className={' dashed-border p-4'}>
                        <p className={'fs-19 font-bold text-primary-500 text-center'}> {t('THE_MAIN_DISPLAY_IMAGE')} </p>
                     <div className="flex justify-center pt-3">
                         <img src={produit} alt="sidebarImg" className={'h-[133px] cursor-pointer'}/>
                     </div>
                        <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2   pb-3">
                            <div className={'md:grid-cols-1'}>
                                <p className={'ps-5 pb-2 fs-15 font-regular'}>
                                    {t('DISPLAY_NAME')}
                                </p>
                                <div className={'dashed-border-sm p-3'}>
                                    <p className={'fs-16 font-regular'}>
                                        عرض بوفيه مفتوح ب 199 ريال
                                    </p>
                                </div>
                            </div>
                            <div className={'md:grid-cols-1'}>
                                <p className={'ps-5 pb-2 fs-15 font-regular'}>
                                    {t('DISPLAY_FEATURES')}
                                </p>
                                <div className={'dashed-border-sm p-3'}>
                                    <p className={'fs-16 font-regular'}>
                                        عرض بوفيه مفتوح ب 199 ريال
                                    </p>
                                </div>
                            </div>
                            <div className={'md:grid-cols-1'}>
                                <p className={'ps-5 pb-2 fs-15 font-regular'}>
                                    {t('OFFER_TERMS_AND_CONDITIONS')}
                                </p>
                                <div className={'dashed-border-sm p-3'}>
                                    <p className={'fs-16 font-regular'}>
                                        عرض بوفيه مفتوح ب 199 ريال
                                    </p>
                                </div>
                            </div>
                            <div className={'md:grid-cols-1'}>
                                <p className={'ps-5 pb-2 fs-15 font-regular'}>
                                    {t('OFFER_PRICE_BEFORE_DISCOUNT')}
                                </p>
                                <div className={'dashed-border-sm p-3'}>
                                    <p className={'fs-16 font-regular'}>
                                        250 ريال
                                    </p>
                                </div>
                            </div>
                            <div className={'md:grid-cols-1'}>
                                <p className={'ps-5 pb-2 fs-15 font-regular'}>
                                    {t('OFFER_START_DATE')}
                                </p>
                                <div className={'dashed-border-sm p-3'}>
                                    <p className={'fs-16 font-regular'}>
                                        1-1-2023
                                    </p>
                                </div>
                            </div>
                            <div className={'md:grid-cols-1'}>
                                <p className={'ps-5 pb-2 fs-15 font-regular'}>
                                    {t('TOTAL_NUMBER_OF_OFFERS')}
                                </p>
                                <div className={'dashed-border-sm p-3'}>
                                    <p className={'fs-16 font-regular'}>
                                        1000
                                    </p>
                                </div>
                            </div>
                            <div className={'md:grid-cols-1'}>
                                <p className={'ps-5 pb-2 fs-15 font-regular'}>
                                    {t('عدد العروض المباعة')}
                                </p>
                                <div className={'dashed-border-sm p-3'}>
                                    <p className={'fs-16 font-regular'}>
                                        99
                                    </p>
                                </div>
                            </div>


                        </div>

                    </div>
                    <div className={' dashed-border p-4'}>
                        <p className={'fs-19 font-bold text-primary-500 text-center'}> {t('SLIDE_PICTURES')} </p>
                     <div className="flex justify-center pt-3">
                         <img src={slide} alt="sidebarImg" className={'h-[133px] cursor-pointer'}/>
                     </div>
                        <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2   pb-3">
                            <div className={'md:grid-cols-1'}>
                                <p className={'ps-5 pb-2 fs-15 font-regular'}>
                                    {t('PRESENTATION_FEATURES_IN_ENGLISH')}
                                </p>
                                <div className={'dashed-border-sm p-3'}>
                                    <p className={'fs-16 font-regular'}>
                                        عرض بوفيه مفتوح ب 199 ريال
                                    </p>
                                </div>
                            </div>
                            <div className={'md:grid-cols-1'}>
                                <p className={'ps-5 pb-2 fs-15 font-regular'}>
                                    {t('PRESENTATION_FEATURES_IN_ENGLISH')}
                                </p>
                                <div className={'dashed-border-sm p-3'}>
                                    <p className={'fs-16 font-regular'}>
                                        عرض بوفيه مفتوح ب 199 ريال
                                    </p>
                                </div>
                            </div>
                            <div className={'md:grid-cols-1'}>
                                <p className={'ps-5 pb-2 fs-15 font-regular'}>
                                    {t('TERMS_AND_CONDITIONS_OF_THE_OFFER_IN_ENGLISH')}
                                </p>
                                <div className={'dashed-border-sm p-3'}>
                                    <p className={'fs-16 font-regular'}>
                                        عرض بوفيه مفتوح ب 199 ريال
                                    </p>
                                </div>
                            </div>
                            <div className={'md:grid-cols-1'}>
                                <p className={'ps-5 pb-2 fs-15 font-regular'}>
                                    {t('OFFER_PRICE_AFTER_DISCOUNT')}
                                </p>
                                <div className={'dashed-border-sm p-3'}>
                                    <p className={'fs-16 font-regular'}>
                                        250 ريال
                                    </p>
                                </div>
                            </div>
                            <div className={'md:grid-cols-1'}>
                                <p className={'ps-5 pb-2 fs-15 font-regular'}>
                                    {t('OFFER_END_DATE')}
                                </p>
                                <div className={'dashed-border-sm p-3'}>
                                    <p className={'fs-16 font-regular'}>
                                        1-1-2023
                                    </p>
                                </div>
                            </div>
                            <div className={'md:grid-cols-2'}>
                                <p className={'ps-5 pb-2 fs-15 font-regular'}>
                                    {t('Number_of_activated_offers')}
                                </p>
                                <div className={'dashed-border-sm p-3'}>
                                    <p className={'fs-16 font-regular'}>
                                        1000
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
                <div className={'flex justify-center py-4'}>
                    <button className="btn btn-primary mx-3">
                        <em className={'icon-pen'}></em>
                        <span className={'ps-3'}>{t('AMENDMENT')}</span>
                    </button>
                    <button className="btn btn-secondary mx-3">
                        <em className={'icon-check'}></em>
                        <span className={'ps-3'}>{t('SAVE')}</span>
                    </button>
                    <button className="btn btn-outline-secondary mx-3">
                        <em className={'icon-close'}></em>
                        <span className={'ps-3'}>{t('CLOSE')}</span>
                    </button>
                </div>
            </div>
        </div>
    );
};


export default ViewOffer;
