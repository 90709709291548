import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../../components/screanTitle/screanTitle";
import React from "react";
import CardMenu from "../../../../../components/card/CardMenu";
import UploadButton from "../../../../../components/filterButton/uploadBtn";
import PrintButton from "../../../../../components/filterButton/printBtn";
import client from "../../../../../assets/img/prod/Group1.png";
import QrCode from "../../../../../assets/img/prod/qr.png";
import Switch from "../../../../../components/switch";
import CustomTable from "../../../../../components/customTable/CustomTable";
import CustomSelect from "../../../../../components/select/select";
import CustomDatePicker from "../../../../../components/fields/DatePicker";

const ServiceProviderPortfoliosDetails = () => {
    const { t } = useTranslation();

    const dataTable = [
        {content:<div className={'flex items-center h-full'}>
                <span className={'font-regular fs-15 text-gray-600'}> 1</span>
            </div>, colSpan: 1 },
        { label: "DEALER_QR", content: <img src={QrCode} alt="avatar4" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>, colSpan: 1 },
        { label: "DEALER_CODE", content: "25487", colSpan: 1 },
        { label: "DEALER", content: "شركة البيك", colSpan: 1 },
        { label: "COUPON_CODE", content: "116899", colSpan: 1 },
        { label: "BRANCH", content: "1", colSpan: 1 },
        { label: "CITY", content:'3', colSpan: 1 },
        { label: "OFFER_PRICE", content:'200 ريال', colSpan: 1 },
        { label: "ACTIVATION_DATE", content:'1-1-2023', colSpan: 1 },
        { label: "STATE", content:'مسددة', color:'green', colSpan: 1 },
        { label: "TRANSFER_DATE", content:'1-10-2023', colSpan: 1 },
        { label: "REVIEW", content: <button className={'action-btn border-primary bg-white'}>
                <span className={'icon-search-normal fs-16 text-primary-500'}> <span className="path1"/> <span className="path2"/></span></button>, colSpan: 1 },


    ];

    return (
        <div>
            <div>
                <ScreanTitle title={t('WALLET_DETAILS')}></ScreanTitle>
                <div className={'flex justify-between pb-3'}>
                    <div>
                        <button className="btn btn-primary mx-3 flex items-center">
                            <span className={'pe-3'}>{t('REPORTS')}</span>
                            <em className={'icon-plus'}></em>
                        </button>
                    </div>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                               <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    الكل
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب الاسم
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب العرض
                                </p>
                                <p className="text-black cursor-pointer">
                                    حسب المدينة
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="flex justify-center gap-8 pt-5 flex-wrap">
                    <div className={'sm-card md:min-w-[200px] mb-3 xl:mb-0'}>
                        <p className={'font-bold fs-17 text-center'}>{t('TOTAL_NUMBER_OF_COUPON_OFFERS')}</p>
                        <p className={'font-bold fs-32 text-center value'}>136</p>
                        <p className={'font-regular fs-15 text-center'}>{t('COUPON')}</p>
                    </div>
                    <div className={'sm-card md:min-w-[200px] mb-3 xl:mb-0'}>
                        <p className={'font-bold fs-17 text-center'}>{t('TOTAL_AMOUNTS_OF_ACTIVE_OFFERS')}</p>
                        <p className={'font-bold fs-32 text-center value'}>13160</p>
                        <p className={'font-regular fs-15 text-center'}>ريال </p>
                    </div>
                    <div className={'sm-card md:min-w-[200px] mb-3 xl:mb-0'}>
                        <p className={'font-bold fs-17 text-center'}>{t('TOTAL_AMOUNTS_DUE')}</p>
                        <p className={'font-bold fs-32 text-center value'}>13160</p>
                    </div>
                </div>
            <div className="white-card h-full w-full mt-6">
               <div className="flex items-center">
                   <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>شركة تيم هورتنز</p>
                   <p className={'px-5  font-bold border-l border-gray-400  fs-16'}>عدد العمليات 1233</p>
                   <p className={'ps-8 font-bold fs-16'}> الفروع 3 </p>
               </div>

                <div className="steppedTable pt-3">
                    <CustomTable data={dataTable} numColumns={'12'} />
                    <CustomTable data={dataTable} numColumns={'12'} />
                    <CustomTable data={dataTable} numColumns={'12'} />
                    <CustomTable data={dataTable} numColumns={'12'} />
                </div>
            </div>
            <div className="white-card h-full w-full mt-6">
                <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5 px-4 pb-6 py-5">

                    <div>
                        <p className={'font-regular fs-15 pb-2 ps-4'}>{t('TOTAL_NUMBER_OF_ACTIVATED_COUPONS')}</p>
                        <div className={'dashed-border py-2 px-4' }>
                            <span>120</span>
                        </div>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-2 ps-4'}>{t('VOUCHER_VALUE')}</p>
                        <div className={'dashed-border py-2 px-4' }>
                            <span>32400</span>
                        </div>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-2 ps-4'}>{t('TOTAL_AMOUNTS_PAID')}</p>
                        <div className={'dashed-border py-2 px-4' }>
                            <span>120</span>
                        </div>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-2 ps-4'}>{t('TOTAL_VALUE_OF_UNPAID_VOUCHERS')}</p>
                        <div className={'dashed-border py-2 px-4' }>
                            <span>310210</span>
                        </div>
                    </div>
                    <div>
                        <p className={'font-regular fs-15 pb-2 ps-4'}>{t('TOTAL_DUE_TO_THE_MERCHANT')}</p>
                        <div className={'dashed-border py-2 px-4' }>
                            <span>310210</span>
                        </div>
                    </div>

                </div>
                <div className={'flex justify-center py-4'}>
                    <button className="btn btn-primary mx-3">
                        <em className={'icon-download'}></em>
                        <span className={'ps-3'}>{t('DOWNLOAD')}</span>
                    </button>
                    <button className="btn btn-secondary mx-3">
                        <em className={'icon-check'}></em>
                        <span className={'ps-3'}>{t('SAVE')}</span>
                    </button>
                    <button className="btn btn-light mx-3">
                        <em className={'icon-printer text-gray-600'}></em>
                        <span className={'ps-3'}>{t('PRINT')}</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default ServiceProviderPortfoliosDetails;
