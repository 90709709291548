import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import InputField from "../../../../components/fields/InputField";
import React from "react";
import CustomSelect from "../../../../components/select/select";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import PrintButton from "../../../../components/filterButton/printBtn";
import Checkbox from "../../../../components/checkbox";
import CustomDatePicker from "../../../../components/fields/DatePicker";
import CustomTable from "../../../../components/customTable/CustomTable";
import Switch from "../../../../components/switch";
import {useDisclosure} from "@chakra-ui/hooks";
import AffiliateMarketingModal from "./components/AffiliateMarketingModal";

const AffiliateMarketing = () => {
    const { t } = useTranslation();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const openModal = () => {
        onOpen();
    };
    type Option = typeof sampleOptions[0];
    const handleChange = (selectedValues: Option[]) => {
        console.log(selectedValues);
    };
    const sampleOptions = [
        {label: "option 1", value: "option-1"},
        {label: "option 2", value: "option-2"},
        {label: "option 3", value: "option-3"},
    ];

    const data = [
        { label: "EMPLOYEE_NAME", content: "بشار بعاج", colSpan: 1 },
        { label: "MARKETING_LINK_NUMBER", content: " 110", colSpan: 1 },
        { label: "COMMISSION_TYPE", content: "مبلغ ثابت", colSpan: 1 },
        { label: "MARKETING_LINK_NUMBER", content:
            <div className="flex">
                <button className={'action-btn border-primary bg-white me-2'} >
                    <span className={'icon-copy fs-18 text-primary-500'}/>
                </button>
                <button className={'action-btn border-primary bg-white ms-2'} >
                    <span className={'icon-shared fs-16 text-primary-500'}/>
                </button>
            </div>
            , colSpan: 1 },
        { label: "NUMBER_OF_REQUESTS", content: " 10", colSpan: 1 },
        { label: "COMMISSION_DUE", content:'200 ريال', colSpan: 1 },
        { label: "REPORT", content:
            <button className={'action-btn border-primary bg-white me-2'} onClick={openModal}>
                <span className={'icon-search-normal fs-16 text-primary-500'}/>
            </button>  , colSpan: 1 },
        { label: "STOP_TEMPORARY", content: <Switch id="switch1"/>, colSpan: 1 },
    ];
    return (
        <div>
            <div>
                <ScreanTitle title={t('ADD_AFFILIATE_SCREEN')}></ScreanTitle>
                <div className={'flex justify-between pb-3'}>
                    <div>
                        <button className="btn btn-primary mx-3 flex items-center">
                            <span className={'pe-3'}>{t('ADD_AFFILIATE')}</span>
                            <em className={'icon-plus'}></em>
                        </button>
                    </div>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                    </div>
                </div>
            </div>
            <div>
                <div className={'white-card pb-3 pt-1'}>
                        <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('ADD_AFFILIATE')}</p>
                        <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pb-3">
                            <div>
                                <InputField
                                    label={t('MARKETERS_NAME')}
                                    placeholder={t('MARKETERS_NAME')}
                                    id="MARKETERS_NAME"
                                    type="text"
                                />
                            </div>
                            <div>
                                <InputField
                                    label={t('MAIL')}
                                    placeholder={t('MAIL')}
                                    id="MAIL"
                                    type="text"
                                />
                            </div>
                            <div>
                                <InputField
                                    label={t('CITY')}
                                    placeholder={t('CITY')}
                                    id="CITY"
                                    type="text"
                                />
                            </div>
                            <div>
                                <InputField
                                    label={t('ADDITIONAL_INFORMATION')}
                                    placeholder={t('ADDITIONAL_INFORMATION')}
                                    id="ADDITIONAL_INFORMATION"
                                    type="text"
                                />
                            </div>
                            <div>
                                <CustomSelect  onChange={handleChange}
                                    label={t('COMMISSION_TYPE')}
                                    placeholder={t('COMMISSION_TYPE')}
                                    options={sampleOptions}
                                />
                            </div>
                            <div>
                                <InputField
                                    label={t('COMMISSION_TYPE')}
                                    placeholder={t('COMMISSION_TYPE')}
                                    id="COMMISSION_TYPE"
                                    type="text"
                                />
                            </div>
                            <div>
                                <InputField
                                    label={t('COMMISSION_RATE')}
                                    placeholder={t('COMMISSION_RATE')}
                                    id="COMMISSION_RATE"
                                    type="text"
                                />
                            </div>
                            <div>
                                <CustomSelect  onChange={handleChange}
                                    label={t('SALES_MANAGER_NAME')}
                                    placeholder={t('SALES_MANAGER_NAME')}
                                    options={sampleOptions}
                                />
                            </div>

                            <div>
                                <p>{t('ALL_ORDERS')}</p>
                                <div className={'flex pt-5'}>
                                    <Checkbox label={t('ALL_ORDERS')} id={'ALL_ORDERS'} />
                                    <Checkbox label={t('SPECIFIC_NUMBER')} id={'SPECIFIC_NUMBER'} />
                                </div>
                            </div>
                            <div>
                                <InputField
                                    label={t('NUMBER_OF_REQUESTS_SPECIFIED')}
                                    placeholder={t('NUMBER_OF_REQUESTS_SPECIFIED')}
                                    id="NUMBER_OF_REQUESTS_SPECIFIED"
                                    type="text"
                                    fixedValue={<span className={'font-regular text-gray-600 fs-14'}>{t('ORDER')}</span>}
                                />
                            </div>
                            <div>
                                <CustomDatePicker label={t('START_DATE')} placeholder={t('START_DATE')} />
                            </div>
                            <div>
                                <CustomDatePicker label={t('EXPIRY_DATE')} placeholder={t('EXPIRY_DATE')} />
                            </div>
                        </div>
                        <div className={'flex justify-center pt-4 flex-wrap'}>
                            <button className="btn btn-primary mx-3 mb-2">
                                <em className={'icon-pen'}></em>
                                <span className={'ps-3'}>{t('AMENDMENT')}</span>
                            </button>
                            <button className="btn btn-secondary mx-3 mb-2">
                                <em className={'icon-check'}></em>
                                <span className={'ps-3'}>{t('SAVE')}</span>
                            </button>
                            <button className="btn btn-outline-secondary mx-3 mb-2">
                                <em className={'icon-close'}></em>
                                <span className={'ps-3'}>{t('CLOSE')}</span>
                            </button>
                        </div>
                    </div>
                <div className="flex pt-6 flex-wrap items-center pb-6">
                    <div className={'dashed-border flex-[1] py-6 px-4'}>
                        <p className={'font-bold fs-19'}>TAKFULALARABIAJKBFCDJSDKFLBVHSAGFSDFSDFVDFGKLGDFNJKKDFGB</p>
                    </div>
                    <button className="btn btn-primary me-3 ms-10 flex items-center">
                        <span className={'pe-3'}>{t('SHARE_LINK')}</span>
                        <em className={'icon-share'}></em>
                    </button>


                </div>
                <div className="white-card">
                    <div className={'steppedTable pt-3'}>
                        <CustomTable data={data} numColumns="8" />
                        <CustomTable data={data} numColumns="8" />
                        <CustomTable data={data} numColumns="8" />
                        <CustomTable data={data} numColumns="8" />

                    </div>
                </div>
            </div>
            <AffiliateMarketingModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
        </div>
    );
};


export default AffiliateMarketing;
