const Progress = (props: {
  value: number;
  showValue?: boolean;
  color?:
    "yellow"
    | "gray";
  width?: string;
}) => {
  const { value, color, width, showValue } = props;
  return (
      <>
        <div
            className={`progressBar h-3 flex ${
                width ? width : "w-full"
            } rounded-full bg-gray-100 my-3`}
        >
          <div
              className={`customProgressBar flex h-full items-center justify-center rounded-full ${
                  color === "yellow"
                      ? "grad-yellow"
                      : color === "gray"
                          ? "bg-gray-500"
                          : "bg-brand-500"
              }`}
              style={{ width: `${value}%` }}
          />

        </div>
        {showValue && (
            <span className="progress-value font-bold ps-3">
          {value}%
        </span>
        )}
      </>
  );
};

export default Progress;
