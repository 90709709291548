import React from "react";
import Select, { SelectProps } from 'react-dropdown-select';
import isRtl from "../../utils/langUtils";

interface Option {
    label: string;
    value: string;
}

interface CustomSelectProps extends Omit<SelectProps<Option>, 'values'> {
    label?: string;
    placeholder?: string;
    options: Option[];
    minWidth?: string;
    isDisabled?: boolean;
    required?: boolean;
    defaultValue?: Option[]; // Define defaultValue prop
}

const CustomSelect: React.FC<CustomSelectProps> = ({ label, required, placeholder, isDisabled, options, minWidth, defaultValue, ...props }) => {
    return (
        <div className={'CustomSelect dark:dark-mode dark:text-white'}>
            {label && <label className={'font-medium fs-15 ps-3 dark:text-white'}>{label}</label>}
            <Select
                options={options}
                searchable={false}
                className={`h-[47px] border-radius-10 custom-select pt-1 ${isDisabled ? 'disabled' : ''} w-200px w-[${minWidth}]`}
                placeholder={placeholder}
                required={required}
                disabled={isDisabled}
                direction= {isRtl ? 'rtl' : 'ltr'}
                onChange={(value) => console.log(value)}
                values={defaultValue} // Use defaultValue as values
                {...props} // Spread other props
            />
        </div>
    );
};

export default CustomSelect;
