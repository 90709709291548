import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { arSA } from 'date-fns/locale';
import {useTranslation} from "react-i18next";
interface CustomDatePickerProps {
    label?: string;
    placeholder?: string;
}
const CustomDatePicker: React.FC<CustomDatePickerProps> = ({ label, placeholder }) => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [locale, setLocale] = useState(null);
    const { i18n } = useTranslation();

    useEffect(() => {
        // Check if the current language is Arabic
        const isArabic = i18n.language === 'ar';
        // Set the locale based on the language
        setLocale(isArabic ? arSA : null);
    }, [i18n.language]);

    const handleChange = (date: React.SetStateAction<Date>) => {
        setSelectedDate(date);
    };

    return (
        <div className={'flex flex-col'}>
            {label && <label className={'font-medium fs-15 ps-3 dark:text-white'}>{label}</label>}
           <div className={'pt-1 w-full'}>
               <DatePicker  placeholderText={placeholder} selected={selectedDate} onChange={handleChange} locale={locale}/>
           </div>
        </div>

    );
};

export default CustomDatePicker;
