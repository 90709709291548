
import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import React from "react";
import CustomSelect from "../../../../components/select/select";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import PrintButton from "../../../../components/filterButton/printBtn";
import Switch from "../../../../components/switch";
import CustomTable from "../../../../components/customTable/CustomTable";
import Checkbox from "../../../../components/checkbox";
import CardMenu from "../../../../components/card/CardMenu";
import InputField from "../../../../components/fields/InputField";
import TextArea from "../../../../components/fields/textArea";
import CustomDatePicker from "../../../../components/fields/DatePicker";

const AddNewSpecialOffers = () => {
    const { t } = useTranslation();
    type Option = typeof sampleOptions[0];
    const handleChange = (selectedValues: Option[]) => {
        console.log(selectedValues);
    };
    const sampleOptions = [
        {label: "option 1", value: "option-1"},
        {label: "option 2", value: "option-2"},
        {label: "option 3", value: "option-3"},
    ];

    return (
        <div>
            <div>
                <ScreanTitle title={t('ADD_SPECIAL_OFFER_SCREEN')}></ScreanTitle>
                <div className={'flex justify-between pb-3'}>
                    <div>
                        <button className="btn btn-primary flex items-center">
                            <span className={'pe-3'}>{t('CREATE_NEW_REMINDER')}</span>
                            <em className={'icon-plus'}></em>
                        </button>
                    </div>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                        <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    فلتر 1
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 2
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 3
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 4
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <div>
                <div className={'white-card pb-3 pt-1  mb-3'}>
                    <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('CREATE_NEW_OFFER')}</p>
                    <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pb-3">
                        <div>
                            <InputField
                                label={t('SHOW_NAME')}
                                placeholder={t('SHOW_NAME')}
                                id="SHOW_NAME"
                                type="text"
                            />
                        </div>
                        <div>
                            <CustomSelect  onChange={handleChange}
                                label={t('WEBSITE_AND_APPLICATION')}
                                placeholder={t('WEBSITE_AND_APPLICATION')}
                                options={sampleOptions}
                            />
                        </div>
                        <div>
                            <CustomDatePicker label={t("OFFER_START_DATE")} placeholder={'OFFER_START_DATE'}/>
                        </div>
                        <div>
                            <CustomDatePicker label={t("OFFER_EXPIRY_DATE")} placeholder={'OFFER_EXPIRY_DATE'}/>
                        </div>
                    </div>
                </div>
                <div className={'white-card pb-3 pt-1 my-3'}>
                    <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-2 px-4 pb-3">
                        <div className={'col-span-2'}>
                            <CustomSelect  onChange={handleChange}
                                label={t('DISPLAY_TYPE')}
                                placeholder={t('DISPLAY_TYPE')}
                                options={sampleOptions}
                            />
                        </div>
                        <div>
                            <InputField
                                label={t('QUANTITY')}
                                placeholder={t('QUANTITY')}
                                id="QUANTITY"
                                type="text"
                            />
                        </div>
                        <div>
                            <CustomSelect  onChange={handleChange}
                                label={t('PRODUCT')}
                                placeholder={t('PRODUCT')}
                                options={sampleOptions}
                            />
                        </div>
                    </div>
                </div>
                <div className={'white-card pb-3 pt-1 my-3'}>
                    <p className={'ps-6 py-3 font-regular fs-17'}>{t('THE_CUSTOMER_GETS')}</p>
                    <p className={'ps-6 py-3 font-regular fs-15'}>{t('DETERMINE_WHAT_GETS')}</p>
                    <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-8 px-4 pb-3">
                        <div className={'col-span-2'}>
                            <InputField
                                label={t('QUANTITY')}
                                placeholder={t('QUANTITY')}
                                id="QUANTITY"
                                type="text"
                            />
                        </div>
                        <div className={'col-span-2'}>
                            <CustomSelect  onChange={handleChange}
                                label={t('PRODUCT')}
                                placeholder={t('PRODUCT')}
                                options={sampleOptions}
                            />
                        </div>
                        <div className={'col-span-2'}>
                            <InputField
                                label={t('SEARCH_PRODUCT')}
                                placeholder={t('SEARCH_PRODUCT')}
                                id="SEARCH_PRODUCT"
                                type="text"
                            />
                        </div>
                        <div>
                            <InputField
                                label={t('DISCOUNT_TYPE')}
                                placeholder={t('QUANTITY')}
                                id="QUANTITY"
                                type="text"
                                fixedValue={<span className={'icon-search-normal fs-14'}></span>}
                            />
                        </div>
                        <div >
                            <InputField
                                label={t('DISCOUNT_PERCENTAGE')}
                                placeholder={t('DISCOUNT_PERCENTAGE')}
                                id="QUANTITY"
                                type="text"
                                fixedValue={<span className={' fs-14'}>%</span>}
                            />
                        </div>
                        <div className={'md:col-span-8'}>
                            <TextArea
                                label={t('MARKETING_MESSAGE_TEXT')}
                                placeholder={t('MARKETING_MESSAGE_TEXT')}
                                id="MARKETING_MESSAGE_TEXT"
                                type="text"
                            />
                        </div>
                    </div>

                </div>
                <div className={'border-white border-2 mt-4 border-radius-20 ps-6 py-5 grad-yellow-2'}>
                    <p className={'font-regular fs-17'}>{t('PRESENTATION_SUMMARY')}</p>
                    <p className={'pt-3 font-bold fs-19'}>اشتري 1 بطاقة واحصل على 1  سنة مجانية</p>
                </div>
                <div className={'flex justify-center pt-4 flex-wrap'}>
                    <button className="btn btn-secondary mx-3 mb-2">
                        <em className={'icon-check'}></em>
                        <span className={'ps-3'}>{t('SAVE')}</span>
                    </button>
                    <button className="btn btn-outline-secondary mx-3 mb-2">
                        <em className={'icon-close'}></em>
                        <span className={'ps-3'}>{t('CLOSE')}</span>
                    </button>
                </div>
            </div>
        </div>
    );
};


export default AddNewSpecialOffers;
