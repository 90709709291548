import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import InputField from "../../../../components/fields/InputField";
import React from "react";
import Upload from "../../../../components/customUpload/Upload";
import CustomTable from "../../../../components/customTable/CustomTable";
import Switch from "../../../../components/switch";

const CountriesManagement = () => {
    const { t } = useTranslation();
    const data = [
        { label: "COUNTRY", content: "البنك السعودي للإستثمار", colSpan: 3 },
        { label: "STOP", content: <Switch id="switch1"/>, colSpan:3 },
        { label: "AMENDMENT", content: <button className={'action-btn border-primary bg-white'} >
                <span className={'icon-pen fs-16 text-primary-500'}> <span className="path1"/> <span className="path2"/></span></button>, colSpan: 3},
        { label: "REVIEW", content: <button className={'action-btn border-primary bg-white'} >
                <span className={'icon-search-normal fs-16 text-primary-500'}> <span className="path1"/> <span className="path2"/></span></button>, colSpan: 1 },

    ];
    return (
        <div>
            <div>
                <ScreanTitle title={t('COUNTRY_SETTINGS_SCREEN')}></ScreanTitle>
                <div className={'pb-3'}>
                    <button className="btn btn-primary mx-3 flex items-center">
                        <span className={'pe-3'}>{t('ADD_COUNTRY')}</span>
                        <em className={'icon-plus'}></em>
                    </button>
                </div>
            </div>
            <div className={'white-card pb-3 pt-1'}>
                <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-2 px-4 pb-3">
                    <div>
                        <InputField
                            label={t('COUNTRY')}
                            value={'المملكة العربية السعودية'}
                            disabled={true}
                            id="COUNTRY"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('COUNTRY_ENGLISH')}
                            value={'KSA'}
                            disabled={true}
                            id="COUNTRY"
                            type="text"
                        />
                    </div>
                    <div className={'flex items-center'}>
                       <div className={'w-full'}>
                           <InputField
                               label={t('COUNTRY_PHONE_CODE')}
                               placeholder={t('966')}
                               id="COUNTRY_PHONE_CODE"
                               type="text"
                           />
                       </div>
                    </div>
                    <div className={'pt-2'}>
                        <Upload  label={t('COUNTRY_FLAG')} id={'COUNTRY_FLAG'}/>
                    </div>
                </div>
                <div className={'flex justify-center py-4'}>
                    <button className="btn btn-primary mx-3">
                        <em className={'icon-pen'}></em>
                        <span className={'ps-3'}>{t('AMENDMENT')}</span>
                    </button>
                    <button className="btn btn-secondary mx-3">
                        <em className={'icon-check'}></em>
                        <span className={'ps-3'}>{t('SAVE')}</span>
                    </button>
                    <button className="btn btn-outline-secondary mx-3">
                        <em className={'icon-close'}></em>
                        <span className={'ps-3'}>{t('CLOSE')}</span>
                    </button>
                </div>
            </div>
            <div className="white-card h-full w-full mt-4">
                <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('CURRENT_COUNTRY')}</p>
                <div className="steppedTable">
                    <CustomTable data={data} numColumns="10" />
                    <CustomTable data={data} numColumns="10" />
                    <CustomTable data={data} numColumns="10" />
                    <CustomTable data={data} numColumns="10" />
                    <CustomTable data={data} numColumns="10" />
                </div>
            </div>
        </div>
    );
};


export default CountriesManagement;
