import React from "react";
import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import Switch from "../../../../components/switch";
import avatar2 from "../../../../assets/img/avatars/avatar2.png";
import avatar3 from "../../../../assets/img/avatars/avatar3.png";
import avatar4 from "../../../../assets/img/avatars/avatar4.png";
import avatar5 from "../../../../assets/img/avatars/avatar6.png";
import CardMenu from "components/card/CardMenu";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import PrintButton from "../../../../components/filterButton/printBtn";

const EmployeeList = () => {
    const { t } = useTranslation();
    return (
        <div>
            <div>
                <ScreanTitle title={t('EMPLOYEES_SCREEN_IN_USER_MANAGEMENT')}></ScreanTitle>
                <div className={'flex justify-end pb-3'}>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                               <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    فلتر 1
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 2
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 3
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 4
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="mt-1 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
                <div className="white-card  h-full w-full">
                    <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('EMPLOYEES_SCREEN_IN_USER_MANAGEMENT')}</p>
                    <div className={'steppedTable pt-3'}>
                        <div className="stepped-element grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-11 px-4 pt-2 pb-1">
                            <div className={'col-span-1'}>
                                <div className={'flex items-center'}>
                                    <span className={'font-regular fs-15 text-gray-600'}> 1</span>
                                    <div className={'ps-2'}>
                                        <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIPS')}</p>
                                        <img src={avatar2} alt="avatar1" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>
                                    </div>
                                </div>

                            </div>
                            <div className={'col-span-2'}>
                                <p className={'font-regular fs-15 pb-1'}>{t('NAME_OF_EMPLOYEE_OWNER')}</p>
                                <p className={'font-regular fs-15 text-gray-600'}>بشار بعاج</p>
                            </div>
                            <div>
                                <p className={'font-regular fs-15 pb-1'}>{t('CITY')}</p>
                                <p className={'font-regular fs-15 text-gray-600'}>الرياض</p>
                            </div>
                            <div>
                                <p className={'font-regular fs-15 pb-1'}>{t('PHONE')}</p>
                                <p className={'font-regular fs-15 text-gray-600'}>0563231328</p>
                            </div>
                            <div className={'col-span-2'}>
                                <p className={'font-regular fs-15 pb-1'}>{t('MAIL')}</p>
                                <p className={'font-regular fs-15 text-gray-600'}>tkaful@gmail.com</p>
                            </div>
                            <div>
                                <p className={'font-regular fs-15 pb-1'}>{t('JOB')}</p>
                                <p className={'font-regular fs-15 text-gray-600'}>مدير مبيعات</p>
                            </div>
                            <div>
                                <p className={'font-regular fs-15 pb-1'}>{t('ON_OFF')}</p>
                                <Switch id="switch1" color={'primary'}/>
                            </div>
                            <div>
                                <p className={'font-regular fs-15 pb-1'}>{t('EDIT')}</p>
                                <button className="action-btn border-primary bg-white " >
                                    <em className={'icon-pen fs-16 text-primary-500'} />
                                </button>
                            </div>
                            <div >
                                <p className={'font-regular fs-15 pb-1'}>{t('REVIEW')}</p>
                                <button className="action-btn border-primary bg-white " >
                                    <em className={'icon-search-normal fs-16 text-primary-500'} />
                                </button>
                            </div>
                        </div>
                        <div className="stepped-element grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-11 px-4 pt-2 pb-1">
                            <div className={'col-span-1'}>
                                <div className={'flex items-center'}>
                                    <span className={'font-regular fs-15 text-gray-600'}> 2</span>
                                    <div className={'ps-2'}>
                                        <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIPS')}</p>
                                        <img src={avatar3} alt="avatar1" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>
                                    </div>
                                </div>
                            </div>
                            <div className={'col-span-2'}>
                                <p className={'font-regular fs-15 pb-1'}>{t('NAME_OF_EMPLOYEE_OWNER')}</p>
                                <p className={'font-regular fs-15 text-gray-600'}>بشار بعاج</p>
                            </div>
                            <div>
                                <p className={'font-regular fs-15 pb-1'}>{t('CITY')}</p>
                                <p className={'font-regular fs-15 text-gray-600'}>الرياض</p>
                            </div>
                            <div>
                                <p className={'font-regular fs-15 pb-1'}>{t('PHONE')}</p>
                                <p className={'font-regular fs-15 text-gray-600'}>0563231328</p>
                            </div>
                            <div className={'col-span-2'}>
                                <p className={'font-regular fs-15 pb-1'}>{t('MAIL')}</p>
                                <p className={'font-regular fs-15 text-gray-600'}>tkaful@gmail.com</p>
                            </div>
                            <div>
                                <p className={'font-regular fs-15 pb-1'}>{t('JOB')}</p>
                                <p className={'font-regular fs-15 text-gray-600'}>مدير مبيعات</p>
                            </div>
                            <div>
                                <p className={'font-regular fs-15 pb-1'}>{t('ON_OFF')}</p>
                                <Switch id="switch1" color={'primary'}/>
                            </div>
                            <div>
                                <p className={'font-regular fs-15 pb-1'}>{t('EDIT')}</p>
                                <button className="action-btn border-primary bg-white " >
                                    <em className={'icon-pen fs-16 text-primary-500'} />
                                </button>
                            </div>
                            <div >
                                <p className={'font-regular fs-15 pb-1'}>{t('REVIEW')}</p>
                                <button className="action-btn border-primary bg-white " >
                                    <em className={'icon-search-normal fs-16 text-primary-500'} />
                                </button>
                            </div>
                        </div>
                        <div className="stepped-element grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-11 px-4 pt-2 pb-1">
                            <div className={'col-span-1'}>
                                <div className={'flex items-center'}>
                                    <span className={'font-regular fs-15 text-gray-600'}> 3</span>
                                    <div className={'ps-2'}>
                                        <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIPS')}</p>
                                        <img src={avatar4} alt="avatar1" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>
                                    </div>
                                </div>

                            </div>
                            <div className={'col-span-2'}>
                                <p className={'font-regular fs-15 pb-1'}>{t('NAME_OF_EMPLOYEE_OWNER')}</p>
                                <p className={'font-regular fs-15 text-gray-600'}>بشار بعاج</p>
                            </div>
                            <div>
                                <p className={'font-regular fs-15 pb-1'}>{t('CITY')}</p>
                                <p className={'font-regular fs-15 text-gray-600'}>الرياض</p>
                            </div>
                            <div>
                                <p className={'font-regular fs-15 pb-1'}>{t('PHONE')}</p>
                                <p className={'font-regular fs-15 text-gray-600'}>0563231328</p>
                            </div>
                            <div className={'col-span-2'}>
                                <p className={'font-regular fs-15 pb-1'}>{t('MAIL')}</p>
                                <p className={'font-regular fs-15 text-gray-600'}>tkaful@gmail.com</p>
                            </div>
                            <div>
                                <p className={'font-regular fs-15 pb-1'}>{t('JOB')}</p>
                                <p className={'font-regular fs-15 text-gray-600'}>مدير مبيعات</p>
                            </div>
                            <div>
                                <p className={'font-regular fs-15 pb-1'}>{t('ON_OFF')}</p>
                                <Switch id="switch1" color={'primary'}/>
                            </div>
                            <div>
                                <p className={'font-regular fs-15 pb-1'}>{t('EDIT')}</p>
                                <button className="action-btn border-primary bg-white " >
                                    <em className={'icon-pen fs-16 text-primary-500'} />
                                </button>
                            </div>
                            <div >
                                <p className={'font-regular fs-15 pb-1'}>{t('REVIEW')}</p>
                                <button className="action-btn border-primary bg-white " >
                                    <em className={'icon-search-normal fs-16 text-primary-500'} />
                                </button>
                            </div>
                        </div>
                        <div className="stepped-element grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-11 px-4 pt-2 pb-1">
                            <div className={'col-span-1'}>
                                <div className={'flex items-center'}>
                                    <span className={'font-regular fs-15 text-gray-600'}> 4</span>
                                    <div className={'ps-2'}>
                                        <p className={'font-regular fs-15 pb-1'}>{t('MEMBERSHIPS')}</p>
                                        <img src={avatar5} alt="avatar1" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>
                                    </div>
                                </div>

                            </div>
                            <div className={'col-span-2'}>
                                <p className={'font-regular fs-15 pb-1'}>{t('NAME_OF_EMPLOYEE_OWNER')}</p>
                                <p className={'font-regular fs-15 text-gray-600'}>بشار بعاج</p>
                            </div>
                            <div>
                                <p className={'font-regular fs-15 pb-1'}>{t('CITY')}</p>
                                <p className={'font-regular fs-15 text-gray-600'}>الرياض</p>
                            </div>
                            <div>
                                <p className={'font-regular fs-15 pb-1'}>{t('PHONE')}</p>
                                <p className={'font-regular fs-15 text-gray-600'}>0563231328</p>
                            </div>
                            <div className={'col-span-2'}>
                                <p className={'font-regular fs-15 pb-1'}>{t('MAIL')}</p>
                                <p className={'font-regular fs-15 text-gray-600'}>tkaful@gmail.com</p>
                            </div>
                            <div>
                                <p className={'font-regular fs-15 pb-1'}>{t('JOB')}</p>
                                <p className={'font-regular fs-15 text-gray-600'}>مدير مبيعات</p>
                            </div>
                            <div>
                                <p className={'font-regular fs-15 pb-1'}>{t('ON_OFF')}</p>
                                <Switch id="switch1" color={'primary'}/>
                            </div>
                            <div>
                                <p className={'font-regular fs-15 pb-1'}>{t('EDIT')}</p>
                                <button className="action-btn border-primary bg-white " >
                                    <em className={'icon-pen fs-16 text-primary-500'} />
                                </button>
                            </div>
                            <div >
                                <p className={'font-regular fs-15 pb-1'}>{t('REVIEW')}</p>
                                <button className="action-btn border-primary bg-white " >
                                    <em className={'icon-search-normal fs-16 text-primary-500'} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
};


export default EmployeeList;
