import React from "react";
import {useTranslation} from "react-i18next";

interface TableItem {
  label?: string;
  color?: string;
  content?: JSX.Element | string;
  colSpan?: number;
  headerClass?: string;
}

interface CustomTableProps {
  data: TableItem[];
  numColumns: string | number ;
  customClass?: any | string ;
}

const CustomTable: React.FC<CustomTableProps> = ({ data, numColumns, customClass }) => {
  const { t } = useTranslation();
  return (
      <div className={`stepped-element grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 xxl-grid-cols-${numColumns} px-4 pt-2 pb-1 `}>
        {data.map((item, index) => (
            <div key={index} className={`col-span-${item.colSpan} flex sm:block justify-between`}>
              <p className={`font-regular fs-15 pb-1 ${item.headerClass}`}>{t(item.label)}</p>
              <div className={`font-regular fs-15 text-gray-600 h-full text-${item.color}-500`}>
                {item.content}
              </div>
            </div>
        ))}
      </div>
  );
};

export default CustomTable;
