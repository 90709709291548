import {useTranslation} from "react-i18next";
import React from "react";
import ScreanTitle from "../../../../../components/screanTitle/screanTitle";
import InputField from "../../../../../components/fields/InputField";
import CustomSelect from "../../../../../components/select/select";
import CustomDatePicker from "../../../../../components/fields/DatePicker";
import Upload from "../../../../../components/customUpload/Upload";


const AddDealer = () => {
    const { t } = useTranslation();
    type Option = typeof sampleOptions[0];
    const handleChange = (selectedValues: Option[]) => {
        console.log(selectedValues);
    };
    const sampleOptions = [
        {label: "option 1", value: "option-1"},
        {label: "option 2", value: "option-2"},
        {label: "option 3", value: "option-3"},
    ];
    return (
        <div>
            <div>
                <ScreanTitle title={t('ADD_MERCHANT_SCREEN')}></ScreanTitle>
            </div>
            <div className="white-card h-full w-full mt-1">
                <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('ADD_CENTER')}</p>
                <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pb-3">
                    <div>
                        <InputField
                            label={t('THE_NAME_OF_THE_CENTER_IN_ARABIC')}
                            placeholder={t('THE_NAME_OF_THE_CENTER_IN_ARABIC')}
                            id="THE_NAME_OF_THE_CENTER_IN_ARABIC"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('THE_NAME_OF_THE_CENTER_IN_ENGLISH')}
                            placeholder={t('THE_NAME_OF_THE_CENTER_IN_ENGLISH')}
                            id="THE_NAME_OF_THE_CENTER_IN_ENGLISH"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('INTERNATIONAL_NUMBERING')}
                            placeholder={t('INTERNATIONAL_NUMBERING')}
                            id="INTERNATIONAL_NUMBERING"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('PHONE')}
                            placeholder={t('PHONE')}
                            id="PHONE"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('NAME_OF_ADMINISTRATOR')}
                            placeholder={t('NAME_OF_ADMINISTRATOR')}
                            id="NAME_OF_ADMINISTRATOR"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('ADMIN_PHONE')}
                            placeholder={t('ADMIN_PHONE')}
                            id="ADMIN_PHONE"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('MAIL')}
                            placeholder={t('MAIL')}
                            id="MAIL"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('DELEGATE_NAME')}
                            placeholder={t('DELEGATE_NAME')}
                            id="DELEGATE_NAME"
                            type="text"
                        />
                    </div>
                    <div>
                        <CustomDatePicker label={t('CONTRACT_STARTING_DATE')} placeholder={t('CONTRACT_STARTING_DATE')} />
                    </div>
                    <div>
                        <CustomDatePicker label={t('CONTRACT_END_DATE')} placeholder={t('CONTRACT_END_DATE')} />
                    </div>
                    <div>
                        <InputField
                            label={t('DESCRIPTION_OF_THE_CENTER_IN_ARABIC')}
                            placeholder={t('DESCRIPTION_OF_THE_CENTER_IN_ARABIC')}
                            id="DESCRIPTION_OF_THE_CENTER_IN_ARABIC"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('DESCRIPTION_OF_THE_CENTER_IN_ENGLISH')}
                            placeholder={t('DESCRIPTION_OF_THE_CENTER_IN_ENGLISH')}
                            id="DESCRIPTION_OF_THE_CENTER_IN_ENGLISH"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('COUNTRY')}
                            placeholder={t('COUNTRY')}
                            id="COUNTRY"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('CITY')}
                            placeholder={t('CITY')}
                            id="CITY"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('SERVICE_DESCRIPTION')}
                            placeholder={t('SERVICE_DESCRIPTION')}
                            id="SERVICE_DESCRIPTION"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('BANK_ACCOUNT')}
                            placeholder={t('BANK_ACCOUNT')}
                            id="BANK_ACCOUNT"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('COMMERCIAL_REGISTRATION_NUMBER')}
                            placeholder={t('COMMERCIAL_REGISTRATION_NUMBER')}
                            id="COMMERCIAL_REGISTRATION_NUMBER"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('LOCATION_MAP')}
                            placeholder={t('LOCATION_MAP')}
                            id="LOCATION_MAP"
                            type="text"
                        />
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4">
                    <div>
                        <Upload  label={t('DOWNLOAD_CENTER_LOGO')} id={'DOWNLOAD_CENTER_LOGO'}/>
                    </div>
                    <div>
                        <Upload  label={t('UPLOAD_COUPON_IMAGE')} id={'UPLOAD_COUPON_IMAGE'}/>
                    </div>
                    <div>
                        <Upload  label={t('DOWNLOAD_THE_CONTRACT')} id={'DOWNLOAD_THE_CONTRACT'}/>
                    </div>
                    <div>
                        <Upload   label={t('AN_IMAGE_OF_A_MENU_OR_POSTER')} id={'AN_IMAGE_OF_A_MENU_OR_POSTER'}/>
                    </div>
                    <div>
                        <InputField
                            label={t('WHATSAPP')}
                            placeholder={t('WHATSAPP')}
                            id="WHATSAPP"
                            type="text"
                            fixedValue={<span className={'icon-up fs-14'}></span>}
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('WEBSITE')}
                            placeholder={t('WEBSITE')}
                            id="WEBSITE"
                            type="text"
                            fixedValue={<span className={'icon-up fs-14'}></span>}
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('FACEBOOK_LINK')}
                            placeholder={t('FACEBOOK_LINK')}
                            id="FACEBOOK_LINK"
                            type="text"
                            fixedValue={<span className={'icon-up fs-14'}></span>}
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('TWITTER_LINK')}
                            placeholder={t('TWITTER_LINK')}
                            id="TWITTER_LINK"
                            type="text"
                            fixedValue={<span className={'icon-up fs-14'}></span>}
                        />
                    </div>
                    <div className={'col-span-2'}>
                        <CustomSelect  onChange={handleChange}
                                       label={t('MERCHANT_RATING')}
                                       placeholder={t('MERCHANT_RATING')}
                                       options={sampleOptions}
                        />
                    </div>
                    <div className={'col-span-2'}>
                        <CustomSelect  onChange={handleChange}
                                       label={t('MERCHANT_SECTION')}
                                       placeholder={t('MERCHANT_SECTION')}
                                       options={sampleOptions}
                        />
                    </div>
                </div>
                <div>
                    <div className={'flex justify-center py-5 flex-wrap'}>
                        <button className="btn btn-primary mx-3">
                            <em className={'icon-plus'}></em>
                            <span className={'ps-3'}>{t('ADD_OFFER')}</span>
                        </button>
                        <button className="btn btn-primary mx-3">
                            <em className={'icon-plus'}></em>
                            <span className={'ps-3'}>{t('ADD_BRANCHES')}</span>
                        </button>
                    </div>
                </div>
                <div>
                    <div className={'flex justify-center py-5 flex-wrap'}>
                        <button className="btn btn-primary mx-3 mb-2">
                            <em className={'icon-pen'}></em>
                            <span className={'ps-3'}>{t('AMENDMENT')}</span>
                        </button>
                        <button className="btn btn-secondary mx-3 mb-2">
                            <em className={'icon-check'}></em>
                            <span className={'ps-3'}>{t('SAVE')}</span>
                        </button>
                        <button className="btn btn-light mx-3 mb-2">
                            <em className={'icon-check'}></em>
                            <span className={'ps-3'}>{t('SAVE_ADD')}</span>
                        </button>
                        <button className="btn btn-outline-secondary mx-3 mb-2">
                            <em className={'icon-close'}></em>
                            <span className={'ps-3'}>{t('CLOSE')}</span>
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
};


export default AddDealer;
