import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import React, {useState} from "react";
import CardMenu from "../../../../components/card/CardMenu";
import CustomTable from "../../../../components/customTable/CustomTable";
import PrintButton from "../../../../components/filterButton/printBtn";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import Switch from "../../../../components/switch";
import {useNavigate} from "react-router-dom";
import QrCode from "../../../../assets/img/prod/qr.png";
import client from "../../../../assets/img/prod/Group1.png";

const Dealer = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const viewMerchant = () => {
        navigate('/private/establishment-management/dealer/view-dealer');
    };
    const addMerchant = () => {
        navigate('/private/establishment-management/dealer/add-dealer');
    };
    const data = [
        { title: t('ALL_DEALER') },
        { title: t('DISCOUNT_DEALERS') },
        { title: t('COUPON_MERCHANTS') },
        { title: t('CARD_DEALERS') },
        { title: t('COUPON_DEALERS') },
    ];
    const dataTable = [
        {content:<div className={'flex items-center h-full'}>
                <span className={'font-regular fs-15 text-gray-600'}> 1</span>
            </div>, colSpan: 1 },
        { label: "CLIENT", content:<img src={client} alt="avatar4" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>, colSpan: 1 },
        { label: "DEALER_QR", content: <img src={QrCode} alt="avatar4" width={'27px'} className={'border-radius-10 h-[27px] me-2'}/>, colSpan: 1 },
        { label: "DEALER_CODE", content: "25487", colSpan: 1 },
        { label: "DEALER_NAME", content: "شركة البيك", colSpan: 1 },
        { label: "BRANCHES_NUMBER", content: "1", colSpan: 1 },
        { label: "OFFERS_NUMBER", content:'3', colSpan: 1 },
        { label: "ACTIVE_OFFERS_NUMBER", content:'2', colSpan: 1 },
        { label: "AMOUNT_VALUE", content:'190', colSpan: 1 },
        { label: "DATE_OF_CONTRACT", content:'1-1-2023', colSpan: 1 },
        { label: "DATE_END_CONTRACT", content:'1-10-2023', colSpan: 1 },
        { label: "FINISH_ORDER", content: <Switch />, colSpan: 1 },
        { label: "REVIEW", content: <button className={'action-btn border-primary bg-white'} onClick={viewMerchant}>
                <span className={'icon-search-normal fs-16 text-primary-500'}> <span className="path1"/> <span className="path2"/></span></button>, colSpan: 1 },


    ];
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const handleCardClick = (index: number) => {
        setActiveIndex(index);
    };


    return (
        <div>
            <div>
                <ScreanTitle title={t('TRADERS_INFORMATION_SCREEN')}></ScreanTitle>
                <div className={'flex justify-between pb-3'}>
                    <div>
                        <button className="btn btn-primary mx-3 flex items-center" onClick={addMerchant}>
                            <span className={'pe-3'}>{t('ADD_DEALER')}</span>
                            <em className={'icon-plus'}></em>
                        </button>
                    </div>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                        <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    الكل
                                </p>
                                <p className="text-black cursor-pointer">
                                    خدمات مطاعم
                                </p>
                                <p className="text-black cursor-pointer">
                                    خدمات اليومية
                                </p>
                                <p className="text-black cursor-pointer">
                                    التسلية والترفيه
                                </p>
                                <p className="text-black cursor-pointer">
                                    تجميل ولياقه
                                </p>
                                <p className="text-black cursor-pointer">
                                    الالكترونيات
                                </p>
                                <p className="text-black cursor-pointer">
                                    متاجر الكترونية
                                </p>
                                <p className="text-black cursor-pointer">
                                    خدمات السيارات
                                </p>
                                <p className="text-black cursor-pointer">
                                    سياحة وسفر
                                </p>
                                <p className="text-black cursor-pointer">
                                    مؤرشف
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>

            <div className={'pb-3 pt-1'}>
                <div className="flex mt-3 checkListCard  gap-5 lg:gap-10 px-0 pb-3 justify-center flex-wrap">
                    {data.map((item, index) => (
                        <div
                            key={index}
                            className={`simple-card md:min-w-[150px] py-2 px-7 ${index === activeIndex && 'active'}`}
                            onClick={() => handleCardClick(index)}
                        >
                            <p className= {` text-center fs-19 ${index === activeIndex ? 'font-bold text-primary-500 border-b-primary-500' : 'font-medium text-gray-600'}`}>{item.title}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="white-card h-full w-full mt-3">
                <div className="steppedTable">
                    <CustomTable data={dataTable} numColumns={'13'} />
                    <CustomTable data={dataTable} numColumns={'13'} />
                    <CustomTable data={dataTable} numColumns={'13'} />
                    <CustomTable data={dataTable} numColumns={'13'} />
                </div>
            </div>
        </div>
    );
};


export default Dealer;
