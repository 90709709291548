import {useTranslation} from "react-i18next";
import React from "react";
import ScreanTitle from "../../../../components/screanTitle/screanTitle";
import InputField from "../../../../components/fields/InputField";
import PrintButton from "../../../../components/filterButton/printBtn";
import UploadButton from "../../../../components/filterButton/uploadBtn";
import CardMenu from "../../../../components/card/CardMenu";
import twitter from "../../../../assets/img/social/twitter.png";
import facebook from "../../../../assets/img/social/facebook.png";
import messenger from "../../../../assets/img/social/messenger.png";
import telegram from "../../../../assets/img/social/telegram.png";
import tiktok from "../../../../assets/img/social/tiktok.png";
import whatsapp from "../../../../assets/img/social/whatsapp.png";
import youtube from "../../../../assets/img/social/youtube.png";
import instagram from "../../../../assets/img/social/instagram.png";
import snapchat from "../../../../assets/img/social/snapchat.png";

import CustomTable from "../../../../components/customTable/CustomTable";


const SocialManagement = () => {
    const { t } = useTranslation();

    const dataTable = [
        { label: "POINT_NAME", content: "انظمام مقهى تيم هورتنز", colSpan: 3 },
        { label: "COUNTRY", content: "المملكة العربية السعودية", colSpan: 3 },
        { label: "CITY", content: "الرياض", colSpan: 1 },
        { label: "DATE", content:'1-1-2023', colSpan: 1 },
        { label: "AMENDMENT", content: <button className={'action-btn border-primary bg-white'}>
                <span className={'icon-pen fs-16 text-primary-500'}> </span></button>, colSpan: 1 },
        { label: "REVIEW", content: <button className={'action-btn border-primary bg-white'}>
                <span className={'icon-search-normal fs-16 text-primary-500'}> <span className="path1"/> <span className="path2"/></span></button>, colSpan: 1 },


    ];

    return (
        <div>
            <div>
                <ScreanTitle title={t('SCREEN_SETTING_SOCIAL_NUMBER')}></ScreanTitle>
                <div className={'flex justify-between pb-3'}>
                    <div>
                        <button className="btn btn-primary mx-3 flex items-center">
                            <span className={'pe-3'}>{t('CREATE_A_MARKETING_CAMPAIGN')}</span>
                            <em className={'icon-plus'}></em>
                        </button>
                    </div>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                        <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div
                                className="z-50 w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                <p className="hover:text-black flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium">
                                    Panel 1
                                </p>
                                <p className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium">
                                    Panel 2
                                </p>
                                <p className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium">
                                    Panel 3
                                </p>
                                <p className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium">
                                    Panel 4
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="white-card h-full w-full mt-1">
                <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('SCREEN_SETTING_SOCIAL_NUMBER')}</p>
                <div  className="dashed-border-sm mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pt-3 pb-5">
                    <div className={'col-span-3'}>
                        <div className={'flex gap-3 items-end'}>
                            <div className={'dashed-border-sm h-[47px]'}>
                                <img src={twitter} alt="twitter" width={'47px'}/>
                            </div>
                            <div className={'flex-1'}>
                                <InputField
                                    label={t('TWITTER_LINK')}
                                    placeholder={t('TWITTER_LINK')}
                                    id="POINT_ADDRESS"
                                    type="text"
                                    fixedValue={<em className={'icon-up'}/>}
                                />
                            </div>

                        </div>
                    </div>
                    <div className={'flex-1'}>
                        <InputField
                            label={t('ADD_ICON')}
                            placeholder={t('ADD_ICON')}
                            id="ADD_ICON"
                            type="text"
                            fixedValue={<em className={'icon-up'}/>}
                        />
                    </div>
                </div>
                <div className="dashed-border-sm mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pt-3 pb-5">
                    <div className={'col-span-3'}>
                        <div className={'flex gap-3 items-end'}>
                            <div className={'dashed-border-sm h-[47px]'}>
                                <img src={facebook} alt="twitter" width={'47px'}/>
                            </div>
                            <div className={'flex-1'}>
                                <InputField
                                    label={t('FACEBOOK_LINK')}
                                    placeholder={t('FACEBOOK_LINK')}
                                    id="POINT_ADDRESS"
                                    type="text"
                                    fixedValue={<em className={'icon-up'}/>}
                                />
                            </div>

                        </div>

                    </div>
                    <div className={'flex-1'}>
                        <InputField
                            label={t('ADD_ICON')}
                            placeholder={t('ADD_ICON')}
                            id="ADD_ICON"
                            type="text"
                            fixedValue={<em className={'icon-up'}/>}
                        />
                    </div>
                </div>
                <div className="dashed-border-sm mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pt-3 pb-5">
                    <div className={'col-span-3'}>
                        <div className={'flex gap-3 items-end'}>
                            <div className={'dashed-border-sm h-[47px]'}>
                                <img src={instagram} alt="instagram" width={'47px'}/>
                            </div>
                            <div className={'flex-1'}>
                                <InputField
                                    label={t('INSTAGRAM_LINK')}
                                    placeholder={t('INSTAGRAM_LINK')}
                                    id="POINT_ADDRESS"
                                    type="text"
                                    fixedValue={<em className={'icon-up'}/>}
                                />
                            </div>

                        </div>
                    </div>
                    <div className={'flex-1'}>
                        <InputField
                            label={t('ADD_ICON')}
                            placeholder={t('ADD_ICON')}
                            id="ADD_ICON"
                            type="text"
                            fixedValue={<em className={'icon-up'}/>}
                        />
                    </div>
                </div>
                <div className="dashed-border-sm mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pt-3 pb-5">
                    <div className={'col-span-3'}>
                        <div className={'flex gap-3 items-end'}>
                            <div className={'dashed-border-sm h-[47px]'}>
                                <img src={whatsapp} alt="watsapp" width={'47px'}/>
                            </div>
                            <div className={'flex-1'}>
                                <InputField
                                    label={t('WHATSAPP_LINK')}
                                    placeholder={t('WHATSAPP_LINK')}
                                    id="WHATSAPP_LINK"
                                    type="text"
                                    fixedValue={<em className={'icon-up'}/>}
                                />
                            </div>

                        </div>

                    </div>
                    <div className={'flex-1'}>
                        <InputField
                            label={t('ADD_ICON')}
                            placeholder={t('ADD_ICON')}
                            id="ADD_ICON"
                            type="text"
                            fixedValue={<em className={'icon-up'}/>}
                        />
                    </div>
                </div>
                <div className="dashed-border-sm mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pt-3 pb-5">
                    <div className={'col-span-3'}>
                        <div className={'flex gap-3 items-end'}>
                            <div className={'dashed-border-sm h-[47px]'}>
                                <img src={tiktok} alt="tiktok" width={'47px'}/>
                            </div>
                            <div className={'flex-1'}>
                                <InputField
                                    label={t('TIKTOK_LINK')}
                                    placeholder={t('TIKTOK_LINK')}
                                    id="TIKTOK_LINK"
                                    type="text"
                                    fixedValue={<em className={'icon-up'}/>}
                                />
                            </div>

                        </div>
                    </div>
                    <div className={'flex-1'}>
                        <InputField
                            label={t('ADD_ICON')}
                            placeholder={t('ADD_ICON')}
                            id="ADD_ICON"
                            type="text"
                            fixedValue={<em className={'icon-up'}/>}
                        />
                    </div>
                </div>
                <div className="dashed-border-sm mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pt-3 pb-5">
                    <div className={'col-span-3'}>
                        <div className={'flex gap-3 items-end'}>
                            <div className={'dashed-border-sm h-[47px]'}>
                                <img src={youtube} alt="youtube" width={'47px'}/>
                            </div>
                            <div className={'flex-1'}>
                                <InputField
                                    label={t('YOUTUBE_LINK')}
                                    placeholder={t('YOUTUBE_LINK')}
                                    id="YOUTUBE_LINK"
                                    type="text"
                                    fixedValue={<em className={'icon-up'}/>}
                                />
                            </div>

                        </div>
                    </div>
                    <div className={'flex-1'}>
                        <InputField
                            label={t('ADD_ICON')}
                            placeholder={t('ADD_ICON')}
                            id="ADD_ICON"
                            type="text"
                            fixedValue={<em className={'icon-up'}/>}
                        />
                    </div>
                </div>
                <div className="dashed-border-sm mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pt-3 pb-5">
                    <div className={'col-span-3'}>
                        <div className={'flex gap-3 items-end'}>
                            <div className={'dashed-border-sm h-[47px]'}>
                                <img src={telegram} alt="telegram" width={'47px'}/>
                            </div>
                            <div className={'flex-1'}>
                                <InputField
                                    label={t('TELEGRAM_LINK')}
                                    placeholder={t('TELEGRAM_LINK')}
                                    id="POINT_ADDRESS"
                                    type="text"
                                    fixedValue={<em className={'icon-up'}/>}
                                />
                            </div>

                        </div>

                    </div>
                    <div className={'flex-1'}>
                        <InputField
                            label={t('ADD_ICON')}
                            placeholder={t('ADD_ICON')}
                            id="ADD_ICON"
                            type="text"
                            fixedValue={<em className={'icon-up'}/>}
                        />
                    </div>
                </div>
                <div className="dashed-border-sm mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pt-3 pb-5">
                    <div className={'col-span-3'}>
                        <div className={'flex gap-3 items-end'}>
                            <div className={'dashed-border-sm h-[47px]'}>
                                <img src={snapchat} alt="snapchat" width={'47px'}/>
                            </div>
                            <div className={'flex-1'}>
                                <InputField
                                    label={t('SNAPCHAT_LINK')}
                                    placeholder={t('SNAPCHAT_LINK')}
                                    id="POINT_ADDRESS"
                                    type="text"
                                    fixedValue={<em className={'icon-up'}/>}
                                />
                            </div>

                        </div>
                    </div>
                    <div className={'flex-1'}>
                        <InputField
                            label={t('ADD_ICON')}
                            placeholder={t('ADD_ICON')}
                            id="ADD_ICON"
                            type="text"
                            fixedValue={<em className={'icon-up'}/>}
                        />
                    </div>
                </div>
                <div className="dashed-border-sm mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pt-3 pb-5">
                    <div className={'col-span-3'}>
                        <div className={'flex gap-3 items-end'}>
                            <div className={'dashed-border-sm h-[47px]'}>
                                <img src={messenger} alt="messenger" width={'47px'}/>
                            </div>
                            <div className={'flex-1'}>
                                <InputField
                                    label={t('MESSENGER_LINK')}
                                    placeholder={t('MESSENGER_LINK')}
                                    id="MESSENGER_LINK"
                                    type="text"
                                    fixedValue={<em className={'icon-up'}/>}
                                />
                            </div>

                        </div>

                    </div>
                    <div className={'flex-1'}>
                        <InputField
                            label={t('ADD_ICON')}
                            placeholder={t('ADD_ICON')}
                            id="ADD_ICON"
                            type="text"
                            fixedValue={<em className={'icon-up'}/>}
                        />
                    </div>
                </div>

                <div className={'flex justify-center py-5 flex-wrap'}>
                    <button className="btn btn-secondary mx-3 mb-2">
                        <em className={'icon-check'}></em>
                        <span className={'ps-3'}>{t('SAVE')}</span>
                    </button>
                    <button className="btn btn-secondary mx-3 mb-2">
                        <em className={'icon-check'}></em>
                        <span className={'ps-3'}>{t('SAVE')}</span>
                    </button>
                    <button className="btn btn-light mx-3 mb-2">
                        <em className={'icon-check'}></em>
                        <span className={'ps-3'}>{t('SAVE_ADD')}</span>
                    </button>
                    <button className="btn btn-outline-secondary mx-3 mb-2">
                        <em className={'icon-close'}></em>
                        <span className={'ps-3'}>{t('CLOSE')}</span>
                    </button>
                </div>
            </div>
        </div>
    );
};


export default SocialManagement;
