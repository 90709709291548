import {useTranslation} from "react-i18next";
import React from "react";
import CardMenu from "../../../../../components/card/CardMenu";
import ScreanTitle from "../../../../../components/screanTitle/screanTitle";
import PrintButton from "../../../../../components/filterButton/printBtn";
import UploadButton from "../../../../../components/filterButton/uploadBtn";
import Switch from "../../../../../components/switch";
import InputField from "../../../../../components/fields/InputField";
import QR from "../../../../../assets/img/prod/qr.png";
import logo from "../../../../../assets/img/prod/image 4.png";
import img1 from "../../../../../assets/img/prod/image 5.png";
import img2 from "../../../../../assets/img/prod/image 6.png";
import img3 from "../../../../../assets/img/prod/image 7.png";
import img4 from "../../../../../assets/img/prod/image 8.png";
import img5 from "../../../../../assets/img/prod/image 9.png";
import CustomTable from "../../../../../components/customTable/CustomTable";

const ViewDealer = () => {
    const { t } = useTranslation();
    const BranchDataTable = [
        {content:<div className={'flex items-center h-full'}> <span className={'font-regular fs-15 text-gray-600'}> 1</span> </div>, colSpan: 1 },
        { label: "COUNTRY", content: "المملكة العربية السعودية", colSpan: 4 },
        { label: "CITY", content: "25487", colSpan: 4 },
        { label: "BRANCH_NAME", content: "1", colSpan: 4 },
        { label: "NUMBER_SHOW_BRANCH", content:'3', colSpan: 4 },
        { label: "REVIEW", content: <button className={'action-btn border-primary bg-white'}>
                <span className={'icon-search-normal fs-16 text-primary-500'}> <span className="path1"/> <span className="path2"/></span></button>, colSpan: 2 },


    ];
    const ShowDataTable = [
        {content:<div className={'flex items-center h-full'}> <span className={'font-regular fs-15 text-gray-600'}> 1</span> </div>, colSpan: 1 },
        { label: "DISPLAY_NUMBER", content: "1145", colSpan: 4 },
        { label: "COUNTRY", content: "المملكة العربية السعودية", colSpan: 4 },
        { label: "CITY", content: "الرياض", colSpan: 4 },
        { label: "DISPLAY_TYPE", content: "قسيمة شرائية", colSpan: 4 },
        { label: "START_DATE", content:'1-1-2023', colSpan: 4 },
        { label: "EXPIRY_DATE", content:'1-1-2023', colSpan: 4 },
        { label: "REVIEW", content: <button className={'action-btn border-primary bg-white'}>
                <span className={'icon-search-normal fs-16 text-primary-500'}> <span className="path1"/> <span className="path2"/></span></button>, colSpan: 2 },


    ];
    return (
        <div>
            <div>
                <ScreanTitle title={t('SERVICE_PROVIDER_PROPERTIES_SCREEN')}></ScreanTitle>
                <div className={'flex justify-between pb-3'}>
                    <button className="btn btn-primary mx-3 flex items-center">
                        <span className={'pe-3'}>{t('VIEW_SERVICE_PROVIDER_PROPERTIES')}</span>
                        <em className={'icon-plus'}></em>
                    </button>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                        <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    الكل
                                </p>
                                <p className="text-black cursor-pointer">
                                    خدمات مطاعم
                                </p>
                                <p className="text-black cursor-pointer">
                                    خدمات اليومية
                                </p>
                                <p className="text-black cursor-pointer">
                                    التسلية والترفيه
                                </p>
                                <p className="text-black cursor-pointer">
                                    تجميل ولياقه
                                </p>
                                <p className="text-black cursor-pointer">
                                    الالكترونيات
                                </p>
                                <p className="text-black cursor-pointer">
                                    متاجر الكترونية
                                </p>
                                <p className="text-black cursor-pointer">
                                    خدمات السيارات
                                </p>
                                <p className="text-black cursor-pointer">
                                    سياحة وسفر
                                </p>
                                <p className="text-black cursor-pointer">
                                    مؤرشف
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>

            <div className={'white-card pb-3 pt-1'}>
                <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('SERVICE_PROVIDER_PROPERTIES_SCREEN')}</p>
                <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pb-3">
                    <div>
                        <InputField
                            label={t('DEALER_NAME')}
                            value={t('مطعم الشاطئ الكبير')}
                            id="DEALER_NAME"
                            disabled={true}
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('PHONE_NUMBER')}
                            value={t('011 22 22 22')}
                            id="PHONE_NUMBER"
                            disabled={true}
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('MOBILE_NUMBER')}
                            value={t('0563231328')}
                            id="MOBILE_NUMBER"
                            disabled={true}
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('ACTIVATION_CODE')}
                            value={t('224466')}
                            id="ACTIVATION_CODE"
                            disabled={true}
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('MAIL')}
                            value={t('mukafaat @gmail.com ')}
                            id="MAIL"
                            disabled={true}
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('REGISTRATION_TYPE')}
                            value={t('مزود خدمة ')}
                            id="REGISTRATION_TYPE"
                            disabled={true}
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('COUNTRY')}
                            value={t('الامملكة العربية السعودية')}
                            id="COUNTRY"
                            disabled={true}
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('REGION')}
                            value={t('الوسطى ')}
                            id="REGION"
                            disabled={true}
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('CITY')}
                            value={t('الرياض')}
                            id="CITY"
                            disabled={true}
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('WALLET_NUMBER')}
                            value={t('4546')}
                            id="WALLET_NUMBER"
                            disabled={true}
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('MANAGER_NAME')}
                            value={t('محمد حسن')}
                            id="MANAGER_NAME"
                            disabled={true}
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('RIB')}
                            value={t('20202010101030')}
                            id="RIB"
                            disabled={true}
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label={t('MERCHANT_BRANCH')}
                            value={t('العدد 3')}
                            id="RIB"
                            disabled={true}
                            type="text"
                        />
                    </div>
                    <div className={'flex dashed-border py-2 px-3 justify-between items-center'}>
                        <div className={'ps-4'}>
                            <p className={'font-bold fs-15'}>{t('DEALER_QR')}</p>
                            <p className={'font-regular fs-15'}>{t('STOP')}</p>
                            <Switch/>
                        </div>
                        <div>
                            <img src={QR} alt="QR" width={'100%'} className={'border-radius-10 me-2'}/>
                        </div>

                    </div>
                    <div className={'flex dashed-border py-2 px-3 justify-between items-center'}>
                        <div className={'ps-4'}>
                            <p className={'font-bold fs-15'}>{t('DEALER_LOGO')}</p>
                        </div>
                        <div>
                            <img src={logo} alt="logo" width={'100%'} className={'border-radius-10 me-2'}/>
                        </div>

                    </div>
                </div>
                <div className={'flex dashed-border py-5 px-3 justify-between items-center mt-4 flex-wrap'}>
                    <div className={'ps-4'}>
                        <p className={'font-bold fs-15'}>{t('STORE_PHOTOS')}</p>
                    </div>
                    <div>
                        <img src={img1} alt="logo" width={'100%'} className={'border-radius-10 me-2'}/>
                    </div>
                    <div>
                        <img src={img2} alt="logo" width={'100%'} className={'border-radius-10 me-2'}/>
                    </div>
                    <div>
                        <img src={img3} alt="logo" width={'100%'} className={'border-radius-10 me-2'}/>
                    </div>
                    <div>
                        <img src={img4} alt="logo" width={'100%'} className={'border-radius-10 me-2'}/>
                    </div>
                    <div>
                        <img src={img5} alt="logo" width={'100%'} className={'border-radius-10 me-2'}/>
                    </div>
                </div>
                <div className={'flex justify-center py-7'}>
                    <button className="btn btn-primary mx-3">
                        <em className={'icon-pen'}></em>
                        <span className={'ps-3'}>{t('AMENDMENT')}</span>
                    </button>
                    <button className="btn btn-secondary mx-3">
                        <em className={'icon-check'}></em>
                        <span className={'ps-3'}>{t('SAVE')}</span>
                    </button>
                    <button className="btn btn-outline-secondary mx-3">
                        <em className={'icon-close'}></em>
                        <span className={'ps-3'}>{t('CLOSE')}</span>
                    </button>
                </div>
            </div>
            <div className="white-card h-full w-full mt-3">
                <div className={'flex items-center flex-wrap'}>
                    <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('CURRENT_BRANCHES_OF_THE_MERCHANT')}</p>
                    <p className={'py-3 ps-5 font-bold fs-15'}>عدد الفروع 3</p>
                </div>
                <div className="steppedTable">
                    <CustomTable data={BranchDataTable} numColumns={'19'}/>
                    <CustomTable data={BranchDataTable} numColumns={'19'}/>
                </div>
            </div>
            <div className="white-card h-full w-full mt-3">
                <div className={"flex justify-between flex-wrap"}>
                    <div className={'flex items-center flex-wrap'}>
                        <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('CURRENT_OFFERS_OF_THE_MERCHANT')}</p>
                        <p className={'py-3 ps-5 font-regular fs-15'}>عدد العروض 3</p>
                    </div>
                    <div className="flex justify-between pb-3">
                        <p className={'py-3 ps-5 font-regular fs-15'}>{t('ACTIVE_OFFERS_NUMBER')} 3</p>
                    </div>
                </div>
                <div className="steppedTable">
                    <CustomTable data={ShowDataTable} numColumns={'27'}/>
                    <CustomTable data={ShowDataTable} numColumns={'27'}/>
                </div>
            </div>
        </div>
    );
};


export default ViewDealer;
