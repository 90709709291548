import React from "react";
import {
    ServiceChartData, ServiceChartOption

} from "./charts-data";
import BarChart from "../../../../components/charts/BarChart";


const ServiceChart = () => {
  return (
      <div className="h-[350px]">
          <BarChart
              chartData={ServiceChartData}
              chartOptions={ServiceChartOption}
          />
      </div>
  );
};

export default ServiceChart;
