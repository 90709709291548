import {useTranslation} from "react-i18next";
import ScreanTitle from "../../../../../components/screanTitle/screanTitle";
import React from "react";
import CardMenu from "../../../../../components/card/CardMenu";
import UploadButton from "../../../../../components/filterButton/uploadBtn";
import PrintButton from "../../../../../components/filterButton/printBtn";
import QR from "../../../../../assets/img/prod/qr.png";

const AddInvoice = () => {
    const {t} = useTranslation();
    return (
        <div>
            <div>
                <ScreanTitle title={t('ADD_INVOICE_SCREEN')}></ScreanTitle>
                <div className={'flex justify-between pb-3'}>
                    <div>
                        <button className="btn btn-primary mx-3 flex items-center">
                            <span className={'pe-3'}>{t('TAX_INVOICE')}</span>
                            <em className={'icon-plus'}></em>
                        </button>
                    </div>
                    <div className={'flex'}>
                        <PrintButton/>
                        <UploadButton/>
                        <CardMenu
                            classList="icon-filter fs-25"
                            menu={<div className="z-50 ">
                                <p className="text-black cursor-pointer">
                                    فلتر 1
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 2
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 3
                                </p>
                                <p className="text-black cursor-pointer">
                                    فلتر 4
                                </p>
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <div className={'white-card  pb-3 pt-1'}>
                <div className="bg-light px-5 py-4 border-radius-10">
                    <p className={'title ps-8 py-3 font-bold text-primary-500 fs-19'}>{t('SELLER_DATA')}</p>
                    <div
                        className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pb-3">
                        <div>
                            <div className={'flex justify-between'}>
                                <p className={'font-regular fs-15 ps-3 pb-1'}>{t('COMPANY_NAME')}</p>
                                <p className={'font-regular fs-15 ps-3 pb-1'}>Company name</p>
                            </div>
                            <div className="dashed-border-sm px-2 py-3">
                                <p className={'ps-2 font-bold fs-15'}>شركة مكافآت المحدودة</p>
                            </div>
                        </div>
                        <div>
                            <div className={'flex justify-between'}>
                                <p className={'font-regular fs-15 ps-3 pb-1'}>{t('BRANCH_NAME')}</p>
                                <p className={'font-regular fs-15 ps-3 pb-1'}>Bransh name</p>
                            </div>

                            <div className="dashed-border-sm px-2 py-3">
                                <p className={'ps-2 font-bold fs-15'}>الفرع الرئيسي </p>
                            </div>
                        </div>

                        <div>
                            <div className={'flex justify-between'}>
                                <p className={'font-regular fs-15 ps-3 pb-1'}>{t('CITY_NEIGHBORHOOD')}</p>
                                <p className={'font-regular fs-15 ps-3 pb-1'}>City name</p>
                            </div>
                            <div className="dashed-border-sm px-2 py-3">
                                <p className={'ps-2 font-bold fs-15'}> الرياض</p>
                            </div>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 ps-3 pb-1'}>{t('POSTAL_CODE')}</p>
                            <div className="dashed-border-sm px-2 py-3">
                                <p className={'ps-2 font-bold fs-15'}> 12460</p>
                            </div>
                        </div>
                        <div className={'2xl:col-span-2'}>
                            <p className={'font-regular fs-15 ps-3 pb-1'}>{t('COUNTRY')}</p>
                            <div className="dashed-border-sm px-2 py-3">
                                <p className={'ps-2 font-bold fs-15'}> السعودية</p>
                            </div>
                        </div>
                        <div className={'2xl:col-span-2'}>
                            <p className={'font-regular fs-15 ps-3 pb-1'}>{t('TAX_NUMBER')}</p>
                            <div className="dashed-border-sm px-2 py-3">
                                <p className={'ps-2 font-bold fs-15'}> 3103590902</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-5 py-4">
                    <div
                        className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pb-3">
                        <div>
                            <div className={'flex justify-between'}>
                                <p className={'font-regular fs-15 ps-3 pb-1'}>{t('INVOICE_NUMBER')}</p>
                                <p className={'font-regular fs-15 ps-3 pb-1'}>item code</p>
                            </div>
                            <div className="dashed-border-sm px-2 py-3">
                                <p className={'ps-2 font-bold fs-15'}>00000312</p>
                            </div>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 ps-3 pb-1'}>{t('INVOICE_DATE')}</p>
                            <div className="dashed-border-sm px-2 py-3">
                                <p className={'ps-2 font-bold fs-15'}>28-02-2023 </p>
                            </div>
                        </div>

                        <div>
                            <p className={'font-regular fs-15 ps-3 pb-1'}>{t('DATE_SUPPLY')}</p>
                            <div className="dashed-border-sm px-2 py-3">
                                <p className={'ps-2 font-bold fs-15'}> 28-02-2023 </p>
                            </div>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 ps-3 pb-1'}>{t('DESCRIPTION')}</p>
                            <div className="dashed-border-sm px-2 py-3">
                                <p className={'ps-2 font-bold fs-15'}> DESCRIPTION</p>
                            </div>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 ps-3 pb-1'}>{t('CUSTOMER_DATA')}</p>
                            <div className="dashed-border-sm px-2 py-3">
                                <p className={'ps-2 font-bold fs-15'}> CUSTOMER_DATA</p>
                            </div>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 ps-3 pb-1'}>{t('CUSTOMER_NAME')}</p>
                            <div className="dashed-border-sm px-2 py-3">
                                <p className={'ps-2 font-bold fs-15'}> CUSTOMER_NAME</p>
                            </div>
                        </div>
                        <div>
                            <p className={'font-regular fs-15 ps-3 pb-1'}>{t('MOBILE_NUMBER')}</p>
                            <div className="dashed-border-sm px-2 py-3">
                                <p className={'ps-2 font-bold fs-15'}> MOBILE_NUMBER</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-light px-5 py-4 border-radius-10">
                    <div
                        className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-8 px-4 pb-3">
                        <div className={'2xl:col-span-2 '}>
                            <div className="flex justify-between">
                                <p className={'font-regular fs-15 ps-3 pb-1'}>{t('CODE')}</p>
                                <p className={'font-regular fs-15 ps-3 pb-1'}>item code</p>
                            </div>
                            <div className="dashed-border-sm px-2 py-3">
                                <p className={'ps-2 font-bold fs-15'}>0000002</p>
                            </div>
                        </div>
                        <div className={'2xl:col-span-2 '}>
                            <div className="flex justify-between">
                                <p className={'font-regular fs-15 ps-3 pb-1'}>{t('DESCRIPTIONN')}</p>
                                <p className={'font-regular fs-15 ps-3 pb-1'}>Description</p>
                            </div>
                            <div className="dashed-border-sm px-2 py-3">
                                <p className={'ps-2 font-bold fs-15'}>اشتراك تطبيق مكافآت </p>
                            </div>
                        </div>

                        <div className={'2xl:col-span-2 '}>
                            <div className="flex justify-between">
                                <p className={'font-regular fs-15 ps-3 pb-1'}>{t('QUANTITY')}</p>
                                <p className={'font-regular fs-15 ps-3 pb-1'}>QTY</p>
                            </div>
                            <div className="dashed-border-sm px-2 py-3">
                                <p className={'ps-2 font-bold fs-15'}> 1</p>
                            </div>
                        </div>
                        <div className={'2xl:col-span-2 '}>
                            <div className="flex justify-between">
                                <p className={'font-regular fs-15 ps-3 pb-1'}>{t('PRICE')}</p>
                                <p className={'font-regular fs-15 ps-3 pb-1'}>PRICE</p>
                            </div>
                            <div className="dashed-border-sm px-2 py-3">
                                <p className={'ps-2 font-bold fs-15'}> 260</p>
                            </div>
                        </div>
                        <div>
                            <div className="flex justify-between">
                                <p className={'font-regular fs-15 ps-3 pb-1'}>{t('DISCOUNT_2')}</p>
                                <p className={'font-regular fs-15 ps-3 pb-1'}>disc.</p>
                            </div>

                            <div className="dashed-border-sm px-2 py-3">
                                <p className={'ps-2 font-bold fs-15'}> 0</p>
                            </div>
                        </div>
                        <div>
                            <div className="flex justify-between">
                                <p className={'font-regular fs-15 ps-3 pb-1'}>{t('TAX')} %</p>
                                <p className={'font-regular fs-15 ps-3 pb-1'}>VAT</p>
                            </div>
                            <div className="dashed-border-sm px-2 py-3">
                                <p className={'ps-2 font-bold fs-15'}> 15</p>
                            </div>
                        </div>
                        <div className={'2xl:col-span-2 '}>
                            <div className="flex justify-between">
                                <p className={'font-regular fs-15 ps-3 pb-1'}>{t('TOTAL_PRICE')}</p>
                                <p className={'font-regular fs-15 ps-3 pb-1'}>total price</p>
                            </div>
                            <div className="dashed-border-sm px-2 py-3">
                                <p className={'ps-2 font-bold fs-15'}> 203</p>
                            </div>
                        </div>
                        <div className={'2xl:col-span-2 '}>
                            <div className="flex justify-between">
                                <p className={'font-regular fs-15 ps-3 pb-1'}>{t('TAX_VALUE')}</p>
                                <p className={'font-regular fs-15 ps-3 pb-1'}>VAT</p>
                            </div>
                            <div className="dashed-border-sm px-2 py-3">
                                <p className={'ps-2 font-bold fs-15'}> 203</p>
                            </div>
                        </div>
                        <div className={'2xl:col-span-2 '}>
                            <div className="flex justify-between">
                                <p className={'font-regular fs-15 ps-3 pb-1'}>{t('NET')}</p>
                                <p className={'font-regular fs-15 ps-3 pb-1'}>Net Price</p>
                            </div>
                            <div className="dashed-border-sm px-2 py-3">
                                <p className={'ps-2 font-bold fs-15'}> 203</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="py-">
                    <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 pb-3">
                        <div>
                            <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-3">
                                <div className="col-span-2 dashed-border-sm flex justify-between px-3 py-4">
                                    <p className={'ps-2 font-regular fs-15'}> {t('TOTAL')}</p>
                                    <p className={'ps-2 font-regular fs-15'}> Amount</p>
                                </div>
                                <div className="dashed-border-sm flex justify-between px-3 py-4">
                                    <p className={'ps-2 font-regular fs-15'}> 203</p>
                                </div>
                            </div>
                            <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-3">
                                <div className="col-span-2 dashed-border-sm flex justify-between px-3 py-4">
                                    <p className={'ps-2 font-regular fs-15'}> {t('DISCOUNTS')}</p>
                                    <p className={'ps-2 font-regular fs-15'}> disc.</p>
                                </div>
                                <div className="dashed-border-sm flex justify-between px-3 py-4">
                                    <p className={'ps-2 font-regular fs-15'}> 203</p>
                                </div>
                            </div>
                            <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-3">
                                <div className="col-span-2 dashed-border-sm flex justify-between px-3 py-4">
                                    <p className={'ps-2 font-regular fs-15'}> {t('TAX')}</p>
                                    <p className={'ps-2 font-regular fs-15'}> VAT</p>
                                </div>
                                <div className="dashed-border-sm px-3 py-4">
                                    <p className={'ps-2 font-regular fs-15'}> 203</p>
                                </div>
                            </div>
                            <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-3">
                                <div className="col-span-2 dashed-border-sm flex justify-between px-3 py-4">
                                    <p className={'ps-2 font-regular fs-15'}> {t('NET')}</p>
                                    <p className={'ps-2 font-regular fs-15'}> Net Amount</p>
                                </div>
                                <div className="dashed-border-sm px-3 py-4">
                                    <p className={'ps-2 font-regular fs-15'}> 203</p>
                                </div>
                            </div>
                            <div className={"col-span-3 text-center pt-3"}>
                                <p className={'pe-4 font-bold fs-15'}>فقط مائتان وتسعة وتسعون ريال لاغير</p>
                            </div>
                        </div>
                        <div className={'flex justify-center items-center'}>
                            <img src={QR} alt="avatar1" width={'200px'} className={'border-radius-10 me-2'}/>
                        </div>
                    </div>
                </div>
                <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-2 pb-10">
                    <div className={"flex justify-center"}>
                        <p className={'font-bold fs-15 ps-3 pb-1'}>{t('SELLER')}</p>
                    </div>
                    <div className={"flex justify-center"}>
                        <p className={'font-bold fs-15 ps-3 pb-1'}>{t('RECIPIENT')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AddInvoice;
